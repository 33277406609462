import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import translationEN from "./en.json";
import translationFR from "./fr.json";
import translationDE from "./de.json";

// the translations
const options = {
  order: ["querystring", "navigator"],
  lookupQuerystring: "lng",
};
const resources = {
  "en-US": {
    translation: translationEN,
  },
  "fr-FR": {
    translation: translationFR,
  },
  "de-DE": {
    translation: translationDE,
  },
};

console.log({ LanguageDetector });

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    detection: options,
    keySeparator: ".", // to support nested translations

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    debug: true,
  });
i18n.changeLanguage();
export default i18n;
