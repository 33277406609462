import Parser from "html-react-parser";
import PropTypes from "prop-types";
import React from "react";
import { Row } from "reactstrap";
class LegalComponent extends React.PureComponent {
  static propTypes = {
    condition: PropTypes.string
  };

  render() {
    const { condition } = this.props;
    return (
      <div>
        <Row style={{ padding: "1rem 2rem" }}>
          <div>{Parser(condition)}</div>
        </Row>
      </div>
    );
  }
}

export default LegalComponent;
