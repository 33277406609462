import axios from "axios";
import gql from "graphql-tag";
import PropTypes from "prop-types";
import React from "react";
import { graphql } from "react-apollo";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import Alert from "../../../handler/utils/Alert";
import { BASE_URL } from "../../../handler/utils/constants";

class SendEmail extends React.Component {
  static propTypes = {
    toggle: PropTypes.func,
    modal: PropTypes.bool,
    id: PropTypes.string,
    error: PropTypes.object,
    getCandidatProfile: PropTypes.object,
    entreprise_id: PropTypes.string,
    offer_Name: PropTypes.string,
  };

  static defaultProps = {
    error: null,
    getCandidatProfile: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      modal: props.modal,
      message: null,
      subject: null,
      loading: false,
    };

    this._toggle = this._toggle.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.modal !== this.props.modal)
      this.setState({ modal: nextProps.modal });
  }

  _toggle() {
    this.props.toggle();
  }

  onResponse = cb => {
    this.setState(
      {
        loading: false,
      },
      () => {
        cb();
      },
    );
  };

  onChangeSubject = e => this.setState({ subject: e.target.value });
  onChangeMessage = e => this.setState({ message: e.target.value });

  handleSubmit = () => {
    if (!this.state.loading) {
      if (this.state.message) {
        this.setState({ loading: true });
        const token = localStorage.getItem("token-candidat");
        const fullName =
          this.props.getCandidatProfile.first_name +
          " " +
          this.props.getCandidatProfile.last_name;
        axios.defaults.headers.authorization = token;
        axios
          .post(BASE_URL + "/mailer2", {
            subject: this.props.offer_Name,
            body: this.state.message,
            entreprise_id: this.props.entreprise_id,
            email: this.props.getCandidatProfile.profile.email,
            fullName,
          })
          .then(res => {
            console.log("ssss", res);
            Alert.success("Votre email a été envoyé avec succès.");
            this.setState({ loading: null, message: null, subject: null });
            this._toggle();
          });
      } else {
        Alert.success("Veuillez remplir tous les champs");
      }
    }
  };

  render() {
    const { modal, loading } = this.state;
    return (
      <div>
        <Modal
          isOpen={modal}
          toggle={this._toggle}
          className="ModalSahreCV"
          style={{ marginTop: "4%", width: "80%" }}
        >
          <ModalHeader
            toggle={this._toggle}
            style={{ textTransform: "uppercase" }}
          >
            Contacter le recruteur
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col style={{ justifyContent: "center", marginTop: "1em" }}>
                <FormGroup className="ModalCV-group__form">
                  <Label className="ModalCV-group__label" for="last_name">
                    Sujet
                  </Label>
                  <Input
                    className="ModalCV-group__input"
                    placeholder="Sujet..."
                    type="text"
                    style={{ minHeight: 30 }}
                    value={this.props.offer_Name}
                    defaultValue={this.props.offer_Name}
                    contentEditable={false}
                  />
                </FormGroup>
                <FormGroup className="ModalCV-group__form">
                  <Label className="ModalCV-group__label" for="first_name">
                    Message
                  </Label>
                  <Input
                    className="ModalCV-group__input"
                    placeholder="Message..."
                    type="textarea"
                    style={{ minHeight: 100 }}
                    value={this.state.message}
                    onChange={this.onChangeMessage}
                  />
                </FormGroup>
                <FormGroup
                  className="ModalCV-group__form NoFlex"
                  style={{
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    className="Profil-btn__cancel btn btn-secondary btn-lg mb-0 BtnCancel ModalBtn"
                    style={{ height: "unset", width: "40%" }}
                    onClick={loading ? null : this._toggle}
                  >
                    {loading ? "En cours" : "Annuler"}
                  </Button>
                  <Button
                    className="Profil-btn__success btn btn-primary btn-lg mb-0 BtnSend ModalBtn"
                    style={{ width: "40%" }}
                    onClick={loading ? null : this.handleSubmit}
                  >
                    {loading ? "En cours" : "Envoyer"}
                  </Button>
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const GET_CANDIDAT = gql`
  query getCandidatProfile {
    getCandidatProfile {
      id
      first_name
      last_name
      profile {
        email
      }
    }
  }
`;

export default graphql(GET_CANDIDAT, {
  options: () => ({
    fetchPolicy: "cache-and-network",
  }),
  props: ({ data: { loading, error, getCandidatProfile } }) => ({
    loading,
    error,
    getCandidatProfile,
  }),
})(SendEmail);
