import React from "react";
import { Row } from "reactstrap";
import ChangePassword from "./components/ChangePassword";

class ForgotPassword extends React.PureComponent {
  render() {
    return (
      <div id="app" className="marginTop BorderTop">


        <Row className="shadow boxPass">
          <ChangePassword />
        </Row>
      </div>
    );
  }
}

export default ForgotPassword;
