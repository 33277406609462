import classNames from "classnames";
import gql from "graphql-tag";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { graphql } from "react-apollo";
import { withRouter } from "react-router-dom";
import Topbar from "./topbar/Topbar";
let that = null;
class Layout extends Component {
  static propTypes = {
    getCandidatProfile: PropTypes.object,
    refetch: PropTypes.func,
    isLoggedIn: PropTypes.bool,
  };

  static defaultProps = {
    getCandidatProfile: {
      last_name: "Candidat",
      avatar: null,
      refetch: () => {},
    },
    isLoggedIn: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      sidebarCollapse: false,
    };
  }

  componentDidMount() {
    that = this;
  }

  render() {
    const { sidebarCollapse } = this.state;
    const layoutClass = classNames({
      layout: true,
      "layout--collapse": sidebarCollapse,
    });
    const { getCandidatProfile, refetch, isLoggedIn } = this.props;
    return (
      <div>
        {isLoggedIn ? (
          <div className={layoutClass}>
            <Topbar
              sidebarCollapse={sidebarCollapse}
              changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
              changeSidebarVisibility={this.changeSidebarVisibility}
              getCandidatProfile={getCandidatProfile}
              refetch={refetch}
            />
          </div>
        ) : (
          <div />
        )}
      </div>
    );
  }
}

const GET_CANDIDAT = gql`
  query getCandidatProfile {
    getCandidatProfile {
      first_name
      last_name
      avatar
    }
  }
`;
function refetch() {
  if (that.props && that.props.refetch) that.props.refetch();
}
export { refetch };
export default withRouter(
  graphql(GET_CANDIDAT, {
    options: () => ({
      fetchPolicy: "cache-and-network",
    }),
    props: ({ data: { loading, error, getCandidatProfile, refetch } }) => {
      return {
        loading,
        error,
        refetch,
        getCandidatProfile,
      };
    },
  })(Layout)
);
