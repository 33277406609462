import gql from "graphql-tag";

export const SET_TOKEN = gql`
  mutation SetToken($token: String = null) {
    setToken(token: $token) @client
  }
`;
export const UPDATE_NOTIFICATIONS_LENGTH = gql`
  mutation UpdateNotificationsLength($length: Int!, $viewed: Boolean!) {
    updateNotificationsLength(length: $length, viewed: $viewed) @client
  }
`;
export const INCREMENT_NOTIFICATION_LENGTH = gql`
  mutation IncrementNotificationsLength($viewed: Boolean!) {
    incrementNotificationsLength(viewed: $viewed) @client
  }
`;
