import PropTypes from "prop-types";
import React from "react";
import { compose, graphql } from "react-apollo";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { SET_TOKEN } from "../../../handler/mutations.local";
import { IS_LOGGED_IN } from "../../../handler/queries.local";
import Photo from "../../../shared/components/Photo";
import Notifications from "./Notifications";
import i18n from "../../../locals/i18n";
import { withTranslation } from "react-i18next";

class UserDetails extends React.Component {
  static propTypes = {
    setToken: PropTypes.func.isRequired,
    getCandidatProfile: PropTypes.object,
    refetch: PropTypes.func,
    isLoggedIn: PropTypes.bool,
  };

  static defaultProps = {
    getCandidatProfile: {
      last_name: "Candidat",
      avatar: null,
    },
  };
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      logged: !!localStorage.getItem("token-candidat"),
    };
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.getCandidatProfile !== this.props.getCandidatProfile) {
      if (!this.props.getCandidatProfile) {
        this.props
          .setToken({
            variables: {
              token: null,
            },
          })
          .then(() => {
            localStorage.removeItem("token-candidat");
            this.setState({ logged: false });
          });
      }
      this.props.refetch();
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isLoggedIn !== this.props.isLoggedIn) {
      if (!this.props.isLoggedIn) {
        this.props
          .setToken({
            variables: {
              token: null,
            },
          })
          .then(() => {
            localStorage.removeItem("token-candidat");
            this.setState({ logged: false });
          });
      }
      this.setState({ logged: this.props.isLoggedIn }, () => {
        this.props.refetch();
      });
    }
    if (prevState.logged !== this.state.logged) {
      this.props.refetch();
    }
    if (prevProps.getCandidatProfile !== this.props.getCandidatProfile) {
      this.props.refetch();
    }
  }

  toggle = () => {
    this.setState(({ isOpen }) => ({
      isOpen: !isOpen,
    }));
  };

  logout = () => {
    this.props
      .setToken({
        variables: {
          token: null,
        },
      })
      .then(() => {
        localStorage.removeItem("token-candidat");
        this.setState({ logged: false });
      });
  };
  render() {
    const { getCandidatProfile } = this.props;
    const { isOpen, logged } = this.state;

    return (
      <React.Fragment>
        <Dropdown
          isOpen={isOpen}
          toggle={this.toggle}
          className="user-detailsMin"
        >
          <DropdownToggle
            className="user-details"
            tag="div"
            style={{ display: "flex", alignItems: "center" }}
          >
            {logged && (
              <Photo
                url={getCandidatProfile.avatar}
                first_name={getCandidatProfile.first_name}
                last_name={getCandidatProfile.last_name}
              />
            )}

            <span
              to={logged ? "#" : "/connexion"}
              className="user-details__name"
            >{`${
              logged
                ? getCandidatProfile
                  ? getCandidatProfile.last_name
                  : "Candidat"
                : "connexion"
            }`}</span>
            {logged && <i className="fa fa-chevron-down text-white" />}
          </DropdownToggle>
          {!logged ? (
            <DropdownMenu className="full-width user-details__drop" right>
              <DropdownItem className="user-defirst_nametails__down">
                <Link
                  className="user-details__link"
                  to={{
                    pathname: "/connexion",
                    state: { top: 0 },
                  }}
                >
                  {i18n.t("useDetails.iAmACandidate")}
                </Link>
              </DropdownItem>
              <DropdownItem className="user-details__logout">
                <a
                  className="user-details__link"
                  target="blank"
                  href="https://rmtest.toolynk.fr/recruteur/"
                >
                  {i18n.t("useDetails.iAmACandidate")}
                </a>
              </DropdownItem>
            </DropdownMenu>
          ) : (
            <DropdownMenu className="full-width user-details__drop" right>
              <DropdownItem className="user-details__down">
                <Link
                  className="user-details__link"
                  to={{
                    pathname: "/profil",
                  }}
                >
                  {i18n.t("useDetails.myAccount")}
                </Link>
              </DropdownItem>

              <DropdownItem
                className="user-details__logout"
                onClick={this.logout}
              >
                <span style={{ paddingLeft: 10, color: "#32618f" }}>
                  {i18n.t("useDetails.logout")}
                </span>
              </DropdownItem>
            </DropdownMenu>
          )}
        </Dropdown>
        {logged && <Notifications />}
      </React.Fragment>
    );
  }
}

export default withTranslation()(
  compose(
    graphql(IS_LOGGED_IN, {
      props: ({ data: { isLoggedIn } }) => ({
        isLoggedIn: isLoggedIn ? isLoggedIn.isLoggedIn : false,
      }),
    }),
    graphql(SET_TOKEN, {
      name: "setToken",
    })
  )(UserDetails)
);
