import * as moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { BASE_URL } from "../../../handler/utils/constants";
import Image from "../../../shared/components/Image";

function Offer_card(props) {
  const { num, offer } = props;
  const {
    _id,
    entreprise_logo,
    name,
    entreprise,
    contract,
    activity_rate,
    createdAt,
  } = props.offer;
  const cont =
    contract && contract.length > 2
      ? [contract[0], contract[1], "..."]
      : contract;
  return (
    <div
      key={_id}
      className={`CandidateListItem " + ${num === offer.num &&
        " CandidateListItem-active"}`}
    >
      <Row onClick={() => props.getItem(props.offer)}>
        <Col
          md={4}
          lg={4}
          xl={3}
          sx={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Link
            to={{
              pathname: "/recruteurs",
              state: { mot_cle: entreprise },
            }}
          >
            <Image
              name={BASE_URL + entreprise_logo}
              className="CandidateListItem-avatar"
            />
          </Link>
        </Col>
        <Col
          md={5}
          lg={5}
          xl={6}
          sx={12}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <span className="CandidateListItem-name__label">{" " + name}</span>

          <span className="CandidateListItem-name__job">
            <Link
              to={{
                pathname: "/recruteurs",
                state: { mot_cle: entreprise },
              }}
            >
              {entreprise}
            </Link>
          </span>
          <div className="CandidateListItem-div">
            <span className="CandidateListItem-div__label">
              {cont && cont + ", "}
              {activity_rate && activity_rate}
            </span>
          </div>
        </Col>
        <Col
          md={3}
          lg={3}
          xl={3}
          sx={12}
          className="CandidateListItem-createdAt"
        >
          Le {moment(createdAt).format("DD/MM/YYYY")}
        </Col>
      </Row>
    </div>
  );
}
Offer_card.propTypes = {
  offer: PropTypes.object,
  num: PropTypes.number,
  getItem: PropTypes.func,
};

Offer_card.defaultProps = {
  offer: {},
  num: null,
};

export default Offer_card;
