import React from "react";
import { Input } from "reactstrap";
import Button from "../../../shared/components/Button";
import { BASE_URL } from "../../../handler/utils/constants";
import axios from "axios";
import PropTypes from "prop-types";

import Alert from "../../../handler/utils/Alert";

export default class CheckResetPasswordCode extends React.Component {
  static propTypes = {
    user: PropTypes.object,
    setToken: PropTypes.func,
    setLogin: PropTypes.func
  };
  constructor() {
    super();
    this.state = { loading: false };
  }

  setEmailRef = ref => {
    this.code = ref;
  };

  handleSubmit = () => {
    if (!this.state.loading) {
      this.setState({ loading: true });
      const code = this.code.value.trim();
      const data = {
        email: this.props.user.email,
        reset_password_code: code
      };
      axios
        .post(BASE_URL + "/auth/password/reset/code/check", data)
        .then(response => {
          this.setState({ loading: false });
          if (response.data.success) {
            this.props.setToken(response.data.reset_password_token);
          } else {
            Alert.warning(
              "Votre code peut être expiré, Veuillez envoyer un nouveau code"
            );
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          console.log(error);
        });
    }
  };

  render() {
    const { loading } = this.state;

    return (
      <div className="Login-send">
        <div className="Login-header border-0 m-0 p-0">
        <div className="mr-2 Signup-Title">Confirmation du code reçu par mail</div>

</div>
        {/*  {user.profile_pic_url ? (
            <FastImage
              source={{ uri: user.profile_pic_url }}
              style={{ width: 48, height: 48, borderRadius: 24 }}
            />
          ) : (
            <FastImage
              source={require("../../img/avatar-placeholder.jpg")}
              style={{ width: 48, height: 48, borderRadius: 24 }}
            />
          )} */}
        <div style={{ flex: 1}}>
          <span className="Login-label__footer pb-3">
            Pour réinitialiser votre mot de passe, merci de renseigner le code
            reçu par mail.
          </span>
    
        </div>
        <span className="Login-label p-0 ml-0 text-uppercase">Code</span>
        <Input
          name="code"
          type="code"
          className="Login-input"
          innerRef={this.setEmailRef}
        />
        <div className="d-flex  w-100 justify-content-center">
        <div>
        <Button
          className="btn btn-primary Signup-btn "
          style={{ marginTop: 30 , width:"auto"}}
          onClick={this.handleSubmit}
          text="ENVOYER"
          color="primary"
          loading={loading}
        /></div>
        </div>
      
      </div>
    );
  }
}
