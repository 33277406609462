import i18n from "../../locals/i18n";

export const PROJECT_NAME = "CodersTalent";
export const HOME = "Accueil";
export const PROFILE = "Mon compte";
export const MYCV = "Mes CV";
export const SHARE_CV = "CV Partagés";
export const OFFERS = "Offres";
export const SETTINGS = "Paramètres";
export const PASSWORD = "Modification du mot de pesse";
export const ADD_OFFER = "CREATION D'UNE OFFRE";
const LOGIN = "Connexion";

export const titles = {
  LOGIN,
  OFFERS,
};

export const work_timeConst = [
  {
    id: 1,
    value: "FULL",
    label: i18n.t("constants.fullTime"),
  },
  {
    id: 2,
    value: "HALF",
    label: i18n.t("constants.halfTime"),
  },
  {
    id: 3,
    value: "ANY",
    label: i18n.t("constants.others"),
  },
];

export const contratConst = [
  {
    value: "CDD",
    label: "CDD",
  },
  {
    value: "CDI",
    label: "CDI",
  },
  {
    value: "STAGE",
    label: i18n.t("constants.TRAINEESHIP"),
  },
  {
    value: "FREELANCE",
    label: i18n.t("constants.FREELANCE"),
  },
  {
    value: "APPRENTISSAGE",
    label: i18n.t("constants.LEARNING"),
  },
  {
    value: "AUTRE",
    label: i18n.t("constants.OTHER"),
  },
];

export const etudeConst = [
  {
    id: 1,
    value: "ONE",
    label: "BAC+1",
  },
  {
    id: 2,
    value: "TWO",
    label: "BAC+2",
  },
  {
    id: 3,
    value: "THREE",
    label: "BAC+3",
  },
  {
    id: 4,
    value: "FOUR",
    label: "BAC+4",
  },
  {
    id: 5,
    value: "FIVE",
    label: "BAC+5",
  },
  {
    id: 6,
    value: "MORE_THAN_FIVE",
    label: "+ BAC+5",
  },
  {
    value: "CAP",
    label: "CAP",
  },
  {
    value: "BEP",
    label: "BEP",
  },
  {
    id: 7,
    value: "AUTRE",
    label: i18n.t("constants.OTHER"),
  },
];

export const statusConst = [
  {
    id: 1,
    value: "PENDING",
    label: i18n.t("constants.pending"),
  },
  {
    id: 2,
    value: "APPROVED",
    label: i18n.t("constants.selected"),
  },
  {
    id: 3,
    value: "CALLED_TO_INETRVIEW",
    label: i18n.t("constants.calledForAnInterview"),
  },
  {
    id: 4,
    value: "INETRVIEW_PASSED",
    label: i18n.t("constants.pastInterview"),
  },
  {
    id: 5,
    value: "ACCEPTED",
    label: i18n.t("constants.detention"),
  },
  {
    id: 6,
    value: "CANCELED",
    label: i18n.t("constants.canceledByYou"),
  },
  {
    id: 7,
    value: "REFUSED",
    label: i18n.t("constants.canceledByCompany"),
  },
];

export const sortApplicationsConst = [
  {
    id: 1,
    value: "recent",
    label: i18n.t("constants.applicationDate"),
  },
  {
    id: 2,
    value: "rating",
    label: i18n.t("constants.note"),
  },
  {
    id: 3,
    value: "experience",
    label: i18n.t("constants.experience"),
  },
  {
    id: 4,
    value: "disponibility",
    label: i18n.t("constants.availablity"),
  },
];

export const sortOffersConst = [
  {
    id: 1,
    value: "recent",
    label: i18n.t("constants.mostRecent"),
  },
  {
    id: 2,
    value: "old",
    label: i18n.t("constants.mostRecent"),
  },
  {
    id: 3,
    value: "az",
    label: "A-Z",
  },
  {
    id: 4,
    value: "za",
    label: "Z-A",
  },
  {
    id: 5,
    value: "up",
    label: i18n.t("constants.applicationsUP"),
  },
  {
    id: 6,
    value: "down",
    label: i18n.t("constants.applicationsDOWN"),
  },
];

export const experienceConst = [
  {
    value: "ONE",
    label: i18n.t("constants.lessThanYear"),
  },
  {
    value: "THREE",
    label: i18n.t("constants.1To3Years"),
  },
  {
    value: "FIVE",
    label: i18n.t("constants.3To5Years"),
  },
  {
    value: "TEN",
    label: i18n.t("constants.5To10Years"),
  },
  {
    value: "MORE_THAN_TEN",
    label: i18n.t("constants.more10Years"),
  },
];
export const timeConst = [
  {
    value: "Soir",
    label: i18n.t("constants.evening"),
  },
  {
    value: "Matin",
    label: i18n.t("constants.morning"),
  },
  {
    value: "Journée",
    label: i18n.t("constants.daytime"),
  },
  {
    value: "Après-midi",
    label: i18n.t("constants.afternoon"),
  },
];
export const rateConst = [
  {
    value: "10%",
    label: "10%",
  },
  {
    value: "20%",
    label: "20%",
  },
  {
    value: "30%",
    label: "30%",
  },
  {
    value: "40%",
    label: "40%",
  },
  {
    value: "50%",
    label: "50%",
  },
  {
    value: "60%",
    label: "60%",
  },
  {
    value: "70%",
    label: "70%",
  },
  {
    value: "80%",
    label: "80%",
  },
  {
    value: "90%",
    label: "90%",
  },
  {
    value: "100%",
    label: "100%",
  },
];
export const genreConst = [
  {
    value: "Homme",
    label: i18n.t("constants.male"),
  },
  {
    value: "Femme",
    label: i18n.t("constants.female"),
  },
];
export const languagesConst = [
  {
    value: "Francais",
    label: i18n.t("constants.french"),
  },
  {
    value: "Anglais",
    label: i18n.t("constants.english"),
  },
  {
    value: "Espagnol",
    label: i18n.t("constants.spanish"),
  },
  {
    value: "Arabe",
    label: i18n.t("constants.arabic"),
  },
  {
    value: "Italien",
    label: i18n.t("constants.italian"),
  },
  {
    value: "Allemand",
    label: i18n.t("constants.german"),
  },
  {
    value: "Hindi",
    label: i18n.t("constants.hindi"),
  },
  {
    value: "Mandarin",
    label: i18n.t("constants.mandarin"),
  },
  {
    value: "Portugais",
    label: i18n.t("constants.portuguese"),
  },
  {
    value: "Russe",
    label: i18n.t("constants.russian"),
  },
];
export const rayonConst = [
  {
    value: 10,
    label: "10Km",
  },
  {
    value: 20,
    label: "20Km",
  },
  {
    value: 40,
    label: "40Km",
  },
  {
    value: 60,
    label: "60Km",
  },
  {
    value: 80,
    label: "80Km",
  },
  {
    value: 100,
    label: "100Km",
  },
];
export const daysConst = [
  {
    value: "La semaine",
    label: i18n.t("constants.monToFri"),
  },
  {
    value: "Lundi",
    label: i18n.t("constants.monday"),
  },
  {
    value: "Mardi",
    label: i18n.t("constants.tuesday"),
  },
  {
    value: "Mercredi",
    label: i18n.t("constants.wednesday"),
  },
  {
    value: "Jeudi",
    label: i18n.t("constants.thursday"),
  },
  {
    value: "Vendredi",
    label: i18n.t("constants.friday"),
  },
  {
    value: "Samedi",
    label: i18n.t("constants.saturday"),
  },
  {
    value: "Dimanche",
    label: i18n.t("constants.sunday"),
  },
];
export const routeToTitle = {
  accueil: {
    title: HOME,
    icon: "home",
  },
  compte: {
    title: PROFILE,
    icon: "my-account",
  },
  "mes-cv": {
    title: MYCV,
    icon: "my-cv",
  },
  "cv-partages": {
    title: SHARE_CV,
    icon: "shared-cv",
  },
  "mes-offres": {
    title: OFFERS,
    icon: "my-offers",
  },
  parametres: {
    title: SETTINGS,
    icon: "settings",
  },
  "changer-mot-de-passe": {
    title: PASSWORD,
    icon: "settings",
  },
  "creation-une-offre": {
    title: ADD_OFFER,
    icon: "my-offers",
  },
};

export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const NAME_REGEX = /^[a-zA-Z ]{2,50}$/;
export const REGEX_MOBILE = /^(?:\+216)[0-9]{8}$/; /* /^(?:\+33)[0-9]{9,10}$/ */

export const ALERT_WARNING = {
  type: "warning",
  icon: "fa fa-exclamation-triangle",
};

export const ALERT_ERROR = {
  type: "error",
  icon: "fa fa-exclamation-circle",
};

export const ALERT_INFO = {
  type: "info",
  icon: "fa fa-info",
};

export const ALERT_SUCCESS = {
  type: "success",
  icon: "fa fa-check",
};

export const ALERT_DEFAULT_CONFIG = {
  manyInstances: false,
  disableAutoClose: false,
  autoClose: 2500,
};

const isLocal = false;

const PROTOCOL = isLocal ? "http://" : "https://";
const WS_PROTOCOL = isLocal ? "ws://" : "wss://";
const HOST = isLocal ? "localhost:7510" : "api-recrute.toolynk.fr";
//const PORT = "6500";
const WS_ENDPOINT = "/subscriptions";

export const BASE_URL = `${PROTOCOL}${HOST}`;
export const WS_URL = `${WS_PROTOCOL}${HOST}${WS_ENDPOINT}`;
