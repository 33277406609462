import React from "react";
import { Row } from "reactstrap";
import { Query } from "react-apollo";
import Pagination from "react-js-pagination";
import FavoriteListTable from "./components/FavoriteListTable";
import { GET_FAVORITES } from "../../handler/queries";
import i18n from "../../locals/i18n";
import { withTranslation } from "react-i18next";

class FavoritesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1
    };
  }
  handlePageChange = pageNumber => {
    this.setState({
      activePage: pageNumber,
      skip: pageNumber * 2
    });
  };
  render() {
    const { activePage } = this.state;
    return (
      <React.Fragment>
        <Row className="Application-row__header">
          <span className="Entreprises-header__blue profile-div">
           {i18n.t("favouritesList.mySavedOffers")}
          </span>
        </Row>
        <Row className="Application-row">
          <Query
            query={GET_FAVORITES}
            variables={{
              skip: (activePage - 1) * 5,
              limit: 5
            }}
            fetchPolicy= 'network-only'
          >
            {({ data, loading, error, refetch }) => {
              if (loading) return <div />;
              else if (error) return <p>{i18n.t("favouritesList.ERROR")}</p>;

              return (
                <React.Fragment>
                  <FavoriteListTable
                    favorites={data.getFavorites && data.getFavorites.favorites}
                    refetch={refetch}
                  />

                  <div style={{ textAlign: "center", marginTop: 50 }}>
                    <Pagination
                      prevPageText={i18n.t("favouritesList.former")}
                      nextPageText={i18n.t("favouritesList.next")}
                      firstPageText={i18n.t("favouritesList.first")}
                      lastPageText={i18n.t("favouritesList.last")}
                      activePage={activePage}
                      itemsCountPerPage={5}
                      innerClass={"pagination"}
                      totalItemsCount={
                        data.getFavorites ? data.getFavorites.totalCount : 0
                      }
                      pageRangeDisplayed={5}
                      onChange={this.handlePageChange}
                    />
                  </div>
                </React.Fragment>
              );
            }}
          </Query>
        </Row>
      </React.Fragment>
    );
  }
}

export default withTranslation()(FavoritesList);
