import gql from "graphql-tag";
import PropTypes from "prop-types";
import React from "react";
import { compose, graphql } from "react-apollo";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import ReactPixel from "react-facebook-pixel";
import { withRouter } from "react-router-dom";
import scrollToComponent from "react-scroll-to-component";
import Select from "react-select";
import ReactGA from "react-ga";

import {
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  ListGroup,
  Row,
} from "reactstrap";
import { SET_TOKEN } from "../../../handler/mutations.local";
import {
  GET_CANDIDAT,
  GET_JOBS,
  UPDATE_USER_STATUS,
} from "../../../handler/queries";
import Alert from "../../../handler/utils/Alert";
import {
  BASE_URL,
  contratConst,
  daysConst,
  etudeConst,
  experienceConst,
  genreConst,
  languagesConst,
  rateConst,
  timeConst,
} from "../../../handler/utils/constants";
import AddressAutocomplete from "../../../shared/components/AddressAutocomplete";
import AutosuggestionInput from "../../../shared/components/AutosuggestionInput";
import Button from "../../../shared/components/Button";
import PdfApercu from "../../../shared/components/pdfApercu";
import { refetch } from "../../layout/index";
import SwitchTootgle from "./Switch";
import i18n from "../../../locals/i18n";
import { withTranslation } from "react-i18next";

const UserImg = `${process.env.PUBLIC_URL}/img/images/user.jpg`;
const validate = `${process.env.PUBLIC_URL}/img/images/check.png`;

const defaultErrors = {
  name: null,
  email: null,
  avatar: null,
  tel: null,
  cv: null,
  jobs: null,
  sharedcv: null,
  experience: null,
  country: null,
  city: null,
  suggestions: null,
  contract: null,
  etude: null,
  letter: null,
  genre: null,
  address: null,
  visibility: null,
};

class ProfileForm extends React.Component {
  static propTypes = {
    getCandidatProfile: PropTypes.object,
    updateCandidatAvatar: PropTypes.func,
    addCVRequest: PropTypes.func,
    refetch: PropTypes.func,
    handleChangeUpdate: PropTypes.func,
    updateCandidat: PropTypes.func,
    shareMyCVState: PropTypes.func,
    removeUserAvatar: PropTypes.func,
    removeCandidat: PropTypes.func,
    updateUserStatus: PropTypes.func,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    getJobs: PropTypes.object,
    setToken: PropTypes.func.isRequired,
  };

  static defaultProps = {
    className: "Profil",
    getCandidatProfile: null,
    title: "",
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      file:
        props.getCandidatProfile && props.getCandidatProfile.cv
          ? props.getCandidatProfile.cv
          : null,
      job_id:
        this.props.getCandidatProfile && this.props.getCandidatProfile.job_id
          ? this.props.getCandidatProfile.job_id
          : null,
      job:
        props.getCandidatProfile && props.getCandidatProfile.job
          ? props.getCandidatProfile.job.name
          : null,

      contract:
        props.getCandidatProfile && props.getCandidatProfile.contract
          ? props.getCandidatProfile.contract
          : null,
      experience:
        props.getCandidatProfile && props.getCandidatProfile.experience
          ? props.getCandidatProfile.experience
          : null,
      etude:
        props.getCandidatProfile && props.getCandidatProfile.etude
          ? props.getCandidatProfile.etude
          : null,
      disponibility_time:
        props.getCandidatProfile && props.getCandidatProfile.disponibility_time
          ? props.getCandidatProfile.disponibility_time
          : null,
      disponibility_days:
        props.getCandidatProfile && props.getCandidatProfile.disponibility_days
          ? props.getCandidatProfile.disponibility_days
          : null,
      languages:
        props.getCandidatProfile && props.getCandidatProfile.languages
          ? props.getCandidatProfile.languages
          : null,
      tel:
        props.getCandidatProfile && props.getCandidatProfile.tel
          ? props.getCandidatProfile.tel
          : null,
      genre:
        props.getCandidatProfile && props.getCandidatProfile.genre
          ? props.getCandidatProfile.genre
          : null,
      activity_rate:
        props.getCandidatProfile && props.getCandidatProfile.activity_rate
          ? props.getCandidatProfile.activity_rate
          : null,
      address:
        props.getCandidatProfile && props.getCandidatProfile.address
          ? props.getCandidatProfile.address
          : null,
      city:
        props.getCandidatProfile && props.getCandidatProfile.city
          ? props.getCandidatProfile.city
          : null,
      location:
        props.getCandidatProfile && props.getCandidatProfile.location
          ? props.getCandidatProfile.location
          : {},
      country:
        props.getCandidatProfile && props.getCandidatProfile.country
          ? props.getCandidatProfile.country
          : null,
      errors: {
        ...defaultErrors,
      },
    };
  }
  componentDidMount() {
    ReactGA.pageview("/profil");
    let disponibility_d = [];
    let disponibility_t = [];
    let language_d = [];
    const contractT = [];
    const {
      disponibility_days,
      disponibility_time,
      languages,
      contract,
    } = this.state;
    if (disponibility_days)
      disponibility_days.map((val) =>
        disponibility_d.push({ value: val, label: val })
      );
    if (disponibility_time)
      disponibility_time.map((val) =>
        disponibility_t.push({ value: val, label: val })
      );
    if (contract)
      contract.map((val) => contractT.push({ value: val, label: val }));
    if (languages)
      languages.map((val) => language_d.push({ value: val, label: val }));
    this.setState({
      languages: language_d,
      disponibility_days: disponibility_d,
      disponibility_time: disponibility_t,
      contract: contractT,
    });
    const { state } = this.props.location;
    if (state && state.inscription && !this.props.getCandidatProfile.cv)
      scrollToComponent(this.cvComp, {
        offset: 0,
        align: "middle",
        duration: 500,
        ease: "inCirc",
      });
  }
  name = null;
  email = null;
  avatar = null;
  sharedcv = null;
  contract = null;
  activity_rate = null;
  profilePicture = React.createRef();
  setRef = (ref) => {
    if (ref) this[ref.name] = ref;
  };
  updateDoc = async (e) => {
    if (e.target.files) {
      if (e.target.name === "avatar") {
        const file = e.target.files[0];

        if (file && file.size > 3e6) {
          Alert.warning(i18n.t("profileForm.PleaseUploadAnImageSmallerThan3MB"));
          return false;
        } else {
          confirmAlert({
            title: i18n.t("profileForm.confirmation"),
            message:
            i18n.t("profileForm.areYouSureYouWantToChangeYourProfilePicture"),
            buttons: [
              {
                label: i18n.t("profileForm.no"),
                onClick: () => {},
              },
              {
                label:  i18n.t("profileForm.yes"),
                onClick: () =>
                  this.props
                    .updateCandidatAvatar({
                      variables: {
                        file,
                      },
                    })
                    .then(() => {
                      setTimeout(() => {
                        this.profilePicture.current.src = `${URL.createObjectURL(
                          file
                        )}`;
                      }, 1000);
                      this.props.refetch();
                      refetch();
                    })
                    .catch((e) => {
                      this.onResponse(() => {
                        if (e && e.graphQLErrors) console.log(e);
                      });
                    }),
              },
            ],
          });
        }
      }
    }
  };
  updatePdf = (e) => {
    if (e.target.files) {
      const file = e.target.files[0];
      if (file && file.type.includes("pdf")) {
        confirmAlert({
          title: i18n.t("profileForm.confirmation"),
          message: i18n.t("profileForm.areYouSureYouWantToReplaceYourCV"),
          buttons: [
            {
              label: i18n.t("profileForm.no"),
              onClick: () => {},
            },
            {
              label: i18n.t("profileForm.yes"),
              onClick: () =>
                this.props
                  .addCVRequest({
                    variables: {
                      file,
                      type: "fr",
                    },
                  })
                  .then(() => {
                    setTimeout(() => {
                      this.onResponse(() => {
                        this.setState({
                          file: file,
                        });
                        ReactPixel.track("Lead", {
                          event:  i18n.t("profileForm.replaceResume"),
                          email: this.props.getCandidatProfile.email,
                        });
                      }, 1000);
                    });
                    this.props.refetch();
                  })
                  .catch((e) => {
                    this.onResponse(() => {
                      if (e && e.graphQLErrors) console.log(e);
                    });
                  }),
            },
          ],
        });
      } else Alert.warning(i18n.t("profileForm.youHaveToChoosePDFCV"));
    }
  };

  onResponse = (cb) => {
    this.setState(
      {
        loading: false,
      },
      () => {
        cb();
      }
    );
  };

  setAddress = (object) => {
    if (object) {
      this.setState({
        address: object.address,
        city: object.city,
        country: object.country,
        location: object.location,
      });
    }
  };
  _handleBlock = (is_blocked, uid) => {
    const errors = {
      ...defaultErrors,
    };
    const {
      first_name,
      last_name,
      address,
      experience,
      genre,
      cv,
      job_id,
    } = this.props.getCandidatProfile;
    if (!cv) {
      errors.cv =
      i18n.t("profileForm.CVValidatedByTheAdministrator");
      this.setState({ errors: { ...errors } });
    }
    if (
      first_name.length < 1 ||
      first_name.length == "N/A" ||
      last_name.length < 1 ||
      last_name.length == "N/A" ||
      !address ||
      !experience ||
      !genre ||
      !cv ||
      !job_id
    ) {
      errors.visibility =
        i18n.t("profileForm.PleaseCompleteAndSaveAllRequiredFields");
      this.setState({ errors: { ...errors } });
    } else {
      errors.visibility = null;
      errors.cv = null;
      this.setState({ loading: true });
      this.props
        .updateUserStatus({
          variables: {
            status: !is_blocked,
            id: uid,
          },
        })
        .then((res) => {
          ReactPixel.track("Lead", {
            event: "profile visble",
            email: this.state.email,
            success: true,
          });
          if (res) Alert.success(i18n.t("profileForm.stateChangedSuccessfully"));
          else Alert.success(i18n.t("profileForm.error"));
          this.props.refetch();
          refetch();
          this.setState({ loading: false });
        });
    }
  };

  _handleRemoveAvatar = () => {
    this.setState({ loading: true });
    confirmAlert({
      title: i18n.t("profileForm.confirmation"),
      message:  i18n.t("profileForm.areYouSureYouWantDeleteYourProfilePicture"),
      buttons: [
        {
          label: i18n.t("profileForm.no"),
          onClick: () => this.setState({ loading: false }),
        },
        {
          label:i18n.t("profileForm.yes"),
          onClick: () =>
            this.props
              .removeUserAvatar({
                variables: {},
              })
              .then((res) => {
                if (res && res.data && res.data.removeUserAvatar)
                  Alert.success(i18n.t("profileForm.profilePictureDeletedSuccessfully"));

                this.props.refetch();
                this.setState({ loading: false });
              }),
        },
      ],
    });
  };

  _handleSubmit = async () => {
    if (!this.state.loading) {
      const first_name = this.first_name.value.trim();
      const last_name = this.last_name.value.trim();
      const tel = this.tel.value.trim();
      const { email } = this.props.getCandidatProfile.profile;
      const {
        genre,
        disponibility_days,
        disponibility_time,
        etude,
        activity_rate,
        contract,
        experience,
        languages,
        job_id,
        address,
        city,
        country,
        location,
      } = this.state;
      if (location.__typename) {
        delete location.__typename;
      }
      const { jobs } = this.props.getJobs;
      let boo = jobs.some(function(arrVal) {
        return job_id === arrVal.id;
      });
      const errors = {
        ...defaultErrors,
      };
      const disponibility_d = disponibility_days
        ? disponibility_days.map((a) => a.label)
        : [];
      const disponibility_t = disponibility_time
        ? disponibility_time.map((a) => a.label)
        : [];
      const contractT = contract ? contract.map((a) => a.label) : [];
      const languages_t = languages ? languages.map((a) => a.label) : [];
      if (first_name.length < 1)
        errors.first_name =
        i18n.t("profileForm.TheFirstNameMandatoryMustContainLeastOneCharacter");
      if (last_name.length < 1)
        errors.last_name =
        i18n.t("profileForm.theNameRequiredAndMustContainLeastOneCharacter");
      if (!address) errors.address =  i18n.t("profileForm.thisFieldIsRequired");
      else if (address.length < 3)
        errors.address =
        i18n.t("profileForm.theAddressIsMandatoryAndMustContainLeastCharacters");
      if (!experience) errors.experience = i18n.t("profileForm.experienceIsMandatory");
      if (!genre) errors.genre = i18n.t("profileForm.genderIsMandatory");
      if (!job_id || !boo) errors.jobs = i18n.t("profileForm.theProfessionIsCompulsory");
      if (
        errors.first_name ||
        errors.last_name ||
        errors.experience ||
        errors.genre ||
        errors.address ||
        errors.jobs
      ) {
        this.setState({ errors: { ...errors } });
        Alert.warning(
          i18n.t("profileForm.pleaseCorrectTheProblemsIdentifiedTheForm")  
        );
      } else {
        this.setState({ loading: true, errors });
        if (this.props.getCandidatProfile)
          this.props
            .updateCandidat({
              variables: {
                id: this.props.getCandidatProfile.id,
                input: {
                  email,
                  last_name,
                  first_name,
                  genre,
                  tel,
                  address,
                  country,
                  city,
                  job_id,
                  languages: languages_t,
                  contract: contractT,
                  activity_rate,
                  etude,
                  disponibility_days: disponibility_d,
                  disponibility_time: disponibility_t,
                  experience,
                  location,
                },
              },
              refetchQueries: [
                {
                  query: GET_CANDIDAT,
                },
              ],
            })
            .then(() => {
              ReactPixel.track("Lead", {
                event: "Modification profile",
                email,
                success: true,
              });
              this.onResponse(() => {
                refetch();
                Alert.success( i18n.t("profileForm.yourProfileHasBeenSuccessfullyUpdated")  );  
                this.props.handleChangeUpdate();
              });
            })
            .catch((e) => {
              console.log("e ", e.graphQLErrors);
              this.onResponse(() => {
                if (e && e.graphQLErrors)
                  Alert.error(e.graphQLErrors[0].message);
              });
            });
      }
    }
  };
  _handleShare = () => {
    if (!this.state.loading) {
      const { sharedcv, cv } = this.props.getCandidatProfile;

      if (cv) {
        this.setState({ loading: true });

        this.props
          .shareMyCVState({
            variables: {
              state: !sharedcv,
            },
          })
          .then((rest) => {
            this.onResponse(() => {
              if (rest.data.shareMyCVState)
                Alert.success(
                  !sharedcv
                    ?  i18n.t("profileForm.yourCVSharedAndVisibleDLMemberCompaniesUnsolicitedApplication") 
                    :  i18n.t("profileForm.YouHaveDisabledSharingResumeItWillLongerVisibleDLMemberCompanies") 
                );
              this.props.refetch();
            });
          })
          .catch((e) => {
            this.onResponse(() => {
              if (e && e.graphQLErrors) Alert.error(e.graphQLErrors[0].message);
            });
          });
      } else Alert.warning( i18n.t("profileForm.youMustUploadLeastCVVersion") );  
    }
  };
  _handleRemove = () => {
    confirmAlert({
      title: i18n.t("profileForm.requestToDeleteYourAccount"),    
      message:
      i18n.t("profileForm.areYouSureYouWantToDeleteYourAccount"),
      buttons: [
        {
          label:  i18n.t("profileForm.no"),
          onClick: () => {},
        },
        {
          label: i18n.t("profileForm.yes"),
          onClick: () =>
            this.props
              .removeCandidat({
                variables: {
                  id: this.props.getCandidatProfile.id,
                  email: this.props.getCandidatProfile.profile.email,
                },
              })
              .then((rest) => {
                if (rest.data.removeCandidat) {
                  ReactPixel.trackCustom("supression du profile", {
                    email: this.props.getCandidatProfile.profile.email,
                    success: true,
                  });
                  ReactPixel.track("Lead", {
                    event: "supression du profile",
                    email: this.props.getCandidatProfile.profile.email,
                    success: true,
                  });
                  this.props
                    .setToken({
                      variables: {
                        token: null,
                      },
                    })
                    .then(() => {
                      localStorage.removeItem("token-candidat");
                    });
                }
              }),
        },
      ],
    });
  };
  handelSelectChange = (e) => {
    e.preventDefault();
    this.setState({
      [e.target.id]: e.target.value,
    });
  };
  onSelect = (value, name) => {
    this.setState({ [name]: value });
  };
  handleChange = (disponibility_days) => {
    this.setState({ disponibility_days: disponibility_days });
  };
  handleChangeT = (disponibility_time) => {
    this.setState({ disponibility_time: disponibility_time });
  };
  pushSelectedSuggestionJobs = (suggestion) => {
    this.setState({ job_id: suggestion.id });
  };
  getValue = (value) => {
    this.setState({ job: value });
  };
  render() {
    const {
      errors,
      loading,
      genre,
      disponibility_days,
      disponibility_time,
      etude,
      country,
      location,
      activity_rate,
      contract,
      experience,
      languages,
      //   job_id,
      tel,
      job,
      address,
      city,
    } = this.state;
    const {
      getCandidatProfile: {
        profile: { is_blocked, id, is_blocked_by_admin },
        last_name,
        first_name,
        avatar,
        cv,
        cv_request,
      },
      getJobs,
    } = this.props;
    return (
      <Col
        md={12}
        className="Profil"
        style={{ paddingLeft: "1em", paddingRight: "1em" }}
      >
        <Row
          style={{ padding: "0rem 2.2rem" }}
          className="nn_pad justify-content-end"
        >
          <Col xs={12} md={5} lg={5} xl={5} className="mt-1">
            <div
              style={{
                position: "relative",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderRadius: "10px",
                padding: "10px 20px",
                marginTop: "10px",
                background: "#eaeaea",
                color: "#777676",
                fontWeight: "bold",
              }}
            >
              <span>
                {is_blocked_by_admin
                  ?       i18n.t("profileForm.suspendedByAdmin")
                  : !is_blocked
                  ?    i18n.t("profileForm.yourCVIsVisibleThePublic")
                  : i18n.t("profileForm.yourCVIsNotVisibleToThePublic")}     
              </span>

              <SwitchTootgle
                isOn={!is_blocked}
                disabled={is_blocked_by_admin}
                onColor="rgb(46, 83, 152)"
                handleToggle={() => this._handleBlock(is_blocked, id)}
              />
            </div>
            <FormFeedback style={{ display: "flex" }}>
              {errors.visibility}
            </FormFeedback>
          </Col>
        </Row>
        <Col xs={12} md={12} lg={12} xl={12} className="mt-1">
          <h3 className="secondTitle">  {  i18n.t("profileForm.personalInformations")} </h3>
        </Col>

        <ListGroup tag="div" className="Profil-group">
          <Col xs={12} md={12} lg={6} xl={6} className=" mb-2">
            <Row>
              <Col xs={12} md={4} lg={4} xl={4} className=" mb-2 p-0">
                <h4 className="TitleProfil mb-2"> { i18n.t("profileForm.profilePicture")}  </h4>
                <div>
                  {avatar ? (
                    <div>
                      <span
                        className="remove-avatar"
                        onClick={this._handleRemoveAvatar}
                      >
                        x
                      </span>
                      <img
                        className="img-logo"
                        ref={this.profilePicture}
                        src={avatar}
                        alt={"logo"}
                      />
                    </div>
                  ) : (
                    <img className="img-logo" src={UserImg} />
                  )}
                </div>
              </Col>
              <Col xs={12} md={8} lg={8} xl={8}>
                <Input
                  style={{ visibility: "hidden" }}
                  id="avatar"
                  name="avatar"
                  type="file"
                  accept="image/jpeg, image/png"
                  onChange={this.updateDoc}
                  invalid={!!this.avatar}
                  innerRef={this.setRef}
                />

                <Label
                  htmlFor="avatar"
                  className="btn Profil-logo__btn__upload font-size-medium medium-padding text-white"
                  style={{ marginRight: 6, marginTop: "6rem" }}
                >
                  <i className="fa fa-camera" />
                  {avatar ?  i18n.t("profileForm.editYourPhoto") :i18n.t("profileForm.chooseAPicture")}   
                </Label>
              </Col>
            </Row>
          </Col>
          <Row>
            <Col xs={12} md={6} lg={6} xl={6} className="Profil-col-left">
              <FormGroup>
                <Label className="Profil-group__label" for="name">
                  {i18n.t("profileForm.fullName")}{" "}
                  <span
                    style={{
                      marginLeft: 5,
                      color: "red",
                      textTransform: "capitalize",
                    }}
                  >
                    * ({i18n.t("profileForm.obligatory")})    
                  </span>
                </Label>
                <Row>
                  <Col xs={12} md={6} lg={6} xl={6} className="mb-2">
                    <Input
                      className="Profil-group__input"
                      innerRef={this.setRef}
                      defaultValue={first_name}
                      type="text"
                      name="first_name"
                      placeholder={i18n.t("profileForm.name")}
                      invalid={!!errors.first_name}
                    />
                    <FormFeedback>{errors.first_name}</FormFeedback>
                  </Col>
                  <Col xs={12} md={6} lg={6} xl={6}>
                    <Input
                      className="Profil-group__input"
                      innerRef={this.setRef}
                      defaultValue={last_name}
                      type="text"
                      name="last_name"
                      placeholder={i18n.t("profileForm.lastName")}
                      invalid={!!errors.last_name}
                    />
                  </Col>
                </Row>

                <FormFeedback>{errors.last_name}</FormFeedback>
              </FormGroup>
            </Col>
            <Col xs={12} md={5} lg={6} xl={6} className="Profil-col-right">
              <FormGroup>
                <Label className="Profil-group__label" for="name">
                   {i18n.t("profileForm.gender")}{" "}
                  <span
                    style={{
                      marginLeft: 5,
                      color: "red",
                      textTransform: "capitalize",
                    }}
                  >
                    * ({i18n.t("profileForm.obligatory")})
                  </span>
                </Label>
                <Select
                  className="Profil-group__input"
                  value={genreConst.filter((exp) => exp.value === genre)[0]}
                  onChange={(genre) =>
                    this.setState({
                      genre: genre.value,
                    })
                  }
                  options={genreConst}
                  multiselect={false}
                />
                {errors.genre && (
                  <span className="span-error">{errors.genre}</span>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6} lg={6} xl={6} className="Profil-col-left">
              <FormGroup>
                <Label className="Profil-group__label" for="name">
                {i18n.t("profileForm.address")}{" "}
                  <span
                    style={{
                      marginLeft: 5,
                      color: "red",
                      textTransform: "capitalize",
                    }}
                  >
                    * ({i18n.t("profileForm.obligatory")})
                  </span>
                </Label>
                <AddressAutocomplete
                  addressEntreprise={{
                    address: address,
                    country: country,
                    city: city,
                    location: location,
                  }}
                  setAddress={this.setAddress}
                />
                {errors.address && (
                  <span className="span-error">{errors.address}</span>
                )}
              </FormGroup>
            </Col>
            <Col xs={12} md={6} lg={6} xl={6} className="Profil-col-left">
              <FormGroup>
                <Label className="Profil-group__label" for="name">
                 {i18n.t("profileForm.phoneNumber")}
                </Label>
                <Input
                  className="Profil-group__input"
                  innerRef={this.setRef}
                  defaultValue={tel}
                  type="tel"
                  name="tel"
                  placeholder={i18n.t("profileForm.phoneNumber")}
                  invalid={!!errors.tel}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="nn_pad">
            <h3 className="secondTitle mb-2 d-block w-100">
              {i18n.t("profileForm.professionalInformation")}
            </h3>

            <Col
              xs={12}
              md={6}
              lg={6}
              xl={6}
              className="Profil-col-left nn_pad"
            >
              <FormGroup>
                <Label className="Profil-group__label" for="name">
                 {i18n.t("profileForm.languageSpoken")}
                </Label>

                <Select
                  id="languages"
                  value={languages}
                  onChange={(languages) =>
                    this.setState({ languages: languages })
                  }
                  options={languagesConst}
                  isMulti={true}
                  isClearable={true}
                  className="Profil-group__input"
                />
              </FormGroup>
            </Col>
            <Col
              xs={12}
              md={6}
              lg={6}
              xl={6}
              className="Profil-col-right nn_pad"
            >
              <FormGroup>
                <Label className="Profil-group__label" for="name">
              {i18n.t("profileForm.levelOfStudies")}
                </Label>
                <Select
                  className="Profil-group__input"
                  value={etudeConst.filter((exp) => exp.value === etude)[0]}
                  onChange={(etude) => this.setState({ etude: etude.value })}
                  options={etudeConst}
                  multiselect={false}
                />
              </FormGroup>
            </Col>

            <Col
              xs={12}
              md={6}
              lg={6}
              xl={6}
              className="Profil-col-right nn_pad"
            >
              <FormGroup>
                <Label className="Profil-group__label" for="name">
                    {i18n.t("profileForm.myExperience")}{" "}
                  <span
                    style={{
                      marginLeft: 5,
                      color: "red",
                      textTransform: "capitalize",
                    }}
                  >
                  * ({i18n.t("profileForm.obligatory")})
                  </span>
                </Label>
                <Select
                  className="Profil-group__input"
                  value={
                    experienceConst.filter((exp) => exp.value === experience)[0]
                  }
                  onChange={(experience) =>
                    this.setState({ experience: experience.value })
                  }
                  options={experienceConst}
                  multiselect={false}
                />
                {errors.experience && (
                  <span className="span-error">{errors.experience}</span>
                )}
              </FormGroup>
            </Col>
            <Col
              xs={12}
              md={6}
              lg={6}
              xl={6}
              className="Profil-col-right nn_pad"
            >
              <FormGroup>
                <Label className="Profil-group__label" for="name">
                  {i18n.t("profileForm.desiredContract")}
                </Label>
                <Select
                  className="Profil-group__input"
                  id="contract"
                  value={contract}
                  onChange={(contract) => this.setState({ contract: contract })}
                  options={contratConst}
                  isMulti={true}
                  isClearable={true}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6} lg={6} xl={6} className="Profil-col-right">
              <FormGroup>
                <Label className="Profil-group__label" for="name">
                {i18n.t("profileForm.jobs")}{" "}
                  <span
                    style={{
                      marginLeft: 5,
                      color: "red",
                      textTransform: "capitalize",
                    }}
                  >
                    * ({i18n.t("profileForm.obligatory")})
                  </span>
                </Label>
                <AutosuggestionInput
                  className="Profil-group__input"
                  items={getJobs && getJobs.jobs ? getJobs.jobs : []}
                  oldValue={job}
                  pushSelectedSuggestion={this.pushSelectedSuggestionJobs}
                  getValue={this.getValue}
                />
                <FormFeedback style={{ display: "flex" }}>
                  {errors.jobs}
                </FormFeedback>
              </FormGroup>
            </Col>
            <Col xs={12} md={6} lg={6} xl={6} className="Profil-col-right">
              <FormGroup>
                <Label className="Profil-group__label" for="name">
                {i18n.t("profileForm.desiredActivityRate")}   
                </Label>
                <Select
                  className="Profil-group__input"
                  value={
                    rateConst.filter((exp) => exp.value === activity_rate)[0]
                  }
                  onChange={(activity_rate) =>
                    this.setState({ activity_rate: activity_rate.value })
                  }
                  options={rateConst}
                  multiselect={false}
                />
              </FormGroup>
            </Col>
          </Row>{" "}
          <Row>
            <Col xs={12} md={6} lg={6} xl={6} className="Profil-col-left">
              <FormGroup>
                <Label className="Profil-group__label" for="name">
                  {i18n.t("profileForm.availablity")}  
                </Label>
                <Row>
                  <Col xs={12} md={6} lg={6} xl={6} className="Profil-col-left">
                    <Select
                      className="Profil-group__input"
                      id="disponibility_days"
                      value={disponibility_days}
                      onChange={this.handleChange}
                      options={daysConst}
                      isMulti={true}
                      isClearable={true}
                    />
                    <FormFeedback>{errors.disponibility_days}</FormFeedback>
                  </Col>
                  <Col xs={12} md={6} lg={6} xl={6} className="Profil-col-left">
                    <Select
                      className="Profil-group__input"
                      value={disponibility_time}
                      onChange={this.handleChangeT}
                      options={timeConst}
                      isMulti={true}
                      isClearable={true}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </Col>
            <Col xs={12} md={6} lg={6} xl={6} className="Profil-col-right">
              <Label className="Profil-group__label" for="name">
             {i18n.t("profileForm.password")} 
              </Label>
              <div className="d-flex NoFlex">
                <Button
                  onClick={() => this.props.history.push("/mot-passe-oublie")}
                  className="Profil-btn__cancel btnPass"
                  size="lg" 
                  text={i18n.t("profileForm.changeThePassword")}   
                  color="secondary"
                  loading={loading}
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-4" style={{ justifyContent: "center" }}>
            <Col xs={12} md={6} lg={6} xl={6} className="text-center">
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {!cv_request && !cv && (
                  <div className="CvTitle mb-2">{i18n.t("profileForm.addYourCVInPDF")}  </div>
                )}
                {cv_request && (
                  <div className="CvTitle mb-2">{i18n.t("profileForm.CVBeingValidated")} </div>
                )}
                {!cv_request && cv && (
                  <div className="CvTitle mb-2">
                {i18n.t("profileForm.CVvalidated")} {" "}
                    <img
                      className=""
                      style={{
                        height: "1.5rem",
                        width: "1.5rem",
                        marginLeft: "10px",
                        marginBottom: 5,
                      }}
                      src={validate}
                    />
                  </div>
                )}
                <span
                  style={{
                    marginLeft: 5,
                    color: "red",
                    textTransform: "capitalize",
                    fontWeight: 600,
                    fontSize: "0.8rem",
                  }}
                >
                  * ({i18n.t("profileForm.obligatory")})  
                </span>
              </div>
              <section
                className="candidatInfo"
                ref={(cvComp) => {
                  this.cvComp = cvComp;
                }}
              >
                <Input
                  style={{ visibility: "hidden", display: "none" }}
                  id="cv"
                  name="cv"
                  type="file"
                  accept="application/pdf"
                  onChange={this.updatePdf}
                />
                <div className="text-right mr-3">
                  <Label
                    htmlFor="cv"
                    className="btn Profil-logo__btn__upload font-size-medium medium-padding text-white pdfBtn"
                  >
                    <i className="fa fa-camera" style={{ marginRight: 6 }} />
                    {cv ? i18n.t("profileForm.editResume") : i18n.t("profileForm.submitCV")}
                  </Label>
                </div>
                <Row className="justify-content-center text-center">
                  <Col xs={12} md={12} lg={12} xl={12}>
                    {(cv_request && cv_request.cv) || cv ? (
                      <PdfApercu
                        className=""
                        file={cv_request ? BASE_URL + cv_request.cv : cv}
                      />
                    ) : (
                      <img className="" style={{ width: "100px" }} />
                    )}
                  </Col>
                </Row>
              </section>
            </Col>
          </Row>
          <Col xs={12} md={12} lg={12} xl={12} className="mt-4">
            <span
              className="text-left w-100 d-block btnSup"
              onClick={this._handleRemove}
            >
              {i18n.t("profileForm.deleteMyAccount")}
            </span>
          </Col>
        </ListGroup>

        <div className="bb_flex">
          <Button
            onClick={() => this.props.history.push("/accueil")}
            className="Profil-btn__cancel btnGray"
            size="lg"
            text={i18n.t("profileForm.cancel")}
            color="secondary"
            loading={loading}
          />
          <Button
            onClick={this._handleSubmit}
            className="Profil-btn__cancel"
            size="lg"
            text={i18n.t("profileForm.save")} 
            color="secondary"
            loading={loading}
          />
        </div>
      </Col>
    );
  }
}
const UPDATE_CANDIDAT = gql`
  mutation updateCandidatDetails($id: ID!, $input: CandidatDetailsInput!) {
    updateCandidatDetails(id: $id, input: $input) {
      id
    }
  }
`;

const REMOVE_CANDIDAT = gql`
  mutation removeCandidat($id: String!, $email: String!) {
    removeCandidat(id: $id, email: $email)
  }
`;

const REMOVE_USER_AVATAR = gql`
  mutation removeUserAvatar {
    removeUserAvatar
  }
`;

const SHARE_MY_CV_STATE = gql`
  mutation shareMyCVState($state: Boolean) {
    shareMyCVState(state: $state)
  }
`;

const UPDATE_AVATAR = gql`
  mutation updateCandidatAvatar($file: Upload!) {
    updateCandidatAvatar(file: $file)
  }
`;
const ADD_CV_REQUEST = gql`
  mutation addCVRequest($file: Upload!, $type: String!) {
    addCVRequest(file: $file, type: $type) {
      id
    }
  }
`;
export default withTranslation()(withRouter(
  compose(
    graphql(UPDATE_CANDIDAT, {
      name: "updateCandidat",
    }),
    graphql(REMOVE_CANDIDAT, {
      name: "removeCandidat",
    }),
    graphql(REMOVE_USER_AVATAR, {
      name: "removeUserAvatar",
    }),
    graphql(SHARE_MY_CV_STATE, {
      name: "shareMyCVState",
    }),
    graphql(ADD_CV_REQUEST, {
      name: "addCVRequest",
    }),
    graphql(SET_TOKEN, {
      name: "setToken",
    }),
    graphql(UPDATE_USER_STATUS, {
      name: "updateUserStatus",
    }),
    graphql(UPDATE_AVATAR, {
      name: "updateCandidatAvatar",
    }),
    graphql(GET_JOBS, {
      options: () => ({
        variables: {
          skip: 0,
          limit: 0,
        },
      }),
      props: ({ data: { loading, error, getJobs } }) => ({
        loading,
        error,
        getJobs,
      }),
    })
  )(ProfileForm))
);
