import React from "react";
import { LinkedInPopUp } from "react-linkedin-login-oauth2";
import { Route, Switch } from "react-router-dom";
import FallbackSpinner from "../../shared/components/FallbackSpinner";
import Candidate from "../candidates";
import ForgotPassword from "../change-password/index";
import FavoritesList from "../favorites/index";
import DefaultDashboard from "../home/index";
import Legal from "../legal/index";
import LogIn from "../login/index";
import Offers from "../offers";
import ProfileDetails from "../profile/index";
import recruteurs from "../recruteurs";
import RGPD from "../rgpd/index";
import SignUp from "../signup/index";
import MainWrapper from "./MainWrapper";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
const publicRoutes = [
  {
    path: ["/connexion/:token", "/connexion"],
    component: LogIn,
    full: true
  },
  {
    path: "/inscription",
    component: SignUp,
    full: true
  }
];
const privateRoutes = [
  {
    path: ["/", "/accueil"],
    component: DefaultDashboard,
    exact: true
  },
  {
    path: "/rgpd",
    component: RGPD,
    exact: true
  },
  {
    path: "/mention-legale",
    component: Legal,
    exact: true
  },
  {
    path: ["/linkedin"],
    component: LinkedInPopUp,
    exact: true
  },
  {
    path: "/offres",
    component: Offers
  },
  {
    path: "/Candidats",
    component: Candidate
  },
  {
    path: "/recruteurs",
    component: recruteurs
  },
  {
    path: "/profil",
    component: ProfileDetails
  },
  {
    path: "/mes-offres-sauvegardees",
    component: FavoritesList
  },
  {
    path: "/mot-passe-oublie",
    component: ForgotPassword,
    exact: true
  }
];

const Router = () => (
  <React.Suspense fallback={<FallbackSpinner />}>
    <MainWrapper>
      <PublicRoute>
     
        <Switch>
          {publicRoutes.map(route => (
            <Route key={route.path} {...route} />
          ))}
          <PrivateRoute>
            <Switch>
              {privateRoutes.map(route => (
                <Route key={route.path} {...route} />
              ))}
            </Switch>
          </PrivateRoute>
        </Switch>
      </PublicRoute>
    </MainWrapper>
  </React.Suspense>
);

export default Router;
