/* eslint-disable react/jsx-no-bind */
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withRouter } from "react-router";
import windowSize from "react-window-size";
import ReactGA from "react-ga";
import ComputerScreen from "./components/ComputerScreen";
import MobileScreen from "./components/MobileScreen";
class recruteurs extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
    windowWidth: PropTypes.number.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      screen: props.windowWidth < 700,
      small: false,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }
  shouldComponentUpdate(nextProps) {
    return nextProps.windowWidth !== this.props.windowWidth;
  }
  updateWindowDimensions() {
    this.setState({ screen: window.innerWidth < 700 });
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateWindowDimensions);
    ReactGA.pageview("/offres");
    if (this.state.screen) {
      this.setState({ small: true });
    } else this.setState({ small: false });
  }

  render() {
    // console.log("this.state.small", this.state.small);
    if (this.props.windowWidth < 700) {
      return <MobileScreen />;
    } else return <ComputerScreen />;
  }
}
export default withRouter(windowSize(recruteurs));
