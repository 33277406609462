import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { compose, graphql } from "react-apollo";
import ReactPixel from "react-facebook-pixel";
import { Link, Redirect, withRouter } from "react-router-dom";
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import {
  DataController,
  DataSearch,
  ReactiveBase,
} from "toolynk-reactivesearch";
import { getStats } from "../../../handler/api/auth";
import { IS_LOGGED_IN } from "../../../handler/queries.local";
import AddressAutocomplete from "../../../shared/components/AddressAutocompleteHome";
import Button from "../../../shared/components/Button";
import Icon from "../../../shared/components/Icon";
import i18n from "../../../locals/i18n";
import { withTranslation } from "react-i18next";

class SearchHome extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      address: "",
      city: null,
      activeTab: "1",
      mot_cle: null,
      offer_name: null,
      offer_city: null,
      location: {},
      rayon: 10,
      stats: [],
      show: true,
      nav: false,
      logged: !!localStorage.getItem("token-candidat"),
    };
  }

  componentDidMount() {
    getStats().then((res) => {
      this.setState({ stats: res });
    });
  }
  setAddress = (object) => {
    if (object) {
      this.setState({
        address: object.address,
        city: object.city,
        location: {
          lat: object.location.lat,
          lon: object.location.lng,
        },
      });
    }
  };
  _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.setState({ nav: true });
      console.log("do validate");
    }
  };
  setName = (name) => {
    this.setState({ mot_cle: name });
  };
  setFormationName = (name) => {
    this.setState({ offer_name: name });
  };
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }
  render() {
    //  const { stats } = this.state;

    if (this.state.nav) {
      if (this.state.activeTab === "1") {
        return (
          <Redirect
            to={{
              pathname: "/recruteurs",
              state: {
                mot_cle: this.state.mot_cle,
                city: this.state.city,
                location: this.state.location,
                rayon: this.state.rayon,
              },
            }}
          />
        );
      }
    }
    return (
      <div className="SearchHome">
        <div className="SearchHome-title">
          <span className="SearchHome-title__white">
            {this.state.activeTab == 1
              ? i18n.t("home.mainPhrase")
              : i18n.t("home.workOrStageOrFormation")}
          </span>
        </div>

        <div
          className="tabs__wrap"
          style={{ marginTop: "2rem", display: "inline-block", width: "70%" }}
        >
          <Nav tabs className="SearchHome-tab">
            <NavItem>
              <NavLink
                className={classnames("SearchHome-tabButton", {
                  "SearchHome-tabButtonA": this.state.activeTab === "1",
                })}
                onClick={() => {
                  this.toggle("1");
                }}
              >
                {i18n.t("home.Recruiters")}
              </NavLink>
            </NavItem>
            {/*    <NavItem>
              <NavLink
                className={classnames("SearchHome-tabButton", {
                  "SearchHome-tabButtonA": this.state.activeTab === "2",
                })}
                onClick={() => {
                  this.toggle("2");
                }}
              >
                Emplois
              </NavLink>
            </NavItem> */}
          </Nav>
          <TabContent
            activeTab={this.state.activeTab}
            style={{ backgroundColor: "transparent", overflow: "unset" }}
          >
            <TabPane tabId="1">
              <ReactiveBase
                app="recrutemoi"
                url="https://elastic.toolynk-lab.com"
                type="entreprises"
              >
                <DataController
                  componentId="DataSensor"
                  dataField={[]}
                  customQuery={() => ({
                    match: {
                      is_blocked: false,
                    },
                    match: {
                      is_blocked_by_admin: false,
                    },
                  })}
                  size={99999999}
                />

                <div className="SearchHome-row__top">
                  <Row
                    style={{ width: "100%" }}
                    onKeyDown={this._handleKeyDown}
                  >
                    <Col
                      xs={12}
                      md={5}
                      lg={5}
                      xl={5}
                      style={{
                        marginRight: 0,
                        backgroundColor: "#fff",
                        paddingRight: "5px",
                        borderRight: "1px solid #e8e8e8",
                        paddingTop: "11px",
                        borderBottomLeftRadius: "10px",
                      }}
                      className="NoBorder"
                    >
                      <DataSearch
                        componentId="SearchSensor"
                        dataField={["name"]}
                        placeholder={i18n.t("home.placeHolderNameVillage")}
                        queryFormat="or"
                        fuzziness={2}
                        fieldWeights={[1]}
                        filterLabel={i18n.t("home.Search")}
                        showFilter={false}
                        URLParams={false}
                        onSuggestion={(suggestion) => ({
                          label: (
                            <Row
                              style={{
                                width: "100%",
                                marginLeft: 0,
                                marginRight: 0,
                                fontFamily: "Montserrat",
                                flexDirection: "column",
                                textAlign: "left",
                                marginLeft: 20,
                                zIndex: 9999,
                              }}
                            >
                              <span
                                style={{
                                  fontWeight: "500",
                                  textTransform: "uppercase",
                                  color: "rgba(41, 94, 190, 1)",
                                  fontFamily: "Montserrat",
                                }}
                              >
                                {suggestion._source.name}
                              </span>

                              {suggestion._source.job && (
                                <span
                                  style={{
                                    fontWeight: "400",
                                    marginLeft: 2,
                                    textTransform: "capitalize",
                                    fontFamily: "Montserrat",
                                  }}
                                >
                                  <Icon
                                    className="MyCV-icon"
                                    name="candidat-brief"
                                  />{" "}
                                  {suggestion._source.job}
                                </span>
                              )}
                            </Row>
                          ),
                          value: suggestion._source.name,
                        })}
                        onValueSelected={(value) => this.setName(value)}
                        renderNoSuggestion={() => (
                          <div>Aucun résultat trouvé</div>
                        )}
                        react={{
                          and: ["DataSensor"],
                        }}
                        icon={
                          <Icon
                            className="location-search-input-icon padding2"
                            name="search-Home"
                            style={{ paddingButtom: "8px" }}
                          />
                        }
                      />
                    </Col>
                    <Col
                      xs={12}
                      md={3}
                      lg={3}
                      xl={3}
                      className="location-search-input-home"
                      style={{
                        display: "flex",
                        backgroundColor: "#fff",
                        borderRight: "1px solid #e8e8e8",
                      }}
                    >
                      <Icon
                        className="location-search-input-icon padding1"
                        name="cv-map"
                      />
                      <AddressAutocomplete
                        addressEntreprise={{}}
                        types={"(cities)"}
                        setAddress={this.setAddress}
                        deafultAddress={this.state.address}
                      />
                    </Col>
                    {/*  <Col
                      xs={12}
                      md={2}
                      lg={2}
                      xl={2}
                      className="location-search-input-home pl-0 pr-0"
                      style={{
                        backgroundColor: "#fff",
                        paddingBottom: "0",
                        paddingTop: "4px",
                      }}
                    >
                      <div className="rayonstyle">Rayon</div>
                      <Select
                        className=""
                        value={
                          rayonConst.filter(
                            (exp) => exp.value === this.state.rayon
                          )[0]
                        }
                        onChange={(rayon) =>
                          this.setState({ rayon: rayon.value })
                        }
                        options={rayonConst}
                        multiselect={false}
                      />
                    </Col> */}
                    <Col
                      xs={12}
                      md={2}
                      lg={2}
                      xl={2}
                      style={{
                        display: "flex",
                        paddingRight: "0",
                        paddingLeft: "0",
                      }}
                    >
                      <Link
                        onClick={() =>
                          ReactPixel.track("Search", {
                            mot_cle: this.state.mot_cle,
                            city: this.state.city,
                            location: this.state.location,
                            rayon: this.state.rayon,
                          })
                        }
                        to={{
                          pathname: "/recruteurs",
                          state: {
                            mot_cle: this.state.mot_cle,
                            city: this.state.city,
                            location: this.state.location,
                            rayon: this.state.rayon,
                          },
                        }}
                        className="SearchHome-btn"
                      >
                        {i18n.t("home.Search")}
                      </Link>
                    </Col>
                  </Row>
                </div>
              </ReactiveBase>
            </TabPane>
          </TabContent>
        </div>
        <div className="registerBox">
          <div className="boxContent">
            <span className="boxContent_text">
              {i18n.t("home.dontHaveAnAccount")}
            </span>
            <Row className="boxContent_Row">
              <Col xs={12} md={6} lg={6} xl={6}>
                <Button
                  className="btn btn-home"
                  type="submit"
                  text={i18n.t("home.createCondidateAccount")}
                  onClick={() => this.props.history.push("/inscription")}
                />
              </Col>
              <Col xs={12} md={6} lg={6} xl={6}>
                <a
                  target="blank"
                  href="https://rmtest.toolynk.fr/recruteur/#/inscription"
                >
                  <Button
                    className="btn btn-home"
                    type="submit"
                    text={i18n.t("home.createCondidateRecruiter")}
                    //onClick={this.handleSubmit}
                  />
                </a>
              </Col>
            </Row>
          </div>
        </div>
        {/*    <div className="CountBox">
          <Col xs={12} md={6} lg={6} xl={6}>
            <Row>
              <Col xs={12} md={4} lg={4} xl={4} className="mb-2">
                <div className="CountItem">
                  <span style={{ fontSize: "18px" }}>{stats[0]}</span>
                  <span style={{ fontSize: "12px" }}> Offres d’emploi</span>
                </div>
              </Col>
              <Col xs={12} md={4} lg={4} xl={4} className="mb-2">
                <div className="CountItem">
                  <span style={{ fontSize: "18px" }}>{stats[1]}</span>
                  <span style={{ fontSize: "12px" }}>Recruteurs</span>
                </div>
              </Col>
              <Col xs={12} md={4} lg={4} xl={4} className="mb-2">
                <div className="CountItem">
                  <span style={{ fontSize: "18px" }}>{stats[2]}</span>
                  <span style={{ fontSize: "12px" }}>Candidats</span>
                </div>
              </Col>
            </Row>
          </Col>
        </div>*/}
      </div>
    );
  }
}
export default withTranslation()(
  withRouter(
    compose(
      graphql(IS_LOGGED_IN, {
        props: ({ data: { isLoggedIn } }) => ({
          isLoggedIn: isLoggedIn ? isLoggedIn.isLoggedIn : false,
        }),
      })
    )(SearchHome)
  )
);
