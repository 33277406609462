import PropTypes from "prop-types";
import React from "react";

function Icon(props) {
  const { name, className } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`icon ${className}`.trim()}
    >
      <use
        xlinkHref={`${process.env.PUBLIC_URL}/img/icons/sprite.svg#${name}`}
      />
    </svg>
  );
}
Icon.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired
};

Icon.defaultProps = {
  className: ""
};

export default Icon;
