/* eslint-disable react/jsx-no-bind */
import PropTypes from "prop-types";
import React, { Component } from "react";
import { compose, graphql } from "react-apollo";
import { withRouter } from "react-router";
import windowSize from "react-window-size";
import { Col, Row } from "reactstrap";
import {
  DataController,
  DataSearch,
  MultiList,
  ReactiveBase,
  ReactiveList,
  SelectedFilters,
  SingleDropdownRange,
  SingleList
} from "toolynk-reactivesearch";
import { IS_LOGGED_IN } from "../../../handler/queries.local";
import { etudeConst, experienceConst } from "../../../handler/utils/constants";
import AddressAutocomplete from "../../../shared/components/AddressAutocompleteHome";
import CollapseFilter from "../../../shared/components/CollapseFilter";
import FallbackSpinner from "../../../shared/components/FallbackSpinner";
import Icon from "../../../shared/components/Icon";
import OfferItemDetail from "./OfferItemDetail";
import Offer_card from "./Offer_card";
import i18n from "../../../locals/i18n";
import { withTranslation } from "react-i18next";

const image = `${process.env.PUBLIC_URL}/img/images/not-description.png`;

let location = null;
class ComputerScreen extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
    windowWidth: PropTypes.number.isRequired,
    isLoggedIn: PropTypes.bool,
  };
  constructor(props) {
    super(props);
    this.state = {
      number: 1,
      offer: null,
      test: false,
      show: true,
      location: null,
      change:false,
      screen: props.windowWidth < 1250,
      city: props.location && props.location.state && props.location.state.city,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.onResultStats = this.onResultStats.bind(this);
    location =
      props.location && props.location.state && props.location.state.location;
  }

  onResultStats = results => {
    if (results !== 0) this.setState({ results });
    return <div />;
  };

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextState.results !== this.state.results ||
      nextState.show !== this.state.show ||
      nextState.city !== this.state.city ||
      nextState.location !== this.state.location ||
      nextState.change !== this.state.change
    );
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }
  updateWindowDimensions() {
    this.setState({ screen: window.innerWidth < 1250 });
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateWindowDimensions);
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.selectedOffer
    ) {
      this.setState({ offer: this.props.location.state.selectedOffer }, () => {
        this.setState({ test: true });
      });
    }
    if (this.state.screen) {
      this.setState({ show: false });
    } else this.setState({ show: true });
  }
  setAddress = object => {
    if (object) {
      this.setState({
        address: object.address,
        city: object.city,
        location: {
          lat: object.location.lat,
          lon: object.location.lng,
        },
      });
      location = {
        lat: object.location.lat,
        lon: object.location.lng,
      };
    }
  };
  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  getItem = offer => {
    const {change} = this.state;

    this.setState({ offer: offer,change: !change });
  };

  showFilter = () => this.setState({ show: !this.state.show });

  render() {
    const { show, city } = this.state;
    let loc = location ? location : this.state.location;

    return (
      <div id="app">
        <ReactiveBase
          app="recrutemoi"
          url="https://elastic.toolynk-lab.com"
          type="offers"
        >
          <Row className="Offers-row pb-4">
            <DataController
              componentId="DataSensor"
              size={10}
              pagination={true}
              dataField={[]}
              customQuery={() => ({
                query: {
                  bool: {
                    must: {
                      match_all: {},
                    },
                    filter: [
                      {
                        term: {
                          shown: true,
                        },
                      },
                    ],
                  },
                },
              })}
            />
            <Col
              xs={12}
              xl={2}
              md={2}
              lg={2}
              className="Offers-col__left"
              style={{ display: show ? "block" : "none" }}
            >
              <Row
                style={{
                  padding: "0px 1rem",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <span className="Entreprises-Filterlabel">{i18n.t("offersComponent.filters")}</span>
                <div onClick={this.showFilter} style={{ cursor: "pointer" }}>
                  <Icon className="collapseIcon" name="collapse" />
                </div>
              </Row>
              <CollapseFilter
                isOpen={false}
                label={i18n.t("offersComponent.search")}
                style={{ paddingBottom: "3rem" }}
              >
                <DataSearch
                  componentId="SearchSensor"
                  dataField={["name", "entreprise", "description_poste"]}
                  placeholder="Mots clés"
                  queryFormat="and"
                  fuzziness={1}
                  fieldWeights={[1, 3, 5]}
                  filterLabel={i18n.t("offersComponent.search")}
                  showFilter={true}
                  URLParams={false}
                  defaultSelected={
                    this.props.location &&
                    this.props.location.state &&
                    this.props.location.state.mot_cle
                      ? this.props.location.state.mot_cle
                      : ""
                  }
                  renderNoSuggestion={() => <div>{i18n.t("offersComponent.noResultFound")}</div>}
                  react={{
                    and: [
                      "DataSensor",
                      "jobSensor",
                      "etudeSensor",
                      "contractSensor",
                      "activity_rateSensor",
                      "DisponibilitéSensor",
                      "ExpérienceSensor",
                      "RayonSensor",
                      "RecruteurSensor",
                    ],
                  }}
                />
              </CollapseFilter>
              <CollapseFilter isOpen={false} label={i18n.t("offersComponent.recruiter")}>
                <SingleList
                  componentId="RecruteurSensor"
                  showSearch={true}
                  filterLabel={i18n.t("offersComponent.recruiter")}
                  selectAllLabel="Tous"
                  dataField="entreprise.keyword"
                  defaultSelected={
                    this.props.location &&
                    this.props.location.state &&
                    this.props.location.state.recruteur
                      ? this.props.location.state.recruteur
                      : ""
                  }
                  renderListItem={(label, count) => (
                    <div style={{ flex: "1" }}>
                      <span className="Offers-label__filter">{label}</span>
                      <span className="Offers-label__count">({count})</span>
                    </div>
                  )}
                  react={{
                    and: [
                      "SearchSensor",
                      "DataSensor",

                      "etudeSensor",
                      "contractSensor",
                      "activity_rateSensor",
                      "DisponibilitéSensor",
                      "ExpérienceSensor",
                      "RayonSensor",
                    ],
                  }}
                />
              </CollapseFilter>
              <CollapseFilter isOpen={false} label={i18n.t("offersComponent.job")}>
                <MultiList
                  componentId="jobSensor"
                  placeholder={i18n.t("offersComponent.search")}
                  queryFormat="or"
                  selectAllLabel="Tous"
                  className="Entreprises-lista"
                  filterLabel={i18n.t("offersComponent.job")}
                  dataField="job.keyword"
                  renderListItem={(label, count) => (
                    <div style={{ flex: "1" }}>
                      <span className="Offers-label__filter">{label}</span>
                      <span className="Offers-label__count">({count})</span>
                    </div>
                  )}
                  react={{
                    and: [
                      "SearchSensor",
                      "DataSensor",

                      "etudeSensor",
                      "contractSensor",
                      "activity_rateSensor",
                      "DisponibilitéSensor",
                      "ExpérienceSensor",
                      "RayonSensor",
                      "RecruteurSensor",
                    ],
                  }}
                />
              </CollapseFilter>
              <CollapseFilter label={i18n.t("offersComponent.levelOfStudies")}>
                <MultiList
                  componentId="etudeSensor"
                  placeholder={i18n.t("offersComponent.search")}
                  queryFormat="or"
                  selectAllLabel="Tous"
                  className="Entreprises-lista"
                  filterLabel={i18n.t("offersComponent.levelOfStudies")}
                  dataField="etude.keyword"
                  renderListItem={(label, count) => (
                    <div style={{ flex: "1" }}>
                      <span className="Offers-label__filter">
                        {etudeConst.filter(exp => exp.value === label)[0].label}
                      </span>
                      <span className="Offers-label__count">({count})</span>
                    </div>
                  )}
                  react={{
                    and: [
                      "SearchSensor",
                      "DataSensor",
                      "jobSensor",

                      "contractSensor",
                      "activity_rateSensor",
                      "DisponibilitéSensor",
                      "ExpérienceSensor",
                      "RayonSensor",
                      "RecruteurSensor",
                    ],
                  }}
                />
              </CollapseFilter>
              <CollapseFilter label={i18n.t("offersComponent.typeOfContract")}>
                <SingleList
                  componentId="contractSensor"
                  showSearch={false}
                  filterLabel={i18n.t("offersComponent.typeOfContract")}
                  selectAllLabel="Tous"
                  dataField="contract.keyword"
                  renderListItem={(label, count) => (
                    <div style={{ flex: "1" }}>
                      <span className="Offers-label__filter">{label}</span>
                      <span className="Offers-label__count">({count})</span>
                    </div>
                  )}
                  className="Entreprises-lista"
                  react={{
                    and: [
                      "SearchSensor",
                      "DataSensor",
                      "jobSensor",

                      "etudeSensor",
                      "activity_rateSensor",
                      "DisponibilitéSensor",
                      "ExpérienceSensor",
                      "RayonSensor",
                      "RecruteurSensor",
                    ],
                  }}
                />
              </CollapseFilter>

              <CollapseFilter label={i18n.t("offersComponent.activityRate")}>
                <MultiList
                  componentId="activity_rateSensor"
                  placeholder={i18n.t("offersComponent.search")}
                  queryFormat="or"
                  selectAllLabel="Tous"
                  className="Entreprises-lista"
                  filterLabel={i18n.t("offersComponent.activityRate")}
                  dataField="activity_rate.keyword"
                  renderListItem={(label, count) => (
                    <div style={{ flex: "1" }}>
                      <span className="Offers-label__filter">{label}</span>
                      <span className="Offers-label__count">({count})</span>
                    </div>
                  )}
                  react={{
                    and: [
                      "SearchSensor",
                      "DataSensor",
                      "jobSensor",
                      "RecruteurSensor",
                      "etudeSensor",
                      "contractSensor",
                      "DisponibilitéSensor",
                      "ExpérienceSensor",
                      "RayonSensor",
                    ],
                  }}
                />
              </CollapseFilter>
              <CollapseFilter label={i18n.t("offersComponent.availablity")}>
                <MultiList
                  componentId="DisponibilitéSensor"
                  placeholder={i18n.t("offersComponent.search")}
                  queryFormat="or"
                  selectAllLabel="Toutes"
                  className="Entreprises-lista"
                  filterLabel={i18n.t("offersComponent.availablity")}
                  dataField="disponibility_days.keyword"
                  renderListItem={(label, count) => (
                    <div style={{ flex: "1" }}>
                      <span className="Offers-label__filter">{label}</span>
                      <span className="Offers-label__count">({count})</span>
                    </div>
                  )}
                  react={{
                    and: [
                      "SearchSensor",
                      "DataSensor",
                      "jobSensor",
                      "RecruteurSensor",
                      "etudeSensor",
                      "contractSensor",
                      "activity_rateSensor",
                      "ExpérienceSensor",
                      "RayonSensor",
                    ],
                  }}
                />
              </CollapseFilter>
              <CollapseFilter label={i18n.t("offersComponent.experience")}>
                <MultiList
                  componentId="ExpérienceSensor"
                  placeholder={i18n.t("offersComponent.search")}
                  queryFormat="or"
                  selectAllLabel="Toutes"
                  className="Entreprises-lista"
                  filterLabel={i18n.t("offersComponent.experience")}
                  dataField="experience.keyword"
                  renderListItem={(label, count) => (
                    <div style={{ flex: "1" }}>
                      <span className="Offers-label__filter">
                        {
                          experienceConst.filter(exp => exp.value === label)[0]
                            .label
                        }
                      </span>
                      <span className="Offers-label__count">({count})</span>
                    </div>
                  )}
                  react={{
                    and: [
                      "SearchSensor",
                      "DataSensor",
                      "jobSensor",
                      "RecruteurSensor",
                      "etudeSensor",
                      "contractSensor",
                      "activity_rateSensor",
                      "DisponibilitéSensor",
                      "RayonSensor",
                    ],
                  }}
                />
              </CollapseFilter>
              <CollapseFilter
                label={i18n.t("offersComponent.location")}
                margin={true}
                isOpen={
                  city &&
                  this.props.location &&
                  this.props.location.state &&
                  this.props.location.state.rayon
                }
              >
                <AddressAutocomplete
                  addressEntreprise={{ address: city }}
                  types={"(cities)"}
                  setAddress={this.setAddress}
                  deafultAddress={{}}
                  withStyle={true}
                />
                {city && (
                  <SingleDropdownRange
                    componentId="RayonSensor"
                    dataField="location"
                    defaultSelected={
                      city &&
                      this.props.location &&
                      this.props.location.state &&
                      this.props.location.state.rayon
                        ? this.props.location.state.rayon +
                        i18n.t("offersComponent.kmAround") +
                          city
                        : i18n.t("offersComponent.10kmAround") + city
                    }
                    data={[
                      {
                        start: 0,
                        end: 1,
                        label: city ? i18n.t("offersComponent.1kmAround") + city : "1Km",
                      },
                      {
                        start: 1,
                        end: 10,
                        label: city ? i18n.t("offersComponent.10kmAround") + city : "10Km",
                      },
                      {
                        start: 10,
                        end: 20,
                        label: city ? i18n.t("offersComponent.20kmAround")  + city : "20Km",
                      },
                      {
                        start: 20,
                        end: 40,
                        label: city ? i18n.t("offersComponent.40kmAround") + city : "40Km",
                      },
                      {
                        start: 40,
                        end: 60,
                        label: city ? i18n.t("offersComponent.60kmAround") + city : "60Km",
                      },
                      {
                        start: 60,
                        end: 80,
                        label: city ? i18n.t("offersComponent.80kmAround")  + city : "80Km",
                      },
                      {
                        start: 80,
                        end: 100,
                        label: city ?  i18n.t("offersComponent.100kmAround")  + city : "100Km",
                      },
                    ]}
                    customQuery={function(value) {
                      if (value && loc)
                        return {
                          query: {
                            bool: {
                              must: {
                                match_all: {},
                              },
                              filter: {
                                geo_distance: {
                                  distance: value.end + "km",
                                  location: loc,
                                },
                              },
                            },
                          },
                        };
                      else
                        return {
                          query: {
                            bool: {
                              must: {
                                match_all: {},
                              },
                            },
                          },
                        };
                    }}
                    placeholder={i18n.t("offersComponent.distanceRadius")}
                    filterLabel={i18n.t("offersComponent.location")}
                    showFilter={true}
                    showSearch={false}
                    className="xcxc"
                    URLParams={false}
                    react={{
                      and: [
                        "SearchSensor",
                        "DataSensor",
                        "jobSensor",
                        "etudeSensor",
                        "contractSensor",
                        "activity_rateSensor",
                        "DisponibilitéSensor",
                        "ExpérienceSensor",
                        "RecruteurSensor",
                      ],
                    }}
                  />
                )}
              </CollapseFilter>
            </Col>
            {!show && (
              <div
                className="Offers-col__left"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: 5,
                }}
              >
                <span
                  className="Entreprises-Filterlabel"
                  style={{ marginBottom: 5 }}
                >
                  {i18n.t("offersComponent.filters")}
                </span>
                <div onClick={this.showFilter} style={{ cursor: "pointer" }}>
                  <Icon className="collapseIcon" name="expand" />
                </div>
              </div>
            )}
            <Col xs={12} xl={4} md={4} lg={4} className="nn_pad">
              <Row
                style={{
                  marginLeft: "0",
                  marginRight: "0",
                }}
                className="NoMargin2"
              >
                <SelectedFilters showClearAll={false} />{" "}
              </Row>
              <div
                style={{
                  backgroundColor: "#fff",
                  paddingTop: "10px",
                }}
              >
                <span
                  style={{
                    position: "absolute",
                    left: "2rem",
                    fontSize: "14px",
                    marginTop: "7px",
                  }}
                >
                 {i18n.t("offersComponent.sortBy")}
                </span>

                <ReactiveList
                  componentId="SearchResult"
                  dataField="name.keyword"
                  className="result-list-container Offers-results Candidates-list GoFlex mr-2"
                  onResultStats={this.onResultStats}
                  size={10}
                  pagination={true}
                  sortOptions={[
                    {
                      label: i18n.t("offersComponent.recent"),
                      dataField: "publishedAt",
                      sortBy: "desc",
                    },
                    {
                      label: i18n.t("offersComponent.ancient"),
                      dataField: "publishedAt",
                      sortBy: "asc",
                    },
                  ]}
                  innerClass={{
                    list: "Candidates-list GoFlex",
                  }}
                  loader={<FallbackSpinner />}
                  onNoResults={
                    <div
                      className="text_of text-center pb-3"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        paddingTop: 60,
                      }}
                    >
                      <Icon className="no_candidate" name="no_offer" />
                    {i18n.t("offersComponent.noOffersFound")}
                    </div>
                  }
                  onAllData={data => this.onAllData(data)}
                  react={{
                    and: [
                      "SearchSensor",
                      "DataSensor",
                      "jobSensor",
                      "genreSensor",
                      "etudeSensor",
                      "contractSensor",
                      "activity_rateSensor",
                      "DisponibilitéSensor",
                      "ExpérienceSensor",
                      "RayonSensor",
                      "RecruteurSensor",
                    ],
                  }}
                />
              </div>
            </Col>

            {this.state.offer && this.state.offer.num ? (
              <Col
                xs={12}
                md={6}
                lg={6}
                xl={show ? 6 : 7}
                style={{ margin: 0, padding: 0 }}
              >
                <OfferItemDetail
                  offer={this.state.offer}
                  isLoggedIn={this.props.isLoggedIn}
                />
              </Col>
            ) : (
              <Col
                xs={12}
                md={6}
                lg={6}
                xl={show ? 6 : 7}
                style={{
                  margin: 0,
                  padding: 0,
                  backgroundColor: "#fff",
                  height: "36rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={image}
                  style={{ width: "20%" }}
                  onError={() => {
                    this.setState({ src: image });
                  }}
                  alt={"cv"}
                />
              </Col>
            )}
          </Row>
        </ReactiveBase>
      </div>
    );
  }
  onAllData = data => {
    if (data !== null) {
      if (data.length == 0) {
        this.setState({ offer: null });
      }
      if (!this.state.test) this.setState({ offer: data[0], test:false });
      return data.map((offer, index) => {
        return (
          <Offer_card
            key={index}
            num={this.state.offer && this.state.offer.num}
            offer={offer}
            getItem={this.getItem}
          />
        );
      });
    }
  };
}
export default withTranslation()(withRouter(
  compose(
    graphql(IS_LOGGED_IN, {
      props: ({ data: { isLoggedIn } }) => ({
        isLoggedIn: isLoggedIn ? isLoggedIn.isLoggedIn : false,
      }),
    }),
  )(windowSize(ComputerScreen))),
);
