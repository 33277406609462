import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
const logo = `${process.env.PUBLIC_URL}/img/images/logo.png`;

function LeftNavBar() {
  return (
    <React.Fragment>
      <div className="LeftNavBar">
        <div className="LeftNavBar-div__logo">
          <Link to="/accueil">
            <img className="logo__nav" src={logo} alt="logo" />
          </Link>
        </div>

        <div className="LeftNavBar-div" />
      </div>
    </React.Fragment>
  );
}

LeftNavBar.propTypes = {
  user: PropTypes.object
};

LeftNavBar.defaultProps = {
  user: {
    fullName: "Esker",
    photoUrl: null
  }
};

export default LeftNavBar;
