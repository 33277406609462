import PropTypes from "prop-types";
import React, { useState } from "react";
const logo = `${process.env.PUBLIC_URL}/img/images/comapny_logo.png`;
const logo2 = `${process.env.PUBLIC_URL}/img/images/logo-placeholder.jpeg`;

function Image(props) {
  const [src, setSrc] = useState(props.name ? props.name : logo);
  const { className } = props;
  return (
    <img
      className={className}
      src={src}
      onError={() => {
        setSrc(props.candidate ? logo2 : logo);
      }}
      alt={"logo"}
    />
  );
}
Image.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  candidate: PropTypes.bool
};

Image.defaultProps = {
  className: ""
};
export default Image;
