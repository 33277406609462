import React from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import PropTypes from "prop-types";
import * as moment from "moment";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import RemoveIcon from "mdi-react/TrashCircleOutlineIcon";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { Link } from "react-router-dom";
const image = `${process.env.PUBLIC_URL}/img/images/empty.png`;
class FavoriteListTable extends React.PureComponent {
  static propTypes = {
    favorites: PropTypes.array,
    mutate: PropTypes.func,
    refetch: PropTypes.func
  };

  static defaultProps = {
    favorites: []
  };
  state = { loading: false };
  _handleCancel = id => {
    this.setState({ loading: true });
    this.props
      .mutate({
        variables: {
          id: id
        }
      })
      .then(() => {
        this.props.refetch();
        this.setState({ loading: false });
      });
  };
  render() {
    return (
      <Table className="Application">
        <Thead>
          <Tr className="Application-tr">
            <Th className="Application-th">Titre de poste / Entreprise</Th>
            <Th className="Application-th"> Publiée le</Th>
            <Th className="Application-th">Expire dans</Th>
            <Th className="Application-th">Lieu de poste</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {this.props.favorites.length > 0 ? (
            this.props.favorites.map(favorite => {
              return (
                <Tr key={favorite.id} className="Application-tr_2">
                  <Td className="Application-td__name">
                    <Link to={`/offre/${favorite.offer.num}`}>
                      {favorite.offer.name}
                    </Link>
                    <br />
                    <span className="Application-td" style={{ padding: 0 }}>
                      {favorite.entreprise.name}
                    </span>
                  </Td>
                  <Td className="Application-td__name">
                    {favorite.offer.publishedAt
                      ? moment(favorite.offer.publishedAt).format("DD/MM/YYYY")
                      : moment().format("DD/MM/YYYY")}
                  </Td>
                  <Td
                    className={
                      moment(favorite.offer.expiredAt).diff(moment(), "days") >
                      0
                        ? "Application-td__name"
                        : "Application-td__name red_ex"
                    }
                  >
                    {favorite.offer.expiredAt
                      ? moment(favorite.offer.expiredAt).diff(
                          moment(),
                          "days"
                        ) > 0
                        ? moment(favorite.offer.expiredAt).diff(
                            moment(),
                            "days"
                          ) + " jours"
                        : "Expirée"
                      : " --"}
                  </Td>

                  <Td className="Application-td__name">
                    {favorite.offer.address}
                  </Td>
                  <Td>
                    <RemoveIcon
                      style={{ fill: "gray", cursor: "pointer" }}
                      onClick={() => this._handleCancel(favorite.id)}
                    />
                  </Td>
                </Tr>
              );
            })
          ) : (
            <div className="noRes">
              <img src={image} />
              <span>Aucun élément dans la liste</span>
            </div>
          )}
        </Tbody>
      </Table>
    );
  }
}
const REMOVE_FAVORITE = gql`
  mutation removeFavorite($id: ID!) {
    removeFavorite(id: $id)
  }
`;
export default graphql(REMOVE_FAVORITE)(FavoriteListTable);
