import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { graphql } from "react-apollo";
import { IS_LOGGED_IN } from "../../handler/queries.local";
//import { refetch } from "../layout/index";
class PrivateRoute extends React.PureComponent {
  static propTypes = {
    children: PropTypes.element.isRequired,
    isLoggedIn: PropTypes.bool,
  };

  render() {
    const { children, isLoggedIn } = this.props;

    return isLoggedIn === false ? (
      <Redirect to="/connexion" />
    ) : (
      <div>{children}</div>
    );
  }
}

export default graphql(IS_LOGGED_IN, {
  props: ({ data: { isLoggedIn } }) => ({
    isLoggedIn: isLoggedIn ? isLoggedIn.isLoggedIn : false,
  }),
})(PrivateRoute);
