import React from "react";
import { Input, Label } from "reactstrap";

import EmailIcon from "mdi-react/EmailIcon";
import Button from "../../../shared/components/Button";
import { invalidEmail } from "../../../handler/errorMessages.json";
import { EMAIL_REGEX, BASE_URL } from "../../../handler/utils/constants";
import axios from "axios";
import PropTypes from "prop-types";
import Alert from "../../../handler/utils/Alert";
import i18n from "../../../locals/i18n";
import { withTranslation } from "react-i18next";

class SendResetPasswordCode extends React.Component {
  static propTypes = {
    setUser: PropTypes.func,
    setLogin: PropTypes.func
  };
  constructor() {
    super();
    this.state = {
      loading: false,
      errors: {
        email: null
      }
    };
  }

  setEmailRef = ref => {
    this.email = ref;
  };

  handleSubmit = () => {
    if (!this.state.loading) {
      this.setState({ loading: true });
      const email = this.email.value.trim();

      let errors = { email: null };
      if (!EMAIL_REGEX.test(email)) errors.email = invalidEmail;

      if (errors.email)
        this.setState({
          errors
        });
      else {
        axios
          .post(BASE_URL + "/auth/password/reset/code/candidat/send", {
            email: email
          })
          .then(response => {
            this.setState({ loading: false });
            if (response.data.success) {
              this.props.setUser(response.data.user);
            } else {
              Alert.warning(i18n.t("errors.mailNotFound"));
            }
          })
          .catch(() => {
        
            this.setState({ loading: false });
          });
      }
    }
  };

  render() {
    const { loading } = this.state;
    return (
      <div className="LoginBox p-0 mx-auto">
              <div className="Login-header border-0 m-0 p-0">
            <span className="mr-2 Signup-Title">{i18n.t("resetPassword.forgotPassword")}</span>{" "}
          </div>
     
        <Label className="Login-label__footer pb-3">
        {i18n.t("resetPassword.useMailToReceiveEmail")}
        </Label>
        <div className="form__form-group p-0">
          <span className="Login-label p-0 ml-0">Email</span>
          <div className="form__form-group-field p-0 ml-0">
            <EmailIcon className="Login-icon" />

            <Input
              name="email"
              type="email"
              className="Login-input ml-0 "
              innerRef={this.setEmailRef}
            />
          </div>
        </div>
<div className="mx-auto mt-4 text-center">       <Button
          className="btn btn-primary Signup-btn btnSend"
          onClick={this.handleSubmit}
          text={i18n.t("resetPassword.send")}
          color="primary"
          loading={loading}
        
        />
        </div>
      
      </div>
    );
  }
}


export default  withTranslation()(SendResetPasswordCode)