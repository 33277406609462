import PropTypes from "prop-types";
import React from "react";

function MainWrapper(props) {
  return (
    <div className="theme-light">
      <div className="wrapper" id="app">
        {props.children}
      </div>
    </div>
  );
}
MainWrapper.propTypes = {
  children: PropTypes.string.isRequired
};

export default MainWrapper;
