import PropTypes from "prop-types";
import React from "react";
import { Container } from "reactstrap";
import OfferItemDetailHeader from "./OfferItemDetailHeader";

function OfferItemDetail(props) {
  const { offer } = props;
  return (
    <Container
      key={offer._id.toString()}
      className="OfferItemDetail"
      style={{ maxWidth: "100%" }}
    >
      <OfferItemDetailHeader offer={offer} isLoggedIn={props.isLoggedIn} />
    </Container>
  );
}
OfferItemDetail.propTypes = {
  offer: PropTypes.object,
  isLoggedIn: PropTypes.bool
};

OfferItemDetail.defaultProps = {
  offer: {}
};

export default OfferItemDetail;
