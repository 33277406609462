import PropTypes from "prop-types";
import React from "react";
import Icon from "../../shared/components/Icon";
function Photo(props) {
  const { url, alt, last_name, first_name } = props;
  return url ? (
    <img className="user-details__photo" src={url} alt={alt} />
    
  ) : (
    <div
      className={"OfferItemDetailHeader-div"}
      style={{ height: "40px", width: "40px" }}
    >

    <div>
      <div
        className="OfferItemDetailHeader-letters"
        style={{ fontSize: "1.1rem" }}
      >
        {`${`${last_name.charAt(0)}${first_name.charAt(0)}`}`.toUpperCase()}
      </div>
   
    </div>
    <Icon className="downArow" name="down-arow" />
    </div>
  );
 
}

Photo.propTypes = {
  url: PropTypes.string,
  alt: PropTypes.string,
  last_name: PropTypes.string,
  first_name: PropTypes.string
};

Photo.defaultProps = {
  url: null,
  alt: "Photo de profil",
  last_name: "M",
  first_name: "M"
};
export default Photo;
