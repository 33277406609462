import * as moment from "moment";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Col, Collapse, Row } from "reactstrap";
import {
  BASE_URL,
  etudeConst,
  experienceConst,
} from "../../../handler/utils/constants";
import Icon from "../../../shared/components/Icon";
import Image from "../../../shared/components/Image";
import OfferDescription from "./OfferDescription";
import SendEmail from "./SendEmail";
import i18n from "../../../locals/i18n";
import { withTranslation } from "react-i18next";

function Offer_card(props) {
  const { num, offer, isLoggedIn } = props;
  const [collapse, setCollapse] = useState(false);
  const [modalSend, setModalSend] = useState(false);

  const {
    _id,
    entreprise_logo,
    name,
    entreprise,
    contract,
    activity_rate,
    createdAt,
    experience,
    address,
    salary,
    salaryH,
    etude,
    disponibility_days,
    disponibility_time,
    salary_type,
    languages,
    job,
    entreprise_id,
    expiredAt,
  } = props.offer;
  const toggle = () => setCollapse(!collapse);
  const lang = languages.map((ln, index) => {
    return ln + (index == languages.length - 1 ? " " : ", ");
  });
  const cont = contract
    ? contract.map((ln, index) => {
        return ln + (index == contract.length - 1 ? " " : ", ");
      })
    : "----";
  return (
    <div
      key={_id}
      className={`CandidateListItem " + ${num === offer.num &&
        " CandidateListItem-active"}`}
      style={{
        borderBottomColor: "#ccc",
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
        marginBottom: 5,
      }}
    >
      <Row>
        <Col
          md={4}
          lg={4}
          xl={3}
          sx={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Link
            to={{
              pathname: "/recruteurs",
              state: { mot_cle: entreprise },
            }}
          >
            <Image
              name={BASE_URL + entreprise_logo}
              className="CandidateListItem-avatar"
            />
          </Link>
        </Col>
        <Col
          md={5}
          lg={5}
          xl={6}
          sx={12}
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <span className="CandidateListItem-name__label">{" " + name}</span>

          <span className="CandidateListItem-name__job">
            <Link
              to={{
                pathname: "/recruteurs",
                state: { mot_cle: entreprise },
              }}
            >
              {entreprise}
            </Link>
          </span>
          <div className="CandidateListItem-div">
            <span className="CandidateListItem-div__label">
              {contract && contract + ", "}
              {activity_rate && activity_rate}
            </span>
          </div>
        </Col>
        <Col
          md={3}
          lg={3}
          xl={3}
          sx={12}
          className="CandidateListItem-createdAt"
        >
          {i18n.t("offerCardMobile.the")} {moment(createdAt).format("DD/MM/YYYY")}
        </Col>
        <Col
          xs={12}
          md={3}
          lg={3}
          xl={3}
          className="OfferItemDetailHeader-col__middle d-flex justify-content-end"
        >
          <div className=" mt-2">
            <Button className="btnVoir mt-2" onClick={toggle}>
              {!collapse ? "Voir plus" : "Voir moins"}
            </Button>
          </div>
        </Col>
        <Collapse isOpen={collapse}>
          <Row className="OfferItemDetailHeader-white pb-3 pt-3  d-flex justify-content-start">
            <SendEmail
              modal={modalSend}
              toggle={() => setModalSend(!modalSend)}
              offer_Name={name}
              entreprise_id={entreprise_id}
            />
            <Col
              xs={12}
              md={12}
              lg={8}
              xl={8}
              className="OfferItemDetailHeader-col__middle"
            >
              <div className="OfferItemDetailHeader-text">{" " + name}</div>
              <div>
                <span className="OfferItemDetailHeader-sub-text">
                  {job && job + ", "}
                  <span style={{ fontSize: "13px", color: "gray" }}>
                    {experience &&
                      experienceConst.filter(exp => exp.value === experience)[0]
                        .label + i18n.t("offerCardMobile.ofExperience")}
                  </span>
                </span>
              </div>
            </Col>
            <div className="boxMail">
              {isLoggedIn && (
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => setModalSend(!modalSend)}
                >
                  <Icon
                    className="CandidateItemDetailHeader-icon GreenIcon"
                    name="cv-envelope"
                  />
                </span>
              )}
            </div>
            <Col xs={12} md={12} lg={12} xl={12} className="PadRes">
              <div style={{ marginTop: "0.5rem" }}>
                <Row>
                  <Col xs={12} md={12} lg={12} xl={12}>
                    <Icon
                      className="OfferItemDetailHeader-icon"
                      name="cv-map"
                    />
                    <span className="OfferItemDetailHeader-sub-info">
                   {i18n.t("offerCardMobile.address")}
                    </span>
                    <span className="OfferItemDetailHeader-sub-infoT">
                      {address ? " " + address : " ---"}
                    </span>
                  </Col>
                  <Col xs={12} md={12} lg={12} xl={12}>
                    <div className="d-flex  align-items-center NoFlex ">
                      <Icon
                        className="OfferItemDetailHeader-icon"
                        name="disponibility-icon"
                      />

                      <span className="OfferItemDetailHeader-sub-info mr-1">
                       {i18n.t("offerCardMobile.availablity")}
                      </span>
                      <span className="OfferItemDetailHeader-sub-infoT d-flex align-items-center ">
                        {disponibility_days &&
                          disponibility_days.map((val, index) => (
                            <React.Fragment key={index}>
                              {val == "Du Lun. au Ven" ? (
                                <span
                                  className="availability-Item"
                                  style={{
                                    width: "8rem",
                                    borderRadius: "10px",
                                  }}
                                >
                                  {i18n.t("offerCardMobile.monToFri")}
                                </span>
                              ) : null}
                              {val == "Du Lun. au Ven" ? null : (
                                <span className="availability-Item">
                                  {val == "Du Lun. au Ven"
                                    ? null
                                    : val.charAt(0) == "M"
                                    ? val.slice(0, 2)
                                    : val.charAt(0)}
                                </span>
                              )}
                            </React.Fragment>
                          ))}
                        <span>{disponibility_time} </span>
                      </span>
                    </div>
                  </Col>

                  <Col xs={12} md={12} lg={5} xl={5}>
                    <Icon
                      className="OfferItemDetailHeader-icon"
                      name="cantract-icon"
                    />
                    <span className="OfferItemDetailHeader-sub-info">
                  {i18n.t("offerCardMobile.contract")}
                    </span>
                    <span className="OfferItemDetailHeader-sub-infoT ml-1">
                      {cont ? cont : "  ---"}
                    </span>
                  </Col>
                  <Col xs={12} md={7} lg={7} xl={7}>
                    <Icon
                      className="OfferItemDetailHeader-icon"
                      name="activity-icon"
                    />
                    <span className="OfferItemDetailHeader-sub-info">
                  {i18n.t("offerCardMobile.activityRate")}
                    </span>
                    <span className="OfferItemDetailHeader-sub-infoT">
                      {activity_rate ? " " + activity_rate : " ---"}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={12} lg={5} xl={5}>
                    <Icon
                      className="OfferItemDetailHeader-icon"
                      name="candidat-etude"
                    />
                    <span className="OfferItemDetailHeader-sub-info">
                      {i18n.t("offerCardMobile.levelOfStudies")}
                    </span>
                    <span className="OfferItemDetailHeader-sub-infoT ml-1">
                      {etude
                        ? " " +
                          etudeConst.filter(exp => exp.value === etude)[0].label
                        : "  ---"}
                    </span>
                  </Col>
                  {salary_type == "Précisez le salaire horaire" ? (
                    <Col xs={12} md={12} lg={7} xl={7}>
                      <Icon
                        className="OfferItemDetailHeader-icon"
                        name="langue-icon"
                      />
                      <span className="OfferItemDetailHeader-sub-info">
                       {i18n.t("offerCardMobile.hourlyWage")}
                      </span>
                      <span className="OfferItemDetailHeader-sub-infoT">
                        {" " + salaryH}
                      </span>
                    </Col>
                  ) : (
                    <Col xs={12} md={12} lg={7} xl={7}>
                      <Icon
                        className="OfferItemDetailHeader-icon"
                        name="langue-icon"
                      />
                      <span className="OfferItemDetailHeader-sub-info">
                      {i18n.t("offerCardMobile.salary")}
                      </span>
                      <span className="OfferItemDetailHeader-sub-infoT">
                        {salary_type == "Selon l'expérience"
                          ?  i18n.t("offerCardMobile.accordingToExperience")
                          :   i18n.t("offerCardMobile.between")+ salary[0] +  i18n.t("offerCardMobile.and") + salary[1]}
                      </span>
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col xs={12} md={12} lg={12} xl={12}>
                    <Icon
                      className="OfferItemDetailHeader-icon"
                      name="languages"
                    />
                    <span className="OfferItemDetailHeader-sub-info">
                   {i18n.t("offerCardMobile.languages")}
                    </span>
                    <span className="OfferItemDetailHeader-sub-infoT ml-1">
                      {lang ? lang : "  ---"}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={12} lg={12} xl={12}>
                    <Icon
                      className="OfferItemDetailHeader-icon"
                      name="date_expriration"
                    />
                    <span className="OfferItemDetailHeader-sub-info">
                   {i18n.t("offerCardMobile.expiresOn")}
                    </span>
                    <span className="OfferItemDetailHeader-sub-infoT ml-1">
                      {moment(expiredAt).format("DD/MM/YYYY")}
                    </span>
                  </Col>
                </Row>
                <Col
                  xs={12}
                  md={12}
                  lg={12}
                  xl={12}
                  style={{ overflow: "hidden" }}
                >
                  {" "}
                  <OfferDescription offer={offer} />{" "}
                </Col>
              </div>
            </Col>
          </Row>
        </Collapse>
      </Row>
    </div>
  );
}
Offer_card.propTypes = {
  offer: PropTypes.object,
  num: PropTypes.number,
  getItem: PropTypes.func,
  isLoggedIn: PropTypes.bool,
};

Offer_card.defaultProps = {
  offer: {},
  num: null,
};

export default  withTranslation()(Offer_card);
