import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import UserDetails from "./UserDetails";
import i18n from "../../../locals/i18n";
import { withTranslation } from "react-i18next";

function RightNavBar(props) {
  return (
    <React.Fragment>
      <div className="RightNavBar-div">
        <Link to="/" className="RightNavBar-div__link">
          {i18n.t("rightNavBar.home")}
        </Link>
      </div>
      {/*       <div className="RightNavBar-div">
        <Link to="/Candidats" className="RightNavBar-div__link">
          Candidats
        </Link>
      </div> */}
      <div className="RightNavBar-div">
        <Link to="/recruteurs" className="RightNavBar-div__link">
        {i18n.t("rightNavBar.recruiters")}
        </Link>
      </div>
      {/*   <div className="RightNavBar-div">
        <Link to="/offres" className="RightNavBar-div__link">
          Emplois
        </Link>
      </div> */}

      <div className="RightNavBar BtnConnexion">
        <UserDetails
          getCandidatProfile={props.getCandidatProfile}
          refetch={props.refetch}
        />
      </div>
    </React.Fragment>
  );
}
RightNavBar.propTypes = {
  getCandidatProfile: PropTypes.object,
  refetch: PropTypes.func,
};

RightNavBar.defaultProps = {
  getCandidatProfile: {
    first_name: "Candidat",
    avatar: null,
  },
};
export default withTranslation()(RightNavBar);
