import PropTypes from "prop-types";
import React from "react";
import { graphql } from "react-apollo";
import { Link } from "react-router-dom";
import ReactGA from "react-ga";
import { SET_TOKEN } from "../../handler/mutations.local";
//import { refetch } from "../layout/index";
import SignUpForm from "./components/SignUpForm";
import { Col, Row, Modal, ModalBody, ModalHeader } from "reactstrap";
import Button from "../../shared/components/Button";
import i18n from "../../locals/i18n";
import { withTranslation } from "react-i18next";

const logo = `${process.env.PUBLIC_URL}/img/images/logo.png`;

class SignUp extends React.PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    setToken: PropTypes.func.isRequired,
  };
  state = {
    modal: false,
  };
  onSuccess = (token) => {
    this.props
      .setToken({
        variables: { token },
      })
      .then(() => {
        this.setState({
          modal: true,
        });
      });
  };
  PopUP = () => {
    //refetch();
    this.props.history.replace({
      pathname: "/profil",
      state: { inscription: true },
    });
  };
  toggle = () => {
    this.setState({
      modal: false,
    });
  };
  componentDidMount() {
    ReactGA.pageview("/inscription");
  }
  render() {
    return (
      <div className="Signup">
        <Modal
          backdrop={false}
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={""}
        >
          <ModalHeader></ModalHeader>
          <ModalBody
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <div className="Login-header border-0 mb-0">
              <span className="mr-2 Signup-Title">
               {i18n.t("signup.YourRegistrationIsSuccessful")}
              </span>
            </div>{" "}
            <div style={{ width: "30%", marginTop: "2rem" }}>
              <Button
                className="btn btn-primary Signup-btn"
                type="submit"
                text="Ok"
                onClick={this.PopUP}
                color="primary"
              />
            </div>
          </ModalBody>
        </Modal>
        <Row style={{ margin: 0, padding: 0 }}>
          <Col xs={12} md={4} lg={4} xl={4} className="Signup-left">
            <div className="Signup-div_logo">
              <Link to="/accueil">
                <img className="Signup-logo" src={logo} alt={"logo"} />
              </Link>
            </div>

            <span className="Signup-text">
            {i18n.t("signup.PostYourCVAndWeTakeCareOfEverything")}
            </span>
          </Col>

          <Col xs={12} md={8} lg={8} xl={8}>
            <div className="d-flex justify-content-center align-items-center h-100">
              <SignUpForm
                onSuccess={this.onSuccess}
                history={this.props.history}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withTranslation()(graphql(SET_TOKEN, {
  name: "setToken",
})(SignUp));
