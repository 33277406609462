import React, { useState } from "react";
import i18n from "../../../locals/i18n";
const Language = {
  FR: "fr-FR",
  EN: "en-US",
  DE: "de-DE",
};

const Lang = () => {
  const [lang, setLang] = useState(i18n.language);

  const changeLanguage = (event) => {
    let language = event.target.value;
    switch (language) {
      case Language.EN:
        setLang(Language.EN);
        i18n.changeLanguage(Language.EN);
        break;
      case Language.DE:
        setLang(Language.DE);
        i18n.changeLanguage(Language.DE);
        break;
      case Language.FR:
        setLang(Language.FR);
        i18n.changeLanguage(Language.FR);
        break;
      default:
        setLang(Language.FR);
        i18n.changeLanguage(Language.FR);
        break;
    }
  };
  return (
    <div>
      <div>
        <select value={lang} name="language" onChange={changeLanguage}>
          <option value={Language.FR}>FR</option>
          <option value={Language.EN}>EN</option>
          <option value={Language.DE}>DE</option>
        </select>
      </div>
    </div>
  );
};

export default Lang;
