import PropTypes from "prop-types";
import React, { useState } from "react";
import { Row } from "reactstrap";
import { BASE_URL } from "../../../handler/utils/constants";
/* import Icon from "../../../shared/components/Icon"; */
import PdfPreview from "../../../shared/components/PdfPreview";
import ModalPreview from "./ModalPreview";
function CandidateDescription(props) {
  const [modal, setmodal] = useState(false);
  const toggle = () => setmodal(!modal);
  const { cv } = props.candidate;
  return (
    <div style={{ width: "100%" }}>
      <ModalPreview modal={modal} cv={cv} toggleModal={toggle} />
      <Row
        style={{
          justifyContent: "center",
          marginBottom: "1rem",
        }}>
        <span className="OfferItemDetailHeader-CV mt-3">Aperçu du CV </span>
      </Row>
      <Row
        style={{
          marginBottom: "1rem",
          position: "relative",
        }}>
        {/*    <div
          style={{
            cursor: "pointer",
            position: "absolute",
            zIndex: 999,
            backgroundColor: "#fff",
            padding: 5,
            right: 0,
          }}
          onClick={() => setmodal(true)}>
          <Icon className="OfferItemDetailHeader-icon loopIcon" name="loop" />
        </div> */}
        <PdfPreview file={BASE_URL + cv} />
      </Row>
    </div>
  );
}
CandidateDescription.propTypes = {
  candidate: PropTypes.object,
};

CandidateDescription.defaultProps = {
  candidate: {},
};
export default CandidateDescription;
