import PropTypes from "prop-types";
import { useEffect } from "react";
import { withRouter } from "react-router-dom";

function ScrollToTop(props) {
  const { location, children } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
}

ScrollToTop.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired,
  children: PropTypes.element.isRequired
};
export default withRouter(ScrollToTop);
