import MenuIcon from "mdi-react/MenuIcon";
import React from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

class NavigationDropDown extends React.PureComponent {
  state = {
    isOpen: false,
  };

  toggle = () => {
    this.setState(({ isOpen }) => ({
      isOpen: !isOpen,
    }));
  };

  componentDidUpdate(prevProps, PrevState) {
    if (PrevState.isOpen !== this.state.isOpen)
      this.setState({ isOpen: this.state.isOpen });
  }

  render() {
    return (
      <Dropdown isOpen={this.state.isOpen} toggle={this.toggle}>
        <DropdownToggle className="navigation-details" tag="div">
          <MenuIcon className="navigation-details__icon" />
        </DropdownToggle>
        <DropdownMenu className="full-width navigation-details__drop" right>
          <DropdownItem className="user-details__down">
            <Link className="navigation-details__link" to="/accueil">
              Accueil
            </Link>
          </DropdownItem>
          {/*    <DropdownItem className="navigation-details__downend">
            <Link
              className="navigation-details__link"
              to={{
                pathname: "/Candidats",
                state: { top: 0 }
              }}
            >
              Candidats
            </Link>
          </DropdownItem> */}
          <DropdownItem className="navigation-details__downend">
            <Link
              className="navigation-details__link"
              to={{
                pathname: "/recruteurs",
                state: { top: 0 },
              }}
            >
              Recruteurs
            </Link>
          </DropdownItem>
          {/*        <DropdownItem className="navigation-details__downend">
            <Link
              className="navigation-details__link"
              to={{
                pathname: "/offres",
                state: { top: 0 }
              }}
            >
              Emplois
            </Link>
          </DropdownItem> */}
        </DropdownMenu>
      </Dropdown>
    );
  }
}

export default NavigationDropDown;
