import PropTypes from "prop-types";
import React from "react";
import NavigationDropDown from "./NavigationDropDown";
import UserDetails from "./UserDetails";

function RightNavBar(props) {
  return (
    <React.Fragment>
      <div className="RightNavBarMin">
        <div className="box_con">
          <UserDetails
            getCandidatProfile={props.getCandidatProfile}
            refetch={props.refetch}
          />
        </div>

        <div className="RightNavBarMin-div">
          <NavigationDropDown />
        </div>
      </div>
    </React.Fragment>
  );
}
RightNavBar.propTypes = {
  getCandidatProfile: PropTypes.object,
  refetch: PropTypes.func
};

RightNavBar.defaultProps = {
  getCandidatProfile: {
    first_name: "Candidat",
    profile_pic_url: null
  }
};

export default RightNavBar;
