import PropTypes from "prop-types";
import React from "react";
import { Col, Row } from "reactstrap";
import { BASE_URL } from "../../../handler/utils/constants";
import Image from "../../../shared/components/Image";

function Recruteur(props) {
  const { num, recruteur } = props;
  const {
    _id,
    profile_pic_url,
    name,
    application_email,
    city
  } = props.recruteur;
  return (
    <div
      key={_id}
      className={`CandidateListItem " + ${num === recruteur.num &&
        " CandidateListItem-active"}`}
    >
      <Row onClick={() => props.getItem(props.recruteur)}>
        <Col
          md={4}
          lg={4}
          xl={3}
          sx={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Image
            name={BASE_URL + profile_pic_url}
            className="CandidateListItem-avatar"
          />
        </Col>
        <Col
          md={6}
          lg={6}
          xl={7}
          sx={12}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <span className="CandidateListItem-name__label">{name + " "}</span>
          <span className="CandidateListItem-name__job">{city}</span>
          <div className="CandidateListItem-div">
            <span className="CandidateListItem-div__label WLab">
              {application_email && application_email}
            </span>
          </div>
        </Col>
        <Col
          md={2}
          lg={2}
          xl={2}
          sx={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start"
          }}
        ></Col>
      </Row>
    </div>
  );
}
Recruteur.propTypes = {
  recruteur: PropTypes.object,
  num: PropTypes.number,
  getItem: PropTypes.func
};

Recruteur.defaultProps = {
  recruteur: {},
  num: null
};

export default Recruteur;
