import gql from "graphql-tag";
import PropTypes from "prop-types";
import React from "react";
import { compose, graphql, withApollo } from "react-apollo";
import { INCREMENT_NOTIFICATION_LENGTH } from "../../../handler/mutations.local";
import { GET_NOTIFICATIONS } from "../../../handler/queries";
import GraphQLResult from "../../../shared/components/GraphQLResult";
import NotificationItem from "../../../shared/components/NotificationItem";
const DEFAULT_LIMIT = 15;

class NotificationsList extends React.PureComponent {
  static propTypes = {
    getNotificationsCandidates: PropTypes.array,
    loading: PropTypes.bool,
    error: PropTypes.object,
    closeMe: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    subscribeToMore: PropTypes.func.isRequired,
    incrementLength: PropTypes.func.isRequired,
    removeNotification: PropTypes.func.isRequired,
    client: PropTypes.any,
    refetch: PropTypes.func,
  };

  static defaultProps = {
    loading: false,
    error: null,
  };
  unsubscribe = null;

  componentDidMount() {
    this.unsubscribe = this.props.subscribeToMore({
      document: CV_REQUEST_ADDED,
      variables: {},
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const { notificationCandidatesAdded } = subscriptionData.data;
        this.props.incrementLength({
          variables: {
            looked: false,
          },
        });
        return {
          ...prev,
          getNotificationsCandidates: [
            notificationCandidatesAdded,
            ...prev.getNotificationsCandidates,
          ],
        };
      },
    });
  }
  componentWillUnmount() {
    this.unsubscribe && this.unsubscribe();
  }

  updateItem = ({ cache, index, updates }) => {
    this.props.closeMe();

    cache.writeQuery({
      query: GET_NOTIFICATIONS,
      variables: {
        skip: 0,
        limit: DEFAULT_LIMIT,
      },
      data: {
        getNotificationsCandidates: [
          ...this.props.getNotificationsCandidates.slice(0, index),
          {
            ...this.props.getNotificationsCandidates[index],
            ...updates,
          },
          ...this.props.getNotificationsCandidates.slice(index + 1),
        ],
      },
    });
  };
  _removeAll = async () => {
    const { removeNotification, closeMe, refetch } = this.props;
    removeNotification({
      variables: {
        id: "",
      },
    }).then(() => {
      refetch();
      closeMe();
    });
  };
  render() {
    const { loading, error, getNotificationsCandidates, isOpen } = this.props;
    const style = { display: isOpen ? "block" : "none" };
    return (
      <React.Fragment>
        <div style={style} className="notifications__marker" />
        <div style={style} className="notifications__container">
          <h6 className="notifications__title">Notifications</h6>
          <GraphQLResult
            loading={loading}
            error={error}
            emptyResult={!loading && !getNotificationsCandidates}
          >
            <div className="notifications__list">
              {getNotificationsCandidates &&
                getNotificationsCandidates.map((notification, index) => (
                  <NotificationItem
                    key={notification.id}
                    index={index}
                    notification={notification}
                    updateNotification={this.updateItem}
                    closeMe={this.props.closeMe}
                  />
                ))}
              {getNotificationsCandidates &&
                getNotificationsCandidates.length == 0 && (
                  <span className="notifications__noItems">
                    aucune notification disponible
                  </span>
                )}
            </div>
            {!loading &&
              getNotificationsCandidates &&
              getNotificationsCandidates.length > 0 && (
                <div className="notifications__footer">
                  <div
                    className="text-white flex-1 notifications__message "
                    style={{ cursor: "pointer" }}
                    onClick={this._removeAll}
                  >
                    Tout effacer
                  </div>
                </div>
              )}
          </GraphQLResult>
        </div>
      </React.Fragment>
    );
  }
}

const CV_REQUEST_ADDED = gql`
  subscription {
    notificationCandidatesAdded {
      id
      message
      candidate_id
      state
      viewed
      createdAt
      type
    }
  }
`;
const ON_NOTIFICATION_REMOVE_ALL = gql`
  mutation removeNotification($id: ID) {
    removeNotification(id: $id)
  }
`;
export default compose(
  graphql(INCREMENT_NOTIFICATION_LENGTH, {
    name: "incrementLength",
  }),
  graphql(ON_NOTIFICATION_REMOVE_ALL, {
    name: "removeNotification",
  }),
  graphql(GET_NOTIFICATIONS, {
    options: ({ skip, limit }) => ({
      fetchPolicy: "network-only",
      variables: {
        skip: skip || 0,
        limit: limit || DEFAULT_LIMIT,
      },
    }),
    props: ({
      data: {
        error,
        loading,
        getNotificationsCandidates,
        refetch,
        subscribeToMore,
      },
    }) => ({
      error,
      loading,
      getNotificationsCandidates,
      refetch,
      subscribeToMore,
    }),
  }),
)(withApollo(NotificationsList));
