import PropTypes from "prop-types";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { Col, Row } from "reactstrap";
import {
  DataController,
  DataSearch,
  ReactiveBase,
  ReactiveList,
  SelectedFilters,
  SingleList,
} from "toolynk-reactivesearch";
import CollapseFilter from "../../../shared/components/CollapseFilter";
import FallbackSpinner from "../../../shared/components/FallbackSpinner";
import Icon from "../../../shared/components/Icon";
import Recruteur_card from "./Recruteur_cardMobile";
import i18n from "../../../locals/i18n";
import { withTranslation } from "react-i18next";

class MobileScreen extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
  };
  constructor(props) {
    super(props);
    this.state = {
      number: 1,
      recruteur: null,
      test: false,
      show: true,
    };
    this.onResultStats = this.onResultStats.bind(this);
  }

  onResultStats = results => {
    if (results !== 0) this.setState({ results });
    return <div />;
  };

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextState.recruteur !== this.state.recruteur ||
      nextState.show !== this.state.show
    );
  }
  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  getItem = recruteur => {
    this.setState({ recruteur: recruteur });
  };

  showFilter = () => this.setState({ show: !this.state.show });

  render() {
    const { show } = this.state;
    return (
      <div id="app">
        <ReactiveBase
          app="recrutemoi"
          url="https://elastic.toolynk-lab.com"
          type="entreprises"
        >
          <Row className="Offers-row pb-4">
            <DataController
              componentId="DataSensor"
              customQuery={() => ({
                query: {
                  bool: {
                    must: {
                      match_all: {},
                    },
                    filter: [
                      {
                        term: {
                          blocked: false,
                        },
                      },
                    ],
                  },
                },
              })}
            />
            {show && (
              <Col xs={12} xl={2} md={2} lg={2} className="Offers-col__left">
                <Row
                  style={{
                    padding: "0px 1rem",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span className="Entreprises-Filterlabel">Filtres:</span>
                  <div onClick={this.showFilter} style={{ cursor: "pointer" }}>
                    <Icon className="collapseIcon" name="collapse" />
                  </div>
                </Row>
                <CollapseFilter
                  isOpen={true}
                  label={i18n.t("recruterComponent.search")}

                  style={{ paddingBottom: "3rem" }}
                >
                  <DataSearch
                    componentId="SearchSensor"
                    dataField={["name"]}
                    placeholder={i18n.t("recruterComponent.name")}
                    queryFormat="or"
                    fuzziness={1}
                    fieldWeights={[1, 3, 5]}
                    filterLabel={i18n.t("recruterComponent.search")}
                    showFilter={true}
                    URLParams={false}
                    defaultSelected={
                      this.props.location &&
                      this.props.location.state &&
                      this.props.location.state.mot_cle
                        ? this.props.location.state.mot_cle
                        : ""
                    }
                    renderNoSuggestion={() => <div>{i18n.t("recruterComponent.noResultFound")}</div>}
                    react={{
                      and: ["DataSensor", "citySensor"],
                    }}
                  />
                </CollapseFilter>
                <CollapseFilter label={i18n.t("recruterComponent.town")}>
                  <SingleList
                    componentId="citySensor"
                    showSearch={false}
                    filterLabel={i18n.t("recruterComponent.town")}
                    className="Entreprises-lista"
                    selectAllLabel="Tous"
                    defaultSelected={
                      this.props.location &&
                      this.props.location.state &&
                      this.props.location.state.city
                        ? this.props.location.state.city
                        : ""
                    }
                    dataField="city.keyword"
                    renderListItem={(label, count) => (
                      <div>
                        <span className="Offers-label__filter">{label}</span>
                        <span className="Offers-label__count">({count})</span>
                      </div>
                    )}
                    react={{
                      and: ["SearchSensor", "DataSensor"],
                    }}
                  />
                </CollapseFilter>
              </Col>
            )}
            {!show && (
              <div
                className="Offers-col__left"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: 5,
                }}
              >
                <span
                  className="Entreprises-Filterlabel"
                  style={{ marginBottom: 5 }}
                >
                  { i18n.t("recruterComponent.noRecruterWasFound")}
                </span>
                <div onClick={this.showFilter} style={{ cursor: "pointer" }}>
                  <Icon className="collapseIcon" name="expand" />
                </div>
              </div>
            )}
            <Col xs={12} xl={4} md={4} lg={4} className="nn_pad">
              <Row
                style={{
                  marginLeft: "0",
                  marginRight: "0",
                }}
                className="NoMargin2"
              >
                {" "}
                <SelectedFilters showClearAll={false} />
              </Row>
              <div
                style={{
                  backgroundColor: "#fff",
                  paddingTop: "10px",
                }}
              >
                <span
                  style={{
                    position: "absolute",
                    left: "2rem",
                    fontSize: "14px",
                    marginTop: "7px",
                  }}
                >
                    {i18n.t("recruterComponent.sortBy")}
                </span>
                <ReactiveList
                  componentId="SearchResult"
                  dataField="name.keyword"
                  className="result-list-container Offers-results Candidates-list GoFlex mr-2"
                  onResultStats={this.onResultStats}
                  size={10}
                  pagination={true}
                  sortOptions={[
                    {
                      label: i18n.t("recruterComponent.recent"),
                      dataField: "createdAt",
                      sortBy: "desc",
                    },
                    {
                      label: i18n.t("recruterComponent.ancient"),
                      dataField: "createdAt",
                      sortBy: "asc",
                    },
                  ]}
                  innerClass={{
                    list: "Candidates-list GoFlex",
                  }}
                  loader={<FallbackSpinner />}
                  onNoResults={
                    <div
                      className="text_of text-center"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        paddingTop: 60,
                      }}
                    >
                      <Icon className="no_candidate" name="no_recruteur" />
                      { i18n.t("recruterComponent.noRecruterWasFound")}
                    </div>
                  }
                  onData={data => this.onData(data)}
                  react={{
                    and: ["SearchSensor", "DataSensor", "citySensor"],
                  }}
                />
              </div>
            </Col>
          </Row>
        </ReactiveBase>
      </div>
    );
  }

  onData = data => {
    return <Recruteur_card recruteur={data} />;
  };
}
export default withTranslation()(withRouter(MobileScreen));
