import axios from "axios";
import EyeIcon from "mdi-react/EyeIcon";
import KeyVariantIcon from "mdi-react/KeyVariantIcon";
import PropTypes from "prop-types";
import React from "react";
import { Input } from "reactstrap";
import Alert from "../../../handler/utils/Alert";
import { BASE_URL } from "../../../handler/utils/constants";
import Button from "../../../shared/components/Button";

export default class ResetPassword extends React.Component {
  static propTypes = {
    user: PropTypes.object,
    token: PropTypes.string,
    setLogin: PropTypes.func
  };
  constructor() {
    super();
    this.state = {
      passwordError: null,
      ConfirmPasswordError: null,
      loading: false,
      showConfirmPassword: false,
      showPassword: false
    };
  }

  setConfirmPasswordRef = ref => {
    this.confirmPassword = ref;
  };

  setPasswordRef = ref => {
    this.password = ref;
  };

  validate = () => {
    const password = this.password.value.trim();
    const confirmPassword = this.confirmPassword.value.trim();
    this.setState({ passwordError: null, confirmPasswordError: null });
    if (password.length < 5) {
      this.setState({ passwordError: "Mot de passe est trop court" });
      return false;
    }
    if (password != confirmPassword) {
      this.setState({
        confirmPasswordError: "Les mots de passe doivent être indentiques"
      });
      return false;
    }
    return true;
  };

  handleSubmit = () => {
    const password = this.password.value.trim();
    if (!this.state.loading && this.validate()) {
      this.setState({ loading: true });
      const data = {
        email: this.props.user.email,
        reset_password_token: this.props.token,
        password: password
      };
      axios
        .post(BASE_URL + "/auth/password/reset/candidat", data)
        .then(response => {
          this.setState({ loading: false });
          if (response.data.success) {
            Alert.success("Votre mot de passe a été modifié avec succès");
            this.props.setLogin();
          } else {
            Alert.warning(
              "Votre code peut être expiré, Veuillez renvoyer un nouveau code"
            );
          }
        })
        .catch(error => {
          console.log(error);
          this.setState({ loading: false });
        });
    }
  };

  showPassword = e => {
    e.preventDefault();
    this.setState({
      showPassword: !this.state.showPassword
    });
  };

  showConfirmPassword = e => {
    e.preventDefault();
    this.setState({
      showConfirmPassword: !this.state.showConfirmPassword
    });
  };
  render() {
    const { loading, showConfirmPassword, showPassword } = this.state;
    return (
      <div className="LoginBox">
     <div className="Login-header border-0 m-0 p-0" >
       <div className="mr-2 Signup-Title mb-3">Réinitialisation du mot de passe</div>
       </div>
        <span className="m-0 LoginLabel">Mot de passe</span>
        <div className="form__form-group-field">
          <KeyVariantIcon className="Login-icon" />

          <Input
            name="password"
            className="Login-input"
            innerRef={this.setPasswordRef}
            type={showPassword ? "text" : "password"}
            placeholder="********"
          />
          <button
            className={`form__form-group-button${
              this.state.showPassword ? " active" : ""
            }`}
            onClick={e => this.showPassword(e)}
          >
            <EyeIcon />
          </button>
        </div>
        {this.state.passwordError && (
          <span style={{ marginLeft: 16, marginBottom: 15, color: "red" }}>
            {this.state.passwordError}
          </span>
        )}
        <br />
        <span className="m-0 Login-label text-uppercase">Confirmation du mot de passe</span>
        <div className="form__form-group-field">
          <KeyVariantIcon className="Login-icon" />

          <Input
            name="confirmPassword"
            className=" Login-input"
            innerRef={this.setConfirmPasswordRef}
            type={showConfirmPassword ? "text" : "password"}
            placeholder="********"
          />
          <button
            className={`form__form-group-button${
              this.state.showConfirmPassword ? " active" : ""
            }`}
            onClick={e => this.showConfirmPassword(e)}
          >
            <EyeIcon />
          </button>
        </div>

        {this.state.confirmPasswordError && (
          <span style={{ marginLeft: 16, marginBottom: 15, color: "red" }}>
            {this.state.confirmPasswordError}
          </span>
        )}
        <br />
        <div className="d-flex  w-100 justify-content-center"><div className=" mt-4">
        <Button
          className="btn btn-primary Signup-btn"
          onClick={this.handleSubmit}
          text="ENVOYER"
          color="primary"
          loading={loading}
        />
        </div>
        </div>
      

      </div>
    );
  }
}
