import React from "react";
import { Container } from "reactstrap";
import { getSettings } from "../../handler/api/auth";
import LegalComponent from "./components/LegalComponent";

class Legal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      condition: "<p><p/>"
    };
  }
  componentDidMount() {
    getSettings().then(res => {
      this.setState({ condition: res.condition });
    });
  }
  render() {
    const { condition } = this.state;
    return (
      <div id="app" style={{ marginTop: "8rem" }}>
        <Container className="dashboard">
          <LegalComponent condition={condition} />
        </Container>
      </div>
    );
  }
}

export default Legal;
