import PropTypes from "prop-types";
import React from "react";
import { Modal, ModalBody } from "reactstrap";
import { BASE_URL } from "../../../handler/utils/constants";
import PdfPreview from "../../../shared/components/PdfPreview";

class ModalPreview extends React.Component {
  static propTypes = {
    toggleModal: PropTypes.func,
    modal: PropTypes.bool,
    cv: PropTypes.string,
  };

  static defaultProps = {
    modal: false,
  };
  constructor(props) {
    super(props);
    this.state = {
      modal: props.modal,
    };
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.modal !== this.props.modal)
      this.setState({ modal: nextProps.modal });
  }
  render() {
    const { modal } = this.state;
    const { toggleModal, cv } = this.props;
    return (
      <Modal isOpen={modal} toggle={toggleModal} className="ModalCV">
        <ModalBody className="dashboard">
          <PdfPreview file={BASE_URL + cv} />
        </ModalBody>
      </Modal>
    );
  }
}
export default ModalPreview;
