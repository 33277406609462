import Parser from "html-react-parser";
import PropTypes from "prop-types";
import React from "react";
import { compose, graphql } from "react-apollo";
import { Link, withRouter } from "react-router-dom";
import { GET_KEYVALUE } from "../../handler/queries.local";
import Candidate from "../candidates";
import ForgotPassword from "../change-password/index";
import FavoritesList from "../favorites/index";
import DefaultDashboard from "../home/index";
import Footer from "../layout/footer/Footer";
import Layout from "../layout/index";
import Legal from "../legal/index";
import Offers from "../offers/index";
import ProfileDetails from "../profile/index";
import recruteurs from "../recruteurs";
import RGPD from "../rgpd/index";
import { IS_LOGGED_IN } from "../../handler/queries.local";
import i18n from "../../locals/i18n";

const routes = [
  {
    path: ["/", "/accueil"],
    component: DefaultDashboard,
    exact: true,
  },
  {
    path: "/rgpd",
    component: RGPD,
    exact: true,
  },
  {
    path: "/mention-legale",
    component: Legal,
    exact: true,
  },
  {
    path: "/offres",
    component: Offers,
    hasParent: false,
    label: i18n.t("publicRoutes.jobs"),
  },
  {
    path: "/Candidats",
    component: Candidate,
    hasParent: false,
    label:  i18n.t("publicRoutes.candidates"),
  },
  {
    path: "/recruteurs",
    component: recruteurs,
    hasParent: false,
    label: i18n.t("publicRoutes.recruiters"),
  },
  {
    path: "/profil",
    component: ProfileDetails,
    hasParent: false,
    label:  i18n.t("publicRoutes.myAccount"),
  },
  {
    path: "/mes-offres-sauvegardees",
    component: FavoritesList,
  },
  {
    path: "/mot-passe-oublie",
    component: ForgotPassword,
    label:     i18n.t("publicRoutes.changePassword") ,
    parentLabel:    i18n.t("publicRoutes.myAccount") ,
    parentPath: "/profil",
  },
];

class PublicRoute extends React.Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
    isLoggedIn: PropTypes.bool,
    KeyValue: PropTypes.string,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
  };

  render() {
    const { children, KeyValue,isLoggedIn } = this.props;

    const { pathname } = this.props.location;
    return (
      <div id="app">
        <Layout isLoggedIn={isLoggedIn} />
        <div className="container__wrap">
          {isLoggedIn&&pathname !== "/" && pathname !== "/accueil" && (
            <div className="pageTitle ">
              {routes.map(route =>
                pathname.includes(route.path) ? (
                  <span className="pageTitle-Title" style={{ display: "none" }}>
                    {route.label}
                  </span>
                ) : null,
              )}
              {isLoggedIn&&<div>
                <Link to="/accueil" className="pageTitle-subTitle">
             {i18n.t("publicRoutes.home")}
                </Link>{" "}
                <span className="pageTitle-subTitle"> {">"} </span>
                {routes.map(route =>
                  pathname.includes(route.path) ? (
                    route.hasParent ? (
                      <React.Fragment>
                        <Link to={route.parentPath}>{route.parentLabel}</Link>{" "}
                        {" / "}
                        {route.label}{" "}
                        {KeyValue && route.showPlus ? Parser(KeyValue) : null}
                      </React.Fragment>
                    ) : (
                      <span className="pageTitle-subTitle">{route.label}</span>
                    )
                  ) : null,
                )}
              </div>}
            </div>
          )}
          {children}
        </div>
        <Footer />
      </div>
    );
  }
}
export default withRouter(
  compose(
    graphql(GET_KEYVALUE, {
      props: ({ data: { KeyValue } }) => ({
        KeyValue: KeyValue ? KeyValue.KeyValue : null,
      }),
    }),graphql(IS_LOGGED_IN, {
      props: ({ data: { isLoggedIn } }) => ({
        isLoggedIn: isLoggedIn ? isLoggedIn.isLoggedIn : false
      })})
  )(PublicRoute),
);
