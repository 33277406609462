import PropTypes from "prop-types";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { Col, Row } from "reactstrap";
import {
  DataController,
  DataSearch,
  ReactiveBase,
  ReactiveList,
  SelectedFilters,
  SingleList,
} from "toolynk-reactivesearch";
import CollapseFilter from "../../../shared/components/CollapseFilter";
import FallbackSpinner from "../../../shared/components/FallbackSpinner";
import Icon from "../../../shared/components/Icon";
import Recruteur_card from "./Recruteur_card";
import RecurteurItemDetail from "./RecurteurItemDetail";
import i18n from "../../../locals/i18n";
import { withTranslation } from "react-i18next";

const image = `${process.env.PUBLIC_URL}/img/images/not-description.png`;

class ComputerScreen extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
  };
  constructor(props) {
    super(props);
    this.state = {
      number: 1,
      recruteur: null,
      test: false,
      change: false,
    };
    this.onResultStats = this.onResultStats.bind(this);
  }

  onResultStats = (results) => {
    if (results !== 0) this.setState({ results });
    return <div />;
  };

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextState.results !== this.state.results ||
      nextState.show !== this.state.show ||
      nextState.change !== this.state.change
    );
  }
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  getItem = (recruteur) => {
    const { change } = this.state;
    this.setState({ recruteur: recruteur, change: !change });
  };

  showFilter = () => this.setState({ show: !this.state.show });

  render() {
    return (
      <div id="app">
        <ReactiveBase
          app="recrutemoi"
          url="https://elastic.toolynk-lab.com"
          type="entreprises"
        >
          <Row className="Offers-row pb-4">
            <DataController
              componentId="DataSensor"
              customQuery={() => ({
                query: {
                  bool: {
                    must: {
                      match_all: {},
                    },
                    filter: [
                      {
                        term: {
                          blocked: false,
                        },
                      },
                    ],
                  },
                },
              })}
            />
            <Col xs={12} xl={2} md={2} lg={2} className="Offers-col__left">
              <CollapseFilter
                isOpen={true}
                label={i18n.t("recruterComponent.search")}
                style={{ paddingBottom: "3rem" }}
              >
                <DataSearch
                  componentId="SearchSensor"
                  dataField={["name"]}
                  placeholder={i18n.t("recruterComponent.name")}
                  queryFormat="or"
                  fuzziness={1}
                  fieldWeights={[1, 3, 5]}
                  filterLabel={i18n.t("recruterComponent.search")}
                  showFilter={true}
                  URLParams={false}
                  defaultSelected={
                    this.props.location &&
                    this.props.location.state &&
                    this.props.location.state.mot_cle
                      ? this.props.location.state.mot_cle
                      : ""
                  }
                  renderNoSuggestion={() => <div>{i18n.t("recruterComponent.noResultFound")}</div>}
                  react={{
                    and: ["DataSensor", "citySensor"],
                  }}
                />
              </CollapseFilter>
              <CollapseFilter label={i18n.t("recruterComponent.town")}>
                <SingleList
                  componentId="citySensor"
                  showSearch={false}
                  filterLabel={i18n.t("recruterComponent.town")}
                  className="Entreprises-lista"
                  selectAllLabel="Tous"
                  defaultSelected={
                    this.props.location &&
                    this.props.location.state &&
                    this.props.location.state.city
                      ? this.props.location.state.city
                      : ""
                  }
                  dataField="city.keyword"
                  renderListItem={(label, count) => (
                    <div>
                      <span className="Offers-label__filter">{label}</span>
                      <span className="Offers-label__count">({count})</span>
                    </div>
                  )}
                  react={{
                    and: ["SearchSensor", "DataSensor"],
                  }}
                />
              </CollapseFilter>
            </Col>
            <Col xs={12} xl={4} md={4} lg={4} className="nn_pad">
              <Row
                style={{
                  marginLeft: "0",
                  marginRight: "0",
                }}
                className="NoMargin2"
              >
                {" "}
                <SelectedFilters showClearAll={false} />
              </Row>
              <div
                style={{
                  backgroundColor: "#fff",
                  paddingTop: "10px",
                }}
              >
                <span
                  style={{
                    position: "absolute",
                    left: "2rem",
                    fontSize: "14px",
                    marginTop: "7px",
                  }}
                >
                {i18n.t("recruterComponent.sortBy")}
                </span>
                <ReactiveList
                  componentId="SearchResult"
                  dataField="name.keyword"
                  className="result-list-container Offers-results Candidates-list GoFlex mr-2"
                  onResultStats={this.onResultStats}
                  size={10}
                  pagination={true}
                  sortOptions={[
                    {
                      label:i18n.t("recruterComponent.recent"),
                      dataField: "createdAt",
                      sortBy: "desc",
                    },
                    {
                      label: i18n.t("recruterComponent.ancient"),
                      dataField: "createdAt",
                      sortBy: "asc",
                    },
                  ]}
                  innerClass={{
                    list: "Candidates-list GoFlex",
                  }}
                  loader={<FallbackSpinner />}
                  onNoResults={
                    <div
                      className="text_of text-center"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        paddingTop: 60,
                      }}
                    >
                      <Icon className="no_candidate" name="no_recruteur" />
                   { i18n.t("recruterComponent.noRecruterWasFound")}
                    </div>
                  }
                  onAllData={(data) => this.onAllData(data)}
                  react={{
                    and: ["SearchSensor", "DataSensor", "citySensor"],
                  }}
                />
              </div>
            </Col>
            {this.state.recruteur && this.state.recruteur.num ? (
              <Col
                xs={12}
                md={6}
                lg={6}
                xl={6}
                style={{ margin: 0, padding: 0 }}
              >
                <RecurteurItemDetail recruteur={this.state.recruteur} />
              </Col>
            ) : (
              <Col
                xs={12}
                md={6}
                lg={6}
                xl={6}
                style={{
                  margin: 0,
                  padding: 0,
                  backgroundColor: "#fff",
                  height: "36rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={image}
                  style={{ width: "20%" }}
                  onError={() => {
                    this.setState({ src: image });
                  }}
                  alt={"image"}
                />
              </Col>
            )}
          </Row>
        </ReactiveBase>
      </div>
    );
  }

  onAllData = (data) => {
    if (data !== null) {
      if (data.length == 0) {
        this.setState({ recruteur: null });
      }
      if (!this.state.test) this.setState({ recruteur: data[0] });
      return data.map((recruteur, index) => {
        return (
          <Recruteur_card
            key={index}
            num={this.state.recruteur && this.state.recruteur.num}
            recruteur={recruteur}
            getItem={this.getItem}
          />
        );
      });
    }
  };
}
export default withTranslation()(withRouter(ComputerScreen));
