import PropTypes from "prop-types";
import React from "react";
import { compose, graphql } from "react-apollo";
import { Redirect } from "react-router-dom";
import { Row } from "reactstrap";
import { GET_CANDIDAT } from "../../handler/queries";
import { IS_LOGGED_IN } from "../../handler/queries.local";
import GraphQlResult from "../../shared/components/GraphQLResult";
import ProfileForm from "./components/ProfileForm";

function ProfileDetails(props) {
  const handleChangeUpdate = () => {
    props.refetch();
  };
  const { loading, error, getCandidatProfile, refetch,isLoggedIn} = props;
  if (!isLoggedIn){
  return (
    <Redirect to="/accueil" />
  ) }else
  return (
    <React.Fragment>
      <Row className="dashboard" style={{ padding:" 0 1em" , background:"#f7f7f7"}}>
        <GraphQlResult
          error={error}
          loading={loading}
          emptyResult={!loading && !getCandidatProfile}
      
        >
          {getCandidatProfile && (
            <ProfileForm
              refetch={refetch}
              getCandidatProfile={getCandidatProfile}
              handleChangeUpdate={handleChangeUpdate}
              loading={loading}
            />
          )}
        </GraphQlResult>
      </Row>
    </React.Fragment>
  );
}
ProfileDetails.propTypes = {
  loading: PropTypes.bool,
  refetch: PropTypes.func,
  error: PropTypes.object,
  getCandidatProfile: PropTypes.object
};

ProfileDetails.defaultProps = {
  loading: false,
  error: null,
  getCandidatProfile: null
};
export default compose(graphql(GET_CANDIDAT, {
  options: () => ({
    fetchPolicy: "network-only"
  }),
  props: ({ data: { loading, error, getCandidatProfile, refetch } }) => ({
    loading,
    error,
    refetch,
    getCandidatProfile
  })
}),
graphql(IS_LOGGED_IN, {
  props: ({ data: { isLoggedIn } }) => ({
    isLoggedIn: isLoggedIn ? isLoggedIn.isLoggedIn : false
  })
}))(ProfileDetails);
