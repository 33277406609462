import gql from "graphql-tag";
import PropTypes from "prop-types";
import React from "react";
import { compose, graphql } from "react-apollo";
import { UPDATE_NOTIFICATIONS_LENGTH } from "../../../handler/mutations.local";
import { COUNT_NOTIFICATION } from "../../../handler/queries";
import Icon from "../../../shared/components/Icon";
import NotificationsList from "./NotificationsList";
class Notifications extends React.PureComponent {
  static propTypes = {
    notificationsLength: PropTypes.number,
    updateNotificationsLength: PropTypes.func.isRequired,
    onNotificationLookedAll: PropTypes.func.isRequired,
    history: PropTypes.object,
  };

  static defaultProps = {
    notificationsLength: 0,
  };

  state = {
    open: false,
  };
  notifications = React.createRef();

  componentDidMount() {
    document
      .getElementById("app")
      .addEventListener("click", this.onClickOutside);
  }

  componentDidUpdate(_, { open }) {
    if (!open && this.state.open && this.props.notificationsLength > 0) {
      const { onNotificationLookedAll } = this.props;
      onNotificationLookedAll({
        variables: {
          id: "",
        },
      }).then(() => {});
      this.props.updateNotificationsLength({
        variables: {
          length: 0,
          viewed: false,
        },
      });
    }
  }

  componentWillUnmount() {
    document
      .getElementById("app")
      .removeEventListener("click", this.onClickOutside);
  }

  toggle = () => {
    this.setState(({ open }) => ({
      open: !open,
    }));
  };

  closeMe = () => {
    this.setState({ open: false });
  };

  onClickOutside = e => {
    if (this.notifications.current.contains(e.target) === false)
      this.setState({ open: false });
  };

  render() {
    const { notificationsLength } = this.props;
    return (
      <div ref={this.notifications} className="notifications">
        <div className="nav-bar__item cursor-pointer" onClick={this.toggle}>
          <Icon className="notif" name="notif" />

          {notificationsLength > 0 && (
            <div className="notifications_number">{notificationsLength}</div>
          )}
        </div>

        <NotificationsList isOpen={this.state.open} closeMe={this.closeMe} />
      </div>
    );
  }
}
const ON_NOTIFICATION_LOOKED_ALL = gql`
  mutation onNotificationLookedAll($id: ID) {
    onNotificationLookedAll(id: $id)
  }
`;
export default compose(
  graphql(UPDATE_NOTIFICATIONS_LENGTH, {
    name: "updateNotificationsLength",
  }),
  graphql(ON_NOTIFICATION_LOOKED_ALL, {
    name: "onNotificationLookedAll",
  }),
  graphql(COUNT_NOTIFICATION, {
    options: {
      fetchPolicy: "network-only",
      variables: {
        viewed: false,
      },
    },
    props: ({ data: { countNotificationsCandidates } }) => ({
      notificationsLength: countNotificationsCandidates,
    }),
  }),
)(Notifications);
