import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";
import { ScrollMode, scrollModePlugin } from "@react-pdf-viewer/scroll-mode";
import "@react-pdf-viewer/scroll-mode/lib/styles/index.css";
import { selectionModePlugin } from "@react-pdf-viewer/selection-mode";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
import PropTypes from "prop-types";
import React from "react";

function PdfPreview(props) {
  const scrollModePluginInstance = scrollModePlugin({
    scrollMode: ScrollMode.Horizontal,
  });
  const selectionModePluginInstance = selectionModePlugin();
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const toolbarPluginInstance = toolbarPlugin();
  const { Toolbar } = toolbarPluginInstance;
  const { file } = props;
  const renderPage = (propsLocal) => (
    <>
      {propsLocal.canvasLayer.children}
      <div
        style={{
          alignItems: "center",
          display: "flex",
          height: "100%",
          justifyContent: "center",
          left: 0,
          position: "absolute",
          top: 0,
          width: "100%",
        }}
      >
        <div
          style={{
            color: "rgba(0, 0, 0, 0.2)",
            fontSize: `${4 * propsLocal.scale}rem`,
            fontWeight: "bold",
            textTransform: "uppercase",
            transform: "rotate(-45deg)",
            userSelect: "none",
          }}
        >
          Coderstalent
        </div>
      </div>
      {propsLocal.annotationLayer.children}
      {propsLocal.textLayer.children}
    </>
  );
  return (
    <div style={{ width: "100%", height: "100%" }}>
      {file ? (
        <>
          <div
            style={{
              alignItems: "center",
              backgroundColor: "#eeeeee",
              borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
              display: "flex",
              padding: "4px",
            }}
          >
            <Toolbar>
              {(props) => {
                const {
                  CurrentPageInput,
                  Download,
                  EnterFullScreen,
                  GoToNextPage,
                  GoToPreviousPage,
                  NumberOfPages,
                  Print,
                  ShowSearchPopover,
                  Zoom,
                  ZoomIn,
                  ZoomOut,
                } = props;
                return (
                  <>
                    <div style={{ padding: "0px 2px" }}>
                      <ShowSearchPopover />
                    </div>
                    <div style={{ padding: "0px 2px" }}>
                      <ZoomOut />
                    </div>
                    <div style={{ padding: "0px 2px" }}>
                      <Zoom />
                    </div>
                    <div style={{ padding: "0px 2px" }}>
                      <ZoomIn />
                    </div>
                    <div style={{ padding: "0px 2px", marginLeft: "auto" }}>
                      <GoToPreviousPage />
                    </div>
                    <div style={{ padding: "0px 2px" }}>
                      <CurrentPageInput /> / <NumberOfPages />
                    </div>
                    <div style={{ padding: "0px 2px" }}>
                      <GoToNextPage />
                    </div>
                    <div style={{ padding: "0px 2px", marginLeft: "auto" }}>
                      <EnterFullScreen />
                    </div>
                    <div style={{ padding: "0px 2px" }}>
                      <Download />
                    </div>
                    <div style={{ padding: "0px 2px" }}>
                      <Print />
                    </div>
                  </>
                );
              }}
            </Toolbar>
          </div>
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
            <Viewer
              fileUrl={file}
              renderPage={renderPage}
              plugins={[
                toolbarPluginInstance,
                scrollModePluginInstance,
                selectionModePluginInstance,
                pageNavigationPluginInstance,
              ]}
            />
          </Worker>
        </>
      ) : (
        <div className="pfd-no">
          <img src={`${process.env.PUBLIC_URL}/img/images/error.png`} />
          <br />
          <span>Document non encore communiqué</span>
        </div>
      )}
    </div>
  );
}
PdfPreview.propTypes = {
  className: PropTypes.string,
  file: PropTypes.any,
  CurrentPageInput: PropTypes.any,
  Download: PropTypes.any,
  EnterFullScreen: PropTypes.any,
  GoToNextPage: PropTypes.any,
  GoToPreviousPage: PropTypes.any,
  NumberOfPages: PropTypes.any,
  Print: PropTypes.any,
  ShowSearchPopover: PropTypes.any,
  Zoom: PropTypes.any,
  ZoomIn: PropTypes.any,
  ZoomOut: PropTypes.any,
};

PdfPreview.defaultProps = {
  className: "",
  file: null,
};
export default PdfPreview;
