import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import Icon from "../../../shared/components/Icon";
import ModalPreview from "../../signup/components/ModalPreview";
import Lang from "./Lang";
import i18n from "../../../locals/i18n";
import { withTranslation } from "react-i18next";
const image = `${process.env.PUBLIC_URL}/img/images/logo-dark.png`;
/* const FacebookImg = `${process.env.PUBLIC_URL}/img/icons/facebook.svg`;
const LinkedImg = `${process.env.PUBLIC_URL}/img/icons/linkedin.svg`; */
class Footer extends React.PureComponent {
  state = {
    modal: false,
  };

  showModal = () => {
    this.setState({
      modal: true,
    });
  };

  toggle = () => {
    this.setState({
      modal: false,
    });
  };

  render() {
    const { modal } = this.state;
    return (
      <div className="Footer">
        <div className="Footer2">
          <ModalPreview modal={modal} toggleModal={this.toggle} />
          <Row className="Footer-row">
            <Col
              xs={12}
              md={3}
              lg={3}
              xl={3}
              className="Footer-col__left"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <img src={image} className="Footer-logo" />

              <hr className="Footer-hr" />

              <span className="mb-2 mt-2 d-flex align-items-center">
                <Icon className="OfferItemDetailHeader-icon" name="mail" />{" "}
                contact@coderstalent.com
              </span>
              <Lang />

              {/*  <span>{i18n.t("common.translate-button")}</span>    <div className="d-flex mb-1">
                <a
                  style={{
                    width: "25px",
                    height: "25px",
                    display: "block",
                    marginRight: "10px",
                  }}
                  target="blank"
                  href="https://www.facebook.com/CodersTalent"
                >
                  <img className="" src={FacebookImg} />
                </a>

                <a
                  style={{
                    width: "25px",
                    height: "25px",
                    display: "block",
                    marginRight: "10px",
                  }}
                  target="blank"
                  href="https://www.linkedin.com/in/CodersTalent-44444444"
                >
                  <img className="" src={LinkedImg} />
                </a>
              </div> */}

              <div />
            </Col>

            <Col xs={12} md={3} lg={3} xl={3} className="Footer-col__middle">
              <h4> {i18n.t("footer.candidates")}</h4>
              <hr className="Footer-hr" />
              <Link to="/inscription" className="Footer-link">
                <span
                  style={{
                    fontWeight: "900",
                    marginRight: "5px",
                    fontSize: "11px",
                    color: "#2da6f6",
                  }}
                  href="https://rmtest.toolynk.fr/recruteur/"
                >
                  {">>"}
                </span>
                {i18n.t("footer.createAnAccount")}
              </Link>
              <Link to="/connexion" className="Footer-link">
                <span
                  style={{
                    fontWeight: "900",
                    marginRight: "5px",
                    fontSize: "11px",
                    color: "#2da6f6",
                  }}
                >
                  {">>"}
                </span>
                {i18n.t("footer.connect")}
              </Link>
              {/*  <Link to="/offres" className="Footer-link">
                {" "}
                <span
                  style={{
                    fontWeight: "900",
                    marginRight: "5px",
                    fontSize: "11px",
                    color: "#2da6f6",
                  }}
                >
                  {">>"}
                </span>
                Offres d'emploi{" "}
              </Link> */}
              <span
                className="Footer-link"
                style={{
                  cursor: "pointer",
                }}
                onClick={this.showModal}
              >
                {" "}
                <span
                  style={{
                    fontWeight: "900",
                    marginRight: "5px",
                    fontSize: "11px",
                    color: "#2da6f6",
                    cursor: "pointer",
                  }}
                >
                  {">>"}
                </span>
                {i18n.t("footer.CGU")}
              </span>
            </Col>
            <Col xs={12} md={3} lg={3} xl={3} className="Footer-col__middle">
              <h4> {i18n.t("footer.recruiters")} </h4>
              <hr className="Footer-hr" />
              <a
                className="Footer-link"
                target="blank"
                href="https://rmtest.toolynk.fr/recruteur/#/inscription"
              >
                {" "}
                <span
                  style={{
                    fontWeight: "900",
                    marginRight: "5px",
                    fontSize: "11px",
                    color: "#2da6f6",
                  }}
                >
                  {">>"}
                </span>{" "}
                {i18n.t("footer.createAnAccount")}
              </a>
              <a
                className="Footer-link"
                target="blank"
                href="https://rmtest.toolynk.fr/recruteur/#/connexion"
              >
                {" "}
                <span
                  style={{
                    fontWeight: "900",
                    marginRight: "5px",
                    fontSize: "11px",
                    color: "#2da6f6",
                  }}
                >
                  {">>"}
                </span>{" "}
                {i18n.t("footer.connect")}
              </a>
              {/*       <Link to="/Candidats" className="Footer-link">
                {" "}
                <span
                  style={{
                    fontWeight: "900",
                    marginRight: "5px",
                    fontSize: "11px",
                    color: "#2da6f6",
                  }}
                >
                  {">>"}
                </span>
                Candidats{" "}
              </Link> */}
            </Col>
          </Row>
          <Row
            className="Footer-row2 justify-content-center"
            style={{ marginLeft: "15px", marginRight: "15px" }}
          >
            <span className="Footer-href__bottom2 text-center pb-2">
              Copyright © 2023
            </span>
          </Row>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Footer);
