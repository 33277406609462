import PropTypes from "prop-types";
import React from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import i18n from "../../locals/i18n";
import { withTranslation } from "react-i18next";

 class AddressAutocomplete extends React.Component {
  static propTypes = {
    setAddress: PropTypes.func,
    addressEntreprise: PropTypes.object,
    types: PropTypes.string,
    withStyle: PropTypes.bool,
  };

  static defaultProps = {
    types: "address",
    withStyle: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      address: props.addressEntreprise.address
        ? props.addressEntreprise.address
        : "",
      zip_code: props.addressEntreprise.zip_code
        ? props.addressEntreprise.zip_code
        : "",
      country: props.addressEntreprise.country
        ? props.addressEntreprise.country
        : "",
      city: props.addressEntreprise.city ? props.addressEntreprise.city : "",
      location: props.addressEntreprise.location
        ? props.addressEntreprise.location
        : {},
    };
  }

  handleChange = address => {
    this.setState({ address });
  };

  handleSelect = address => {
    let addressI = null;
    let zip_codeI = null;
    let countryI = null;
    let cityI = null;
    geocodeByAddress(address)
      .then(async results => {
        let corr = await getLatLng(results[0]);
        addressI = results[0].formatted_address;
        results[0].address_components.map(cmp => {
          cmp.types.map(type => {
            if (type === "postal_code") zip_codeI = cmp.long_name;
            if (type === "country") countryI = cmp.long_name;
            if (type === "locality") cityI = cmp.long_name;
          });
        });

        this.setState({
          address: addressI,
          zip_code: zip_codeI,
          country: countryI,
          city: cityI,
          location: corr,
        });
        this.props.setAddress({
          address: addressI,
          zip_code: zip_codeI,
          country: countryI,
          city: cityI,
          location: corr,
        });
      })
      .catch(error => console.error("Error", error));
  };

  render() {
    const searchOptions = {
      componentRestrictions: { country: ["fr", "be", "ch"] },
      types: [this.props.types],
    };
    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        searchOptions={searchOptions}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div
            style={
              this.props.withStyle
                ? {
                    border: " 1px solid #cccccc",
                    paddingLeft: 14,
                    width: "100%",
                    backgroundColor: "#fff",
                  }
                : { width: "100%", backgroundColor: "#fff" }
            }
          >
            <input
              {...getInputProps({
                placeholder:
                  this.props.types === "(cities)"
                    ? i18n.t("addressAutocomplete.town")
                    : i18n.t("addressAutocomplete.EnterYourAddress"),
                className: "location-search-input-homes",
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion, index) => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: "#fafafa", cursor: "pointer" }
                  : { backgroundColor: "#ffffff", cursor: "pointer" };
                return (
                  <div
                    key={index}
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

export default withTranslation()(AddressAutocomplete)