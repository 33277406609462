import { COUNT_NOTIFICATION } from "../handler/queries";

export default {
  Query: {
    isLoggedIn: (_, {}, { getCacheKey }) =>
      getCacheKey({ __typename: "IsLoggedIn" }),
    KeyValue: (_, {}, { getCacheKey }) =>
      getCacheKey({ __typename: "KeyValue" }),
  },
  Mutation: {
    setToken: (_, { token }, { cache }) => {
      localStorage.setItem("token-candidat", token);
      cache.writeData({
        data: {
          isLoggedIn: {
            __typename: "IsLoggedIn",
            isLoggedIn: !!token,
          },
        },
      });
      return null;
    },
    setKeyName: (_, { KeyValue }, { cache }) => {
      cache.writeData({
        data: {
          KeyValue: {
            __typename: "KeyValue",
            KeyValue: KeyValue,
          },
        },
      });
      return null;
    },
    incrementNotificationsLength: (_, {}, { cache }) => {
      const audio = new Audio("/notification.mp3");
      audio.play();
      const queryParams = {
        query: COUNT_NOTIFICATION,
        variables: {
          viewed: false,
        },
      };

      const { countNotificationsCandidates } = cache.readQuery(queryParams);
      cache.writeQuery({
        ...queryParams,
        data: {
          countNotificationsCandidates: countNotificationsCandidates + 1,
        },
      });
      return null;
    },
    updateNotificationsLength: (_, { length, viewed }, { cache }) => {
      cache.writeQuery({
        query: COUNT_NOTIFICATION,
        variables: {
          viewed,
        },
        data: {
          countNotificationsCandidates: length,
        },
      });
      return null;
    },
  },
};
