import PropTypes from "prop-types";
import React from "react";
import { Col, Row } from "reactstrap";
import {
  BASE_URL,
  etudeConst,
  experienceConst
} from "../../../handler/utils/constants";
import Image from "../../../shared/components/Image";

function Candidate_card(props) {
  const { num, candidate } = props;
  const {
    _id,
    avatar,
    first_name,
    last_name,
    etude,
    job,
    experience
  } = props.candidate;
  return (
    <div
      key={_id}
      className={`CandidateListItem " + ${num === candidate.num &&
        " CandidateListItem-active"}`}
    >
      <Row onClick={() => props.getItem(props.candidate)}>
        <Col
          md={4}
          lg={4}
          xl={3}
          sx={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          {avatar && avatar !== "undefined" ? (
            <Image
              name={BASE_URL + avatar}
              className="CandidateListItem-avatar"
              candidate
            />
          ) : (
            <div
              className={"OfferItemDetailHeader-div"}
              style={{ height: "60px", width: "60px", margin: "0px auto" }}
            >
              <div className="OfferItemDetailHeader-letters">
                {`${`${last_name.charAt(0)}${first_name.charAt(
                  0
                )}`}`.toUpperCase()}
              </div>
            </div>
          )}
        </Col>
        <Col
          md={6}
          lg={6}
          xl={7}
          sx={12}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <span className="CandidateListItem-name__label">
            {first_name + " " + last_name}
          </span>
          <span className="CandidateListItem-name__job">{job}</span>
          <div className="CandidateListItem-div">
            <span className="CandidateListItem-div__label font-weight-normal WLab">
              {etude &&
                etudeConst.filter(exp => exp.value === etude)[0].label + ", "}
              {experience &&
                experienceConst.filter(exp => exp.value === experience)[0]
                  .label + " d'éxpérience"}
            </span>
          </div>
        </Col>
        <Col
          md={2}
          lg={2}
          xl={2}
          sx={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start"
          }}
        ></Col>
      </Row>
    </div>
  );
}
Candidate_card.propTypes = {
  candidate: PropTypes.object,
  num: PropTypes.number,
  getItem: PropTypes.func
};

Candidate_card.defaultProps = {
  candidate: {},
  num: null
};

export default Candidate_card;
