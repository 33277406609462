import React from "react";
import { Row } from "reactstrap";
import ReactGA from "react-ga";
import AddNewCV from "./components/AddNewCV";
/* import Jobs from "./components/Jobs";
import JobsList from "./components/JobsList"; */
import SearchHome from "./components/SearchHome";
import { withTranslation } from "react-i18next";

class DefaultDashboard extends React.PureComponent {
  componentDidMount() {
    ReactGA.pageview("/accueil");
  }

  render() {
    return (
      <div>
        <Row style={{ margin: 0 }}>
          <SearchHome />
          {/*       <Jobs />
          <JobsList /> */}

          <AddNewCV />
          <div style={{ height: 50, width: 10 }}></div>
        </Row>
      </div>
    );
  }
}

export default withTranslation()(DefaultDashboard);
