import PropTypes from "prop-types";
import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { getSettings } from "../../../handler/api/auth";
import LegalComponent from "../../legal/components/LegalComponent";
import i18n from "../../../locals/i18n";
import { withTranslation } from "react-i18next";

class ModalPreview extends React.Component {
  static propTypes = {
    toggleModal: PropTypes.func,
    modal: PropTypes.bool,
  };

  static defaultProps = {
    modal: false,
  };
  constructor(props) {
    super(props);
    this.state = {
      modal: props.modal,
      condition: "<p><p/>",
    };
  }
  componentDidMount() {
    getSettings().then(res => {
      this.setState({ condition: res.condition });
    });
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.modal !== this.props.modal)
      this.setState({ modal: nextProps.modal });
  }
  render() {
    const { modal, condition } = this.state;
    const { toggleModal } = this.props;
    return (
      <Modal isOpen={modal} toggle={toggleModal} className="ModalCV">
        <ModalHeader
          style={{
            textTransform: "uppercase",
            height: "5rem",
            backgroundColor: "#2e5398",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "2rem",
          }}
        >
          {i18n.t("generalConditions")}
        </ModalHeader>
        <ModalBody className="dashboard">
          <LegalComponent condition={condition} />
        </ModalBody>
      </Modal>
    );
  }
}
export default withTranslation()(ModalPreview);
