import EmailIcon from "mdi-react/EmailIcon";
import EyeIcon from "mdi-react/EyeIcon";
import KeyVariantIcon from "mdi-react/KeyVariantIcon";
import PropTypes from "prop-types";
import React, { Component } from "react";
//import GoogleIcon from "mdi-react/GoogleIcon";
//import FacebookIcon from "mdi-react/FacebookIcon";

import { Input } from "reactstrap";
import { signin, signiWthLinkedIn, signup } from "../../../handler/api/auth";
import { auth, invalidEmail } from "../../../handler/errorMessages.json";
import Alert from "../../../handler/utils/Alert";
import { EMAIL_REGEX } from "../../../handler/utils/constants";
import Button from "../../../shared/components/Button";
import i18n from "../../../locals/i18n";
import { withTranslation } from "react-i18next";

class LogInForm extends Component {
  static propTypes = {
    history: PropTypes.object,
    onSuccess: PropTypes.func.isRequired,
    setForgotPasword: PropTypes.func.isRequired,
    setUser: PropTypes.func,
  };
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      loading: false,
      message: "",
      errors: {
        email: null,
        password: null,
      },
    };
  }

  email = null;
  password = null;

  setEmailRef = (ref) => {
    this.email = ref;
  };

  setPasswordRef = (ref) => {
    this.password = ref;
  };

  showPassword = (e) => {
    e.preventDefault();
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  loginWithGoogle = () => {
    const { onSuccess } = this.props;
    window.OAuth.initialize("fzjokJZ5VjBmzvl2GKu1nCm_cgk");
    window.OAuth.popup("google").done(function(result) {
      result.me().done(function(data) {
        signiWthLinkedIn({ email: data.email })
          .then(({ success, token }) => {
            if (!success) {
              signup({
                email: data.email,
                password: "$CodersTalent$",
                last_name: data.raw.names[0].familyName || "candidat",
                first_name: data.raw.names[0].givenName || "candidat",
              })
                .then(({ success, token, message }) => {
                  if (!success) {
                    Alert.warning(message);
                    this.setState({
                      loading: false,
                      errors: { email: null, password: null },
                    });
                  } else {
                    onSuccess(token);
                  }
                })
                .catch(() => {});
            } else {
              onSuccess(token);
            }
          })
          .catch(() => {});
      });
    });
  };
  loginWithFacebook = () => {
    const { onSuccess } = this.props;
    window.OAuth.initialize("fzjokJZ5VjBmzvl2GKu1nCm_cgk");
    window.OAuth.popup("facebook").done(function(result) {
      result.me().done(function(data) {
        signiWthLinkedIn({ email: data.email })
          .then(({ success, token }) => {
            if (!success) {
              signup({
                email: data.email,
                password: "$CodersTalent$",
                last_name: data.lastname || "candidat",
                first_name: data.firstname || "candidat",
              })
                .then(({ success, token, message }) => {
                  if (!success) {
                    Alert.warning(message);
                    this.setState({
                      loading: false,
                      errors: { email: null, password: null },
                    });
                  } else {
                    onSuccess(token);
                  }
                })
                .catch(() => {});
            } else {
              onSuccess(token);
            }
          })
          .catch(() => {});
      });
      // do some stuff with result
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const email = this.email.value.trim();
    const password = this.password.value.trim();
    let errors = { email: null, password: null };
    if (!email) errors.email = i18n.t("errors.fieldRequired");
    else if (!EMAIL_REGEX.test(email)) errors.email = invalidEmail;
    if (!password) errors.password = i18n.t("errors.fieldRequired");
    else if (password.length < 6) errors.password = auth.invalidPassword;
    if (errors.email || errors.password)
      this.setState({
        errors,
      });
    else {
      this.setState({ loading: true });
      signin({ email, password })
        .then(({ success, token, message }) => {
          if (!success) {
            this.setState({ loading: false }, () => {
              Alert.warning(message);
              this.password.value = "";
              this.setState({
                loading: false,
                errors: { email: null, password: null },
              });
            });
          } else {
            this.props.onSuccess(token);
          }
        })
        .catch(() => {
          this.setState({ loading: false }, () => {});
        });
    }
  };
  responseFacebook = (response) => {
    if (response) {
      const { email, name } = response;
      const { onSuccess } = this.props;
      const index = name.indexOf(" ");
      const first_name = name.substr(0, index);
      const last_name = name.substr(index + 1);
      signiWthLinkedIn({ email })
        .then(({ success, token }) => {
          if (!success) {
            signup({
              email: email,
              password: "CodersTalent$$",
              last_name: last_name,
              first_name: first_name,
            })
              .then(({ success, token, message }) => {
                if (!success) {
                  Alert.warning(message);
                  this.setState({
                    loading: false,
                    errors: { email: null, password: null },
                  });
                } else {
                  onSuccess(token);
                }
              })
              .catch(() => {});
          } else {
            onSuccess(token);
          }
        })
        .catch(() => {});
    }
  };
  render() {
    const { showPassword, loading, errors } = this.state;
    return (
      <div>
        <div className="LoginBox p-0 mx-auto">
          <div className="Login-header border-0 m-0 p-0">
            <span className="mr-2 Signup-Title">
              {" "}
              {i18n.t("login.connect")}
            </span>
          </div>
          <span className="Login-label__footer pb-3">
            {i18n.t("login.enterYourCredentials")}
          </span>
          <div
            className="form"
            style={{ flexDirection: "row-reverse", justifyContent: "flex-end" }}
          >
            <div className="form__form-group">
              <span className=" m-0 LoginLabel">{i18n.t("login.mail")}</span>
              <div className="form__form-group-field m-0">
                <EmailIcon className="Login-icon" />

                <Input
                  name="email"
                  type="email"
                  className="Login-input"
                  style={{ fontSize: 14 }}
                  innerRef={this.setEmailRef}
                />
              </div>
              {errors.email && (
                <span
                  style={{
                    fontSize: 12,
                    width: "100%",
                    color: "#f50101",
                    textTransform: "initial",
                  }}
                >
                  {errors.email}
                </span>
              )}
            </div>
            <div className="form__form-group mb-2">
              <div className="d-flex justify-content-between">
                <span className=" m-0 LoginLabel">
                  {i18n.t("login.password")}
                </span>
              </div>
              <div className="form__form-group-field m-0 ">
                <KeyVariantIcon className="Login-icon" />

                <Input
                  name="password"
                  className="Login-input"
                  style={{ fontSize: 14 }}
                  innerRef={this.setPasswordRef}
                  type={showPassword ? "text" : "password"}
                  placeholder="********"
                />
                <button
                  className={`form__form-group-button${
                    this.state.showPassword ? " active" : ""
                  }`}
                  onClick={(e) => this.showPassword(e)}
                >
                  <EyeIcon />
                </button>
              </div>
              {errors.password && (
                <span
                  style={{
                    fontSize: 12,
                    width: "100%",
                    color: "#f50101",
                    textTransform: "initial",
                  }}
                >
                  {errors.password}
                </span>
              )}
            </div>
            <div
              style={{
                justifyContent: "space-between",
                display: "flex",
                width: "100%",
              }}
            >
              <div className="Login-label-forget m-0 p-0 Login-label__footer  mb-2">
                <span onClick={this.props.setForgotPasword}>
                  {i18n.t("login.forgotPassword")}
                </span>
              </div>
              <span
                onClick={() => this.props.history.push("/inscription")}
                className="Login-label-sincrir"
              >
                {i18n.t("login.signup")}
              </span>
            </div>
            <div className="d-flex  w-100 justify-content-center">
              <div className=" mt-4">
                <Button
                  className="btn btn-primary Signup-btn"
                  onClick={this.handleSubmit}
                  text={i18n.t("login.login")}
                  color="primary"
                  loading={loading}
                />
              </div>
              <span>{this.state.message}</span>
            </div>
            {/* <hr className={"Login-hr"} />
            <div
              className="d-flex"
              style={{
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span className=" m-0 LoginLabel">Ou continuer avec</span>
            </div>
            <div
              className="d-flex  w-100 justify-content-center  FlexTab"
              style={{ alignItems: "center", justifyContent: "center" }}
            >
              <div className=" mt-4" style={{ marginRight: 15 }}>
                <Button2
                  className="btn btn-primary kep-login-google"
                  style={{ padding: 0, fontSize: "0.8rem" }}
                  onClick={this.loginWithGoogle}
                  loading={loading}
                >
                  <GoogleIcon
                    style={{ width: 20, height: 30, marginTop: -5 }}
                  />{" "}
                  <span className="btn_linked">Google</span>
                </Button2>
              </div>
              <div className=" mt-4">
                <Button2
                  className="btn btn-primary kep-login-facebook"
                  style={{ padding: 0, fontSize: "0.8rem" }}
                  onClick={this.loginWithFacebook}
                  loading={loading}
                >
                  <FacebookIcon
                    style={{ width: 20, height: 30, marginTop: -5 }}
                  />{" "}
                  <span className="btn_linked">Facebook</span>
                </Button2>
              </div>
            </div>
          */}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(LogInForm);
