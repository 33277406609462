import PropTypes from "prop-types";
import React from "react";
import { Container } from "reactstrap";
import RecruteurItemDetailHeader from "./RecruteurItemDetailHeader";

function RecurteurItemDetail(props) {
  const { recruteur } = props;
  return (
    <Container
      key={recruteur._id.toString()}
      className="OfferItemDetail"
      style={{ maxWidth: "100%" }}
    >
      <RecruteurItemDetailHeader recruteur={recruteur} />
    </Container>
  );
}
RecurteurItemDetail.propTypes = {
  recruteur: PropTypes.object,
};

RecurteurItemDetail.defaultProps = {
  recruteur: {},
};

export default RecurteurItemDetail;
