import React from "react";
import { Link } from "react-router-dom";
const logo = `${process.env.PUBLIC_URL}/img/images/logo.png`;

function LeftNavBar() {
  return (
    <React.Fragment>
      <div className="LeftNavBarMin">
        <div className="LeftNavBarMin-div">
          <div className="LeftNavBarMin-div__logo">
            <Link to="/accueil">
              <img className="LeftNavBarMin-logo__nav" src={logo} alt="logo" />
            </Link>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default LeftNavBar;
