import gql from "graphql-tag";
import * as moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { graphql } from "react-apollo";
import { Link } from "react-router-dom";

class NotificationItem extends React.PureComponent {
  static propTypes = {
    notification: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    updateNotification: PropTypes.func,
    onNotificationLookedCandidate: PropTypes.func.isRequired,
    closeMe: PropTypes.func
  };

  static defaultProps = {
    updateNotification: () => {}
  };

  static fragments = {
    notificationItemDetails: gql`
      fragment NotificationItemDetails on NotificationC {
        id
        state
        viewed
        message
        createdAt
      }
    `
  };

  updateNotif = () => {
    const {
      index,
      updateNotification,
      onNotificationLookedCandidate,
      notification: { id, viewed }
    } = this.props;

    if (!viewed) {
      onNotificationLookedCandidate({
        variables: {
          id
        },
        update: (
          cache,
          { data: { onNotificationLookedCandidate: viewed } }
        ) => {
          if (viewed)
            updateNotification({
              cache,
              index,
              updates: { notification: { viewed } }
            });
        }
      });
    }
  };

  render() {
    const { message, createdAt, viewed } = this.props.notification;
    return (
      <div onClick={this.updateNotif}>
        <Link
          className={`notifications__item ${
            !viewed ? "notifications__item--not-looked" : ""
          }`.trim()}
          to={"/profil"}
          onClick={this.props.closeMe}
        >
          <span className="notifications__message">{message}</span>
          <span className="notifications__date">
            {" "}
            {moment(createdAt).format("DD/MM/YYYY")}
          </span>
        </Link>
      </div>
    );
  }
}

const ON_NOTIFICATION_LOOKED = gql`
  mutation onNotificationLookedCandidate($id: ID!) {
    onNotificationLookedCandidate(id: $id)
  }
`;

export default graphql(ON_NOTIFICATION_LOOKED, {
  name: "onNotificationLookedCandidate"
})(NotificationItem);
