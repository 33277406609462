import FilterIcon from "mdi-react/FilterIcon";
import MinusIcon from "mdi-react/MinusIcon";
import PlusIcon from "mdi-react/PlusIcon";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Collapse } from "reactstrap";

class CollapseFilter extends Component {
  static propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    icon: PropTypes.bool,
    isOpen: PropTypes.bool,
    children: PropTypes.any.isRequired,
    margin: PropTypes.bool,
  };
  static defaultProps = {
    className: null,
    label: "",
    isOpen: false,
    margin: false,
  };

  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = { collapse: props.isOpen };
  }

  toggle() {
    this.setState((state) => ({ collapse: !state.collapse }));
  }

  render() {
    const { icon, children, label, className, margin } = this.props;
    return (
      <div
        style={
          margin
            ? {
                borderBottom: "1px solid #efefef",
                marginBottom: 20,
              }
            : {
                borderBottom: "1px solid #efefef",
              }
        }>
        <span
          className="Entreprises-filters__item"
          style={{ marginBottom: "1rem", display: "flow-root" }}
          onClick={this.toggle}>
          {icon && (
            <FilterIcon
              style={{ fill: className ? "white" : "gray", marginTop: -5 }}
            />
          )}
          <span> {label}</span>
          {this.state.collapse ? (
            <span className="MyCV-chevron">
              <MinusIcon style={{ fill: className ? "white" : "gray" }} />
            </span>
          ) : (
            <span className="MyCV-chevron">
              <PlusIcon style={{ fill: className ? "white" : "gray" }} />
            </span>
          )}
        </span>
        <Collapse isOpen={this.state.collapse}>{children}</Collapse>
      </div>
    );
  }
}

export default CollapseFilter;
