import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";
import EmailIcon from "mdi-react/EmailIcon";
import EyeIcon from "mdi-react/EyeIcon";
import KeyVariantIcon from "mdi-react/KeyVariantIcon";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import ReactPixel from "react-facebook-pixel";
import { Form, FormGroup, Input } from "reactstrap";
import { signiWthLinkedIn, signup } from "../../../handler/api/auth";
import { auth, invalidEmail } from "../../../handler/errorMessages.json";
import Alert from "../../../handler/utils/Alert";
import { EMAIL_REGEX } from "../../../handler/utils/constants";
import Button from "../../../shared/components/Button";
import Checkbox from "../../../shared/components/Checkbox";
import ModalPreview from "../components/ModalPreview";
import i18n from "../../../locals/i18n";
import { withTranslation } from "react-i18next";

//import GoogleIcon from "mdi-react/GoogleIcon";
//import FacebookIcon from "mdi-react/FacebookIcon";
class SignUpForm extends PureComponent {
  static propTypes = {
    setUser: PropTypes.func,
    history: PropTypes.object,
    onSuccess: PropTypes.func.isRequired,
  };

  state = {
    showPassword: false,
    showPassword2: false,
    loading: false,
    condition: false,
    modal: false,
    success: false,
    errors: {
      email: null,
      password: null,
      password2: null,
      last_name: null,
      first_name: null,
    },
  };

  email = null;
  first_name = null;
  last_name = null;
  password = null;
  password2 = null;
  setEmailRef = (ref) => {
    this.email = ref;
  };
  setLastNameRef = (ref) => {
    this.last_name = ref;
  };
  setFirstNameRef = (ref) => {
    this.first_name = ref;
  };
  setPasswordRef = (ref) => {
    this.password = ref;
  };
  setPassword2Ref = (ref) => {
    this.password2 = ref;
  };
  handleChecked = (checked, name) => {
    this.setState({
      [name]: checked,
    });
  };
  showPassword = (e) => {
    e.preventDefault();
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };
  showPassword2 = (e) => {
    e.preventDefault();
    this.setState({
      showPassword2: !this.state.showPassword2,
    });
  };
  showModal = () => {
    this.setState({
      modal: true,
    });
  };
  loginWithGoogle = () => {
    const { onSuccess } = this.props;
    window.OAuth.initialize("fzjokJZ5VjBmzvl2GKu1nCm_cgk");
    window.OAuth.popup("google").done(function(result) {
      result.me().done(function(data) {
        signiWthLinkedIn({ email: data.email })
          .then(({ success, token }) => {
            if (!success) {
              signup({
                email: data.email,
                password: "$CodersTalent$",
                last_name: data.raw.names[0].familyName || "candidat",
                first_name: data.raw.names[0].givenName || "candidat",
              })
                .then(({ success, token, message }) => {
                  if (!success) {
                    Alert.warning(message);
                    this.setState({
                      loading: false,
                      errors: { email: null, password: null },
                    });
                  } else {
                    onSuccess(token);
                  }
                })
                .catch(() => {});
            } else {
              onSuccess(token);
            }
          })
          .catch(() => {});
      });
    });
  };
  loginWithFacebook = () => {
    const { onSuccess } = this.props;
    window.OAuth.initialize("fzjokJZ5VjBmzvl2GKu1nCm_cgk");
    window.OAuth.popup("facebook").done(function(result) {
      result.me().done(function(data) {
        signiWthLinkedIn({ email: data.email })
          .then(({ success, token }) => {
            if (!success) {
              signup({
                email: data.email,
                password: "$CodersTalent$",
                last_name: data.lastname || "candidat",
                first_name: data.firstname || "candidat",
              })
                .then(({ success, token, message }) => {
                  if (!success) {
                    Alert.warning(message);
                    this.setState({
                      loading: false,
                      errors: { email: null, password: null },
                    });
                  } else {
                    onSuccess(token);
                  }
                })
                .catch(() => {});
            } else {
              onSuccess(token);
            }
          })
          .catch(() => {});
      });
      // do some stuff with result
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const email = this.email.value.trim();
    const last_name = this.last_name.value.trim();
    const first_name = this.first_name.value.trim();
    const password = this.password.value.trim();
    const password2 = this.password2.value.trim();

    let errors = {
      email: null,
      password: null,
      password2: null,
      last_name: null,
      first_name: null,
    };
    if (!EMAIL_REGEX.test(email)) errors.email = invalidEmail;
    if (password.length < 6) errors.password = auth.invalidPassword;
    if (password != password2)
      errors.password2 = "Les mots de passe doivent être identiques";
    if (last_name.length < 1) errors.last_name = "Le prénom est obligatoire";
    if (first_name.length < 1) errors.first_name = "Le nom est obligatoire";
    if (!this.state.condition)
      Alert.warning("Merci d'accepter les conditions générales");
    if (
      errors.email ||
      errors.password ||
      errors.password2 ||
      errors.first_name ||
      errors.last_name ||
      !this.state.condition
    )
      this.setState({
        errors,
      });
    else {
      this.setState({ loading: true });
      signup({ email, password, last_name, first_name })
        .then(({ success, token, id, message }) => {
          ReactPixel.track("Lead", { event: "inscription", email, success });
          if (!success) {
            Alert.warning(message);
            this.setState({ loading: false }, () => {
              this.password.value = "";
            });
          } else {
            localStorage.setItem("id", id);
            this.props.onSuccess(token);
            this.setState({ loading: false }, () => {});
          }
        })
        .catch(() => {
          this.setState({ loading: false }, () => {});
        });
    }
  };
  toggle = () => {
    this.setState({
      modal: false,
    });
  };
  render() {
    const { showPassword, showPassword2, loading, modal, errors } = this.state;
    return (
      <div style={{ maxWidth: "100%" }}>
        <ModalPreview modal={modal} toggleModal={this.toggle} />
        <div className="d-flex SignInfo align-items-center">
          <span className="Login-label__footer m-0 mr-1">
            {i18n.t("signup.alreadyHaveAnAccount")}
          </span>
          <Button
            onClick={() => this.props.history.push("/connexion")}
            className="btn btn-subscribe btn-primary "
            type="submit"
            text={i18n.t("signup.Connexion")}
            color="primary"
          />
        </div>
        <div className="SignBox p-0">
          <div className="Login-header border-0 mb-0">
            <span className="mr-2 Signup-Title">{i18n.t("signup.signup")}</span>
          </div>{" "}
          <Form
            className="form"
            onSubmit={this.handleSubmit}
            style={{ flexDirection: "row-reverse", padding: 0 }}
          >
            <div className="form__form-group">
              <span className="Signup-label ">{i18n.t("signup.fullName")}</span>
              <div style={{ flexDirection: "row", display: "flex" }}>
                <div style={{ width: "100%" }}>
                  <div className="form__form-group-field">
                    <AccountOutlineIcon className="Signup-icon" />

                    <Input
                      name="first_name"
                      connexion
                      className="Signup-input"
                      type="text"
                      innerRef={this.setFirstNameRef}
                      placeholder={i18n.t("signup.name")}
                    />
                  </div>
                  {errors.first_name && (
                    <span
                      style={{
                        fontSize: 12,
                        width: "100%",
                        color: "#f50101",
                        textTransform: "initial",
                        marginLeft: "10px",
                      }}
                    >
                      {errors.first_name}
                    </span>
                  )}
                </div>
                <div style={{ width: "100%" }}>
                  <div className="form__form-group-field">
                    <AccountOutlineIcon className="Signup-icon" />

                    <Input
                      name="last_name"
                      className="Signup-input"
                      type="text"
                      innerRef={this.setLastNameRef}
                      placeholder={i18n.t("signup.lastName")}
                    />
                  </div>
                  {errors.last_name && (
                    <span
                      style={{
                        fontSize: 12,
                        width: "100%",
                        color: "#f50101",
                        textTransform: "initial",
                        marginLeft: "10px",
                      }}
                    >
                      {errors.last_name}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="form__form-group">
              <span className="Signup-label">{i18n.t("signup.mail")}</span>
              <div className="form__form-group-field">
                <EmailIcon className="Signup-icon" />

                <Input
                  name="email"
                  type="email"
                  className="Signup-input"
                  innerRef={this.setEmailRef}
                />
              </div>
              {errors.email && (
                <span
                  style={{
                    fontSize: 12,
                    width: "100%",
                    color: "#f50101",
                    textTransform: "initial",
                    marginLeft: "10px",
                  }}
                >
                  {errors.email}
                </span>
              )}
            </div>
            <div className="form__form-group">
              <span className="Signup-label">{i18n.t("signup.password")}</span>
              <div className="form__form-group-field">
                <KeyVariantIcon className="Signup-icon" />

                <Input
                  name="password"
                  className="Signup-input"
                  innerRef={this.setPasswordRef}
                  type={showPassword ? "text" : "password"}
                  placeholder="********"
                />
                <button
                  className={`form__form-group-button${
                    this.state.showPassword ? " active" : ""
                  }`}
                  onClick={(e) => this.showPassword(e)}
                >
                  <EyeIcon />
                </button>
              </div>
              {errors.password && (
                <span
                  style={{
                    fontSize: 12,
                    width: "100%",
                    color: "#f50101",
                    textTransform: "initial",
                    marginLeft: "10px",
                  }}
                >
                  {errors.password}
                </span>
              )}
            </div>
            <div className="form__form-group">
              <span className="Signup-label">
                {i18n.t("signup.verifyPassword")}
              </span>
              <div className="form__form-group-field">
                <KeyVariantIcon className="Signup-icon" />

                <Input
                  name="password2"
                  className="Signup-input"
                  innerRef={this.setPassword2Ref}
                  type={showPassword2 ? "text" : "password"}
                  placeholder="********"
                />
                <button
                  className={`form__form-group-button${
                    this.state.showPassword2 ? " active" : ""
                  }`}
                  onClick={(e) => this.showPassword2(e)}
                >
                  <EyeIcon />
                </button>
              </div>
              {errors.password2 && (
                <span
                  style={{
                    fontSize: 12,
                    width: "100%",
                    color: "#f50101",
                    textTransform: "initial",
                    marginLeft: "10px",
                  }}
                >
                  {errors.password2}
                </span>
              )}
            </div>
          </Form>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <FormGroup
              check
              className="ModalCV-group__form"
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "baseline",
                width: "100%",
              }}
            >
              <Checkbox
                checked={this.state.condition}
                onCkeck={this.handleChecked}
                name={"condition"}
              />
              <span
                className="Signup-label"
                style={{ width: "100%", cursor: "pointer" }}
                onClick={this.showModal}
              >
                {i18n.t("signup.acceptTerms")}
              </span>
            </FormGroup>
            <div style={{ margin: "0px auto" }}>
              <Button
                className="btn btn-primary Signup-btn"
                type="submit"
                text="S'inscrire"
                onClick={this.handleSubmit}
                color="primary"
                loading={loading}
              />
            </div>

            {/*  <hr className={"Login-hr"} />
            <div
              className="d-flex"
              style={{
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span className=" m-0 LoginLabel">Ou continuer avec</span>
            </div>
            <div
              className="d-flex  w-100 justify-content-center  FlexTab"
              style={{
                alignItems: "center",
                justifyContent: "center",
                paddingBottom: 15,
              }}
            >
              <div className=" mt-4" style={{ marginRight: 15 }}>
                <Button2
                  className="btn btn-primary kep-login-google"
                  style={{ padding: 0, fontSize: "0.8rem" }}
                  onClick={this.loginWithGoogle}
                  loading={loading}
                >
                  <GoogleIcon
                    style={{ width: 20, height: 30, marginTop: -5 }}
                  />{" "}
                  <span className="btn_linked">Google</span>
                </Button2>
              </div>
              <div className=" mt-4">
                <Button2
                  className="btn btn-primary kep-login-facebook"
                  style={{ padding: 0, fontSize: "0.8rem" }}
                  onClick={this.loginWithFacebook}
                  loading={loading}
                >
                  <FacebookIcon
                    style={{ width: 20, height: 30, marginTop: -5 }}
                  />{" "}
                  <span className="btn_linked">Facebook</span>
                </Button2>
              </div>
            </div>
            */}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(SignUpForm);
