import PropTypes from "prop-types";
import React from "react";
import { Row } from "reactstrap";

function OfferDescription(props) {
  const { offer } = props;
  return (
    <div style={{ width: "100%" }}>
      <Row
        style={{
          marginTop: "2rem",
          marginBottom: "1rem",
        }}
      >
        <span className="OfferItemDetailHeader-text">Description</span>
      </Row>
      <Row
        style={{
          backgroundColor: "#fff",
          //  overflow: "hidden",
          minHeight: "200px",
        }}
      >
        <span style={{ fontSize: "14px", whiteSpace: "break-spaces" }}>
          {offer.description_poste}
        </span>
      </Row>
    </div>
  );
}
OfferDescription.propTypes = {
  offer: PropTypes.object,
};

OfferDescription.defaultProps = {
  offer: {},
};
export default OfferDescription;
