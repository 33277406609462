/* eslint-disable react/jsx-no-bind */
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withRouter } from "react-router";
import windowSize from "react-window-size";
import { Col, Row } from "reactstrap";
import {
  DataController,
  DataSearch,
  MultiList,
  ReactiveBase,
  ReactiveList,
  SelectedFilters,
  SingleDropdownRange,
} from "toolynk-reactivesearch";
import { etudeConst, experienceConst } from "../../../handler/utils/constants";
import AddressAutocomplete from "../../../shared/components/AddressAutocompleteHome";
import CollapseFilter from "../../../shared/components/CollapseFilter";
import FallbackSpinner from "../../../shared/components/FallbackSpinner";
import Icon from "../../../shared/components/Icon";
import CandidateItemDetail from "./CandidateItemDetail";
import Candidate_card from "./Candidate_card";
import i18n from "../../../locals/i18n";
import { withTranslation } from "react-i18next";
const cv = `${process.env.PUBLIC_URL}/img/images/cv.png`;

let location = null;
class ComputerScreen extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
    windowWidth: PropTypes.number.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      number: 1,
      candidate: null,
      screen: props.windowWidth < 1250,
      numberr: 0,
      test: false,
      location: null,
      show: true,
      change: false,
      city: props.location && props.location.state && props.location.state.city,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.onResultStats = this.onResultStats.bind(this);
    location =
      props.location && props.location.state && props.location.state.location;
  }

  onResultStats = (results) => {
    if (results !== 0) this.setState({ results });
    return <div />;
  };
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }
  setAddress = (object) => {
    if (object) {
      this.setState({
        address: object.address,
        city: object.city,
        location: {
          lat: object.location.lat,
          lon: object.location.lng,
        },
      });
      location = {
        lat: object.location.lat,
        lon: object.location.lng,
      };
    }
  };
  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextState.results !== this.state.results ||
      nextState.show !== this.state.show ||
      nextState.city !== this.state.city ||
      nextState.screen !== this.state.screen ||
      nextState.location !== this.state.location ||
      nextState.change !== this.state.change
    );
  }
  noResult = () => {
    return (
      <div className="text_of text-center pb-3">
   {i18n.t("condidatesComponent.noCandidateWasFound") }    
      </div>
    );
  };
  updateWindowDimensions() {
    this.setState({ screen: window.innerWidth < 1250 });
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateWindowDimensions);
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.selectedcv
    ) {
      this.setState({ candidate: this.props.location.state.selectedcv }, () => {
        this.setState({ test: true });
      });
    }
    if (this.state.screen) {
      this.setState({ show: false });
    } else this.setState({ show: true });
  }
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  getItem = (candidate) => {
    const { change } = this.state;
    this.setState({ candidate: candidate, change: !change });
  };

  showFilter = () => this.setState({ show: !this.state.show });

  render() {
    let loc = location ? location : this.state.location;
    const { show, city } = this.state;
    return (
      <div id="app">
        <ReactiveBase
          app="recrutemoi"
          url="https://elastic.toolynk-lab.com"
          type="candidats"
        >
          <Row className="Offers-row pb-4">
            <DataController
              componentId="DataSensor"
              size={10}
              pagination={true}
              dataField={[]}
              customQuery={() => ({
                query: {
                  bool: {
                    must: [
                      {
                        match: {
                          is_blocked: false,
                        },
                      },
                      {
                        match: {
                          is_blocked_by_admin: false,
                        },
                      },
                    ],
                  },
                },
              })}
            />
            <Col
              xs={12}
              xl={2}
              md={2}
              lg={2}
              className="Offers-col__left"
              style={{ display: show ? "block" : "none" }}
            >
              <Row
                style={{
                  padding: "0px 1rem",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <span className="Entreprises-Filterlabel">{i18n.t("condidatesComponent.filters")}</span>
                <div onClick={this.showFilter} style={{ cursor: "pointer" }}>
                  <Icon className="collapseIcon" name="collapse" />
                </div>
              </Row>
              <CollapseFilter
                isOpen={true}
                label={i18n.t("condidatesComponent.search")}
                style={{ paddingBottom: "3rem" }}
              >
                <DataSearch
                  componentId="SearchSensor"
                  dataField={[
                    "first_name",
                    "last_name",
                    "cv_data",
                    "contract",
                    "job",
                  ]}
                  placeholder="Mot clé"
                  queryFormat="and"
                  fuzziness={1}
                  fieldWeights={[1, 3, 5]}
                  filterLabel={i18n.t("condidatesComponent.search")}
                  showFilter={true}
                  URLParams={false}
                  defaultSelected={
                    this.props.location &&
                    this.props.location.state &&
                    this.props.location.state.mot_cle
                      ? this.props.location.state.mot_cle
                      : ""
                  }
                  onSuggestion={(suggestion) => ({
                    label: (
                      <div>
                        <span
                          style={{
                            fontWeight: "500",
                            textTransform: "uppercase",
                            color: "rgba(41, 94, 190, 1)",
                            fontFamily: "Montserrat",
                          }}
                        >
                          {suggestion._source.last_name}
                        </span>
                        <span
                          style={{
                            fontWeight: "500",
                            marginLeft: 2,
                            textTransform: "uppercase",
                            color: "rgba(41, 94, 190, 1)",
                            fontFamily: "Montserrat",
                          }}
                        >
                          {suggestion._source.first_name}
                        </span>
                        {suggestion._source.job && (
                          <span
                            style={{
                              fontWeight: "400",
                              marginLeft: 2,
                              textTransform: "capitalize",
                              fontFamily: "Montserrat",
                            }}
                          >
                            <Icon className="MyCV-icon" name="candidat-brief" />{" "}
                            {suggestion._source.job}
                          </span>
                        )}
                        {suggestion._source.experience && (
                          <span
                            style={{
                              fontWeight: "400",
                              marginLeft: 2,
                              textTransform: "capitalize",
                              fontFamily: "Montserrat",
                            }}
                          >
                            <Icon className="MyCV-icon" name="candidat-test" />
                            {
                              experienceConst.filter(
                                (experience) =>
                                  experience.value ===
                                  suggestion._source.experience
                              )[0].label
                            }
                          </span>
                        )}
                      </div>
                    ),
                    value: suggestion._source.first_name,
                  })}
                  renderNoSuggestion={() => <div>{i18n.t("condidatesComponent.noResultFound")}</div>}
                  react={{
                    and: [
                      "DataSensor",
                      "jobSensor",
                      "genreSensor",
                      "etudeSensor",
                      "contractSensor",
                      "activity_rateSensor",
                      "DisponibilitéSensor",
                      "ExpérienceSensor",
                      "RayonSensor",
                    ],
                  }}
                />
              </CollapseFilter>
              <CollapseFilter isOpen={false} label={i18n.t("condidatesComponent.job")}>
                <MultiList
                  componentId="jobSensor"
                  placeholder={i18n.t("condidatesComponent.search")}
                  queryFormat="or"
                  selectAllLabel="Tous"
                  className="Entreprises-lista"
                  filterLabel={i18n.t("condidatesComponent.job")}
                  dataField="job.keyword"
                  renderListItem={(label, count) => (
                    <div>
                      <span className="Offers-label__filter">{label}</span>
                      <span className="Offers-label__count">({count})</span>
                    </div>
                  )}
                  react={{
                    and: [
                      "SearchSensor",
                      "DataSensor",
                      "genreSensor",
                      "etudeSensor",
                      "contractSensor",
                      "activity_rateSensor",
                      "DisponibilitéSensor",
                      "ExpérienceSensor",
                      "RayonSensor",
                    ],
                  }}
                />
              </CollapseFilter>
              <CollapseFilter label={i18n.t("condidatesComponent.gender")}>
                <MultiList
                  componentId="genreSensor"
                  queryFormat="or"
                  selectAllLabel="Tous"
                  showSearch={false}
                  className="Entreprises-lista"
                  filterLabel={i18n.t("condidatesComponent.gender")}
                  dataField="genre.keyword"
                  renderListItem={(label, count) => (
                    <div>
                      <span className="Offers-label__filter">{label}</span>
                      <span className="Offers-label__count">({count})</span>
                    </div>
                  )}
                  react={{
                    and: [
                      "SearchSensor",
                      "DataSensor",
                      "jobSensor",
                      "etudeSensor",
                      "contractSensor",
                      "activity_rateSensor",
                      "DisponibilitéSensor",
                      "ExpérienceSensor",
                      "RayonSensor",
                    ],
                  }}
                />
              </CollapseFilter>
              <CollapseFilter label={i18n.t("condidatesComponent.levelOfStudies")}>
                <MultiList
                  componentId="etudeSensor"
                  queryFormat="or"
                  showSearch={false}
                  selectAllLabel="Tous"
                  className="Entreprises-lista"
                  filterLabel={i18n.t("condidatesComponent.levelOfStudies")}
                  dataField="etude.keyword"
                  renderListItem={(label, count) => (
                    <div>
                      <span className="Offers-label__filter">
                        {
                          etudeConst.filter((exp) => exp.value === label)[0]
                            .label
                        }
                      </span>
                      <span className="Offers-label__count">({count})</span>
                    </div>
                  )}
                  react={{
                    and: [
                      "SearchSensor",
                      "DataSensor",
                      "jobSensor",
                      "genreSensor",
                      "contractSensor",
                      "activity_rateSensor",
                      "DisponibilitéSensor",
                      "ExpérienceSensor",
                      "RayonSensor",
                    ],
                  }}
                />
              </CollapseFilter>
              <CollapseFilter label={i18n.t("condidatesComponent.typeOfContract")}>
                <MultiList
                  componentId="contractSensor"
                  showSearch={false}
                  filterLabel={i18n.t("condidatesComponent.typeOfContract")}
                  queryFormat="or"
                  selectAllLabel="Tous"
                  className="Entreprises-lista"
                  dataField="contract.keyword"
                  renderListItem={(label, count) => (
                    <div>
                      <span className="Offers-label__filter">{label}</span>
                      <span className="Offers-label__count">({count})</span>
                    </div>
                  )}
                  react={{
                    and: [
                      "SearchSensor",
                      "DataSensor",
                      "jobSensor",
                      "genreSensor",
                      "etudeSensor",
                      "activity_rateSensor",
                      "DisponibilitéSensor",
                      "ExpérienceSensor",
                      "RayonSensor",
                    ],
                  }}
                />
              </CollapseFilter>

              <CollapseFilter label={i18n.t("condidatesComponent.activityRate")}>
                <MultiList
                  componentId="activity_rateSensor"
                  showSearch={false}
                  queryFormat="or"
                  selectAllLabel="Tous"
                  className="Entreprises-lista"
                  filterLabel={i18n.t("condidatesComponent.activityRate")}
                  dataField="activity_rate.keyword"
                  renderListItem={(label, count) => (
                    <div>
                      <span className="Offers-label__filter">{label}</span>
                      <span className="Offers-label__count">({count})</span>
                    </div>
                  )}
                  react={{
                    and: [
                      "SearchSensor",
                      "DataSensor",
                      "jobSensor",
                      "genreSensor",
                      "etudeSensor",
                      "contractSensor",
                      "DisponibilitéSensor",
                      "ExpérienceSensor",
                      "RayonSensor",
                    ],
                  }}
                />
              </CollapseFilter>
              <CollapseFilter label={i18n.t("condidatesComponent.availablity")}>
                <MultiList
                  componentId="DisponibilitéSensor"
                  showSearch={false}
                  queryFormat="or"
                  selectAllLabel="Toutes"
                  className="Entreprises-lista"
                  filterLabel={i18n.t("condidatesComponent.availablity")}
                  dataField="disponibility_days.keyword"
                  renderListItem={(label, count) => (
                    <div>
                      <span className="Offers-label__filter">{label}</span>
                      <span className="Offers-label__count">({count})</span>
                    </div>
                  )}
                  react={{
                    and: [
                      "SearchSensor",
                      "DataSensor",
                      "jobSensor",
                      "genreSensor",
                      "etudeSensor",
                      "contractSensor",
                      "activity_rateSensor",
                      "ExpérienceSensor",
                      "RayonSensor",
                    ],
                  }}
                />
              </CollapseFilter>
              <CollapseFilter label={i18n.t("condidatesComponent.experience")}>
                <MultiList
                  componentId="ExpérienceSensor"
                  placeholder="Rechercher"
                  queryFormat="or"
                  selectAllLabel="Toutes"
                  className="Entreprises-lista"
                  filterLabel={i18n.t("condidatesComponent.experience")}
                  dataField="experience.keyword"
                  renderListItem={(label, count) => (
                    <div>
                      <span className="Offers-label__filter">
                        {
                          experienceConst.filter(
                            (exp) => exp.value === label
                          )[0].label
                        }
                      </span>
                      <span className="Offers-label__count">({count})</span>
                    </div>
                  )}
                  react={{
                    and: [
                      "SearchSensor",
                      "DataSensor",
                      "jobSensor",
                      "genreSensor",
                      "etudeSensor",
                      "contractSensor",
                      "activity_rateSensor",
                      "DisponibilitéSensor",
                      "RayonSensor",
                    ],
                  }}
                />
              </CollapseFilter>
              <CollapseFilter
                label={i18n.t("condidatesComponent.location")}
                margin={true}
                isOpen={
                  city &&
                  this.props.location &&
                  this.props.location.state &&
                  this.props.location.state.rayon
                }
              >
                <AddressAutocomplete
                  addressEntreprise={{ address: city }}
                  types={"(cities)"}
                  setAddress={this.setAddress}
                  deafultAddress={{}}
                  withStyle={true}
                />
                {city && (
                  <SingleDropdownRange
                    componentId="RayonSensor"
                    dataField="location"
                    className="xcxc"
                    defaultSelected={
                      city &&
                      this.props.location &&
                      this.props.location.state &&
                      this.props.location.state.rayon
                        ? this.props.location.state.rayon +
                        i18n.t("condidatesComponent.kmAround")+
                          city
                        : i18n.t("condidatesComponent.10kmAround") + city
                    }
                    data={[
                      {
                        start: 0,
                        end: 1,
                        label: city ? i18n.t("condidatesComponent.1kmAround") + city : "1Km",
                      },
                      {
                        start: 1,
                        end: 10,
                        label: city ? i18n.t("condidatesComponent.10kmAround") + city : "10Km",
                      },
                      {
                        start: 10,
                        end: 20,
                        label: city ? i18n.t("condidatesComponent.20kmAround") + city : "20Km",
                      },
                      {
                        start: 20,
                        end: 40,
                        label: city ? i18n.t("condidatesComponent.40kmAround") + city : "40Km",
                      },
                      {
                        start: 40,
                        end: 60,
                        label: city ?i18n.t("condidatesComponent.60kmAround") + city : "60Km",
                      },
                      {
                        start: 60,
                        end: 80,
                        label: city ? i18n.t("condidatesComponent.80kmAround") + city : "80Km",
                      },
                      {
                        start: 80,
                        end: 100,
                        label: city ? i18n.t("condidatesComponent.100kmAround")+ city : "100Km",
                      },
                    ]}
                    customQuery={function(value) {
                      if (value && loc)
                        return {
                          query: {
                            bool: {
                              must: {
                                match_all: {},
                              },
                              filter: {
                                geo_distance: {
                                  distance: value.end + "km",
                                  location: loc,
                                },
                              },
                            },
                          },
                        };
                      else
                        return {
                          query: {
                            bool: {
                              must: {
                                match_all: {},
                              },
                            },
                          },
                        };
                    }}
                    placeholder={ i18n.t("condidatesComponent.distanceRadius")}
                    filterLabel={ i18n.t("condidatesComponent.search")}
                    showFilter={true}
                    showSearch={false}
                    // className="Entreprises-lista"
                    URLParams={false}
                    react={{
                      and: [
                        "SearchSensor",
                        "DataSensor",
                        "jobSensor",
                        "genreSensor",
                        "etudeSensor",
                        "contractSensor",
                        "activity_rateSensor",
                        "DisponibilitéSensor",
                        "ExpérienceSensor",
                      ],
                    }}
                  />
                )}
              </CollapseFilter>
            </Col>
            {!show && (
              <div
                className="Offers-col__left"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: 5,
                }}
              >
                <span
                  className="Entreprises-Filterlabel"
                  style={{ marginBottom: 5 }}
                >
                 { i18n.t("condidatesComponent.filters")}
                </span>
                <div onClick={this.showFilter} style={{ cursor: "pointer" }}>
                  <Icon className="collapseIcon" name="expand" />
                </div>
              </div>
            )}
            <Col xs={12} xl={4} md={4} lg={4} className="nn_pad">
              <div className="NoMargin">
                <Row
                  style={{
                    marginLeft: "0",
                    marginRight: "0",
                  }}
                  className="NoMargin2"
                >
                  {" "}
                  <SelectedFilters
                    showClearAll={true}
                    clearAllLabel= { i18n.t("condidatesComponent.clearFilters")}
                  />
                </Row>
                <div
                  style={{
                    backgroundColor: "#fff",
                    paddingTop: "10px",
                  }}
                >
                  <span
                    style={{
                      position: "absolute",
                      left: "2rem",
                      fontSize: "14px",
                      marginTop: "7px",
                    }}
                  >
                   { i18n.t("condidatesComponent.sortBy")}
                  </span>
                  <ReactiveList
                    componentId="SearchResult"
                    dataField="name.keyword"
                    className="result-list-container Offers-results GoFlex Candidates-list mr-2"
                    onResultStats={this.onResultStats}
                    size={10}
                    pagination={true}
                    sortOptions={[
                      {
                        label: "récent",
                        dataField: "createdAt",
                        sortBy: "desc",
                      },
                      {
                        label: "ancien",
                        dataField: "createdAt",
                        sortBy: "asc",
                      },
                    ]}
                    innerClass={{
                      list: "Candidates-list GoFlex",
                    }}
                    loader={<FallbackSpinner />}
                    onNoResults={
                      <div
                        className="text_of text-center pb-3"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          paddingTop: 80,
                        }}
                      >
                        <Icon className="no_candidate" name="no_candidate" />
                        { i18n.t("condidatesComponent.noCandidateWasFound")}
                      </div>
                    }
                    onAllData={(data) => this.onAllData(data)}
                    react={{
                      and: [
                        "SearchSensor",
                        "DataSensor",
                        "jobSensor",
                        "genreSensor",
                        "etudeSensor",
                        "contractSensor",
                        "activity_rateSensor",
                        "DisponibilitéSensor",
                        "ExpérienceSensor",
                        "RayonSensor",
                      ],
                    }}
                  />
                </div>
              </div>
            </Col>
            {this.state.candidate && this.state.candidate.num ? (
              <Col
                xs={12}
                md={6}
                lg={6}
                xl={show ? 6 : 7}
                style={{ margin: 0, padding: 0 }}
              >
                <CandidateItemDetail candidate={this.state.candidate} />
              </Col>
            ) : (
              <Col
                xs={12}
                md={6}
                lg={6}
                xl={show ? 6 : 7}
                style={{
                  margin: 0,
                  padding: 0,
                  backgroundColor: "#fff",
                  height: "36rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={cv}
                  style={{ width: "30%" }}
                  onError={() => {
                    this.setState({ src: cv });
                  }}
                  alt={"cv"}
                />
              </Col>
            )}
          </Row>
        </ReactiveBase>
      </div>
    );
  }
  onAllData = (data) => {
    if (data !== null) {
      if (data.length == 0) {
        this.setState({ candidate: null });
      }
      if (!this.state.test) this.setState({ candidate: data[0], test: false });
      return data.map((candidate, index) => {
        return (
          <Candidate_card
            key={index}
            num={this.state.candidate && this.state.candidate.num}
            candidate={candidate}
            getItem={this.getItem}
          />
        );
      });
    }
  };
}
export default withTranslation()(withRouter(windowSize(ComputerScreen))) 
