import gql from "graphql-tag";
import PropTypes from "prop-types";
import React from "react";
import { compose, graphql } from "react-apollo";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import ReactGA from "react-ga";
import { checkToken } from "../../handler/api/auth";
import { SET_TOKEN } from "../../handler/mutations.local";
import Alert from "../../handler/utils/Alert";
import { refetch } from "../layout/index";
import CheckResetPasswordCode from "./components/CheckResetPasswordCode";
import LogInForm from "./components/LogInForm";
import ResetPassword from "./components/ResetPassword";
import SendResetPasswordCode from "./components/SendResetPasswordCode";
import i18n from "../../locals/i18n";
import { withTranslation } from "react-i18next";

const logo = `${process.env.PUBLIC_URL}/img/images/logo.png`;

const Screens = {
  LOGIN: "LOGIN",
  SEND_CODE: "SEND_CODE",
  CHECK_CODE: "CHECK_CODE",
  RESET_PASSWORD: "RESET_PASSWORD",
};

class LogIn extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    setToken: PropTypes.func.isRequired,
    acceptShareCandidat: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { screen: Screens.LOGIN, user: null, token: null };
  }

  setForgotPasword = () => this.setState({ screen: Screens.SEND_CODE });
  setLogin = () =>
    this.setState({ screen: Screens.LOGIN, user: null, token: null });
  setUser = user => this.setState({ user, screen: Screens.CHECK_CODE });
  setToken = token => this.setState({ token, screen: Screens.RESET_PASSWORD });

  onSuccess = token => {
    this.props
      .setToken({
        variables: { token },
      })
      .then(() => {
        this.props.history.replace("/");
        refetch();
      });
  };
  componentDidMount() {
    ReactGA.pageview("/connexion");
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.token
    )
      checkToken(this.props.match.params.token)
        .then(rest => {
          if (rest && rest.status && rest.status === 200) {
            this.props
              .setToken({
                variables: { token: this.props.match.params.token },
              })
              .then(() => {
                this.props.history.replace("/");
              });
          } else {
            Alert.warning(i18n.t("login.expiredAuthenticationToken"));
          }
        })
        .catch(() => {
          Alert.warning(i18n.t("login.expiredAuthenticationToken"));
          this.setState({ loading: false }, () => {});
        });
  }
  renderScreen = () => {
    switch (this.state.screen) {
      case Screens.LOGIN:
        return (
          <LogInForm
            onSuccess={this.onSuccess}
            setForgotPasword={this.setForgotPasword}
            history={this.props.history}
          />
        );
      case Screens.SEND_CODE:
        return (
          <SendResetPasswordCode
            setUser={this.setUser}
            setLogin={this.setLogin}
          />
        );
      case Screens.CHECK_CODE:
        return (
          <CheckResetPasswordCode
            setToken={this.setToken}
            user={this.state.user}
            setLogin={this.setLogin}
          />
        );
      case Screens.RESET_PASSWORD:
        return (
          <ResetPassword
            user={this.state.user}
            token={this.state.token}
            setLogin={this.setLogin}
          />
        );
    }
  };

  render() {
    return (
      <div className="Login">
        <Row style={{ margin: 0, padding: 0 }}>
          <Col xs={12} md={4} lg={4} xl={4} className="Login-left">
            <div className="Login-div_logo">
              <Link to="/accueil">
                <img className="Login-logo" src={logo} alt={"logo"} />
              </Link>
            </div>
            <span className="Login-text">
             {i18n.t("login.PostYourCVAndWeTakeCareOfEverything")}
            </span>
          </Col>

          <Col xs={12} md={8} lg={8} xl={8}>
            <div className="d-flex justify-content-center align-items-center h-100">
              {this.renderScreen()}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const UPDATE_APPLICATION_STATE = gql`
  mutation acceptShareCandidat {
    acceptShareCandidat {
      id
    }
  }
`;
export default withTranslation()(withRouter(
  compose(
    graphql(SET_TOKEN, {
      name: "setToken",
    }),
    graphql(UPDATE_APPLICATION_STATE, {
      name: "acceptShareCandidat",
    }),
  )(LogIn))
);
