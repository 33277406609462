import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { ApolloLink, from, split } from "apollo-link";
import { onError } from "apollo-link-error";
import { withClientState } from "apollo-link-state";
import { WebSocketLink } from "apollo-link-ws";
import { createUploadLink } from "apollo-upload-client";
import { getMainDefinition } from "apollo-utilities";
import "bootstrap/dist/css/bootstrap.css";
import React, { useEffect } from "react";
import { ApolloProvider } from "react-apollo";
import CookieConsent, { Cookies } from "react-cookie-consent";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import { hot } from "react-hot-loader";
import { HashRouter } from "react-router-dom";
import { SubscriptionClient } from "subscriptions-transport-ws";
import resolvers from "../../handler/resolvers.local.js";
import { BASE_URL, WS_URL } from "../../handler/utils/constants";
import "../../scss/app.scss";
import Router from "./Router";
import ScrollToTop from "./ScrollToTop";
import i18n from "../../locals/i18n";

let isloged = !!localStorage.getItem("token-candidat");
const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: localStorage.getItem("token-candidat") || null,
    },
  }));

  return forward(operation);
});
const uploadLink = createUploadLink({
  uri: `${BASE_URL}/graphql`,
});

const cache = new InMemoryCache({
  dataIdFromObject: (object) => {
    switch (object.__typename) {
      case "countNotificationsCandidates":
        return "countNotificationsCandidates";
      default:
        return object.id || null;
    }
  },
});
const stateLink = withClientState({
  defaults: {
    isLoggedIn: {
      isLoggedIn: isloged,
      __typename: "IsLoggedIn",
    },
    KeyValue: {
      KeyValue: null,
      __typename: "KeyValue",
    },
  },
  cache,
});

const errorLink = onError(
  ({ graphQLErrors, networkError, response, operation }) => {
    if (graphQLErrors && graphQLErrors.length) {
      graphQLErrors.map(({ message, locations, path }) => {
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
            locations
          )}, Path: ${path}`
        );
      });
    }
    if (networkError && networkError.bodyText == "Unauthorized") {
      localStorage.removeItem("token-candidat");
      isloged = false;
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
    if (operation.operationName === "IgnoreErrorsQuery") {
      response.errors = null;
    }
  }
);
const wsClient = new SubscriptionClient(WS_URL, {
  reconnect: true,
  connectionParams: {
    authToken: localStorage.getItem("token-candidat"),
  },
});

wsClient.onError((e) => {
  console.log({ ...e });
});
wsClient.onConnected(() => console.log("connected to ws"));
wsClient.onDisconnected(() => console.log("disconnected from ws"));
const wsLink = new WebSocketLink(wsClient);

const client = new ApolloClient({
  link: split(
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query);
      return kind === "OperationDefinition" && operation === "subscription";
    },
    wsLink,
    from([errorLink, authMiddleware, stateLink, uploadLink])
  ),
  resolvers,
  cache,
});
export const clientLogout = () => client.cache.reset();
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: true, // enable logs
};
function App() {
  Cookies.set("dash-candidat", "done");
  useEffect(() => {
    ReactPixel.init("663453774564527", {}, options);
    ReactPixel.pageView();
    ReactGA.initialize("UA-125430757-1");
  }, []);
  return (
    <ApolloProvider client={client}>
      <HashRouter>
        <ScrollToTop>
          <Helmet>
            <title>{i18n.t("appJs.freeCVLibrary")}</title>
            <meta
              name="description"
              content={i18n.t("appJs.WorkInternshipAlternationTraining")}
            />
            <meta name="robots" content="noodp,noydir" />
            <meta name="theme-color" content="#000000" />
          </Helmet>
          <Router />
          <CookieConsent
            onAccept={() => {}}
            debug={false}
            enableDeclineButton
            declineButtonText={i18n.t("appJs.decline")}
            buttonText={i18n.t("appJs.accept")}
            buttonClasses="accept"
            declineButtonClasses="decline"
            onDecline={() => {}}
          >
            {i18n.t("appJs.cookies")}
          </CookieConsent>
        </ScrollToTop>
      </HashRouter>
    </ApolloProvider>
  );
}
export default hot(module)(App);
