import PropTypes from "prop-types";
import React, { useState } from "react";
import { compose, graphql } from "react-apollo";
import { withRouter } from "react-router-dom";
import { Button, Col, Collapse, Row } from "reactstrap";
import { GET_CANDIDAT } from "../../../handler/queries";
import { IS_LOGGED_IN } from "../../../handler/queries.local";
import {
  BASE_URL,
  etudeConst,
  experienceConst,
} from "../../../handler/utils/constants";
import Icon from "../../../shared/components/Icon";
import CVPreview from "./CVPreview";
function CandidateItemDetailHeader(props) {
  const [collapse, setCollapse] = useState(false);
  const [modalCV, setmodalCV] = useState(false);

  const toggle = () => setCollapse(!collapse);
  const toggleCV = () => setmodalCV(!modalCV);

  const {
    avatar,
    experience,
    first_name,
    last_name,
    contract,
    activity_rate,
    languages,
    etude,
    disponibility_days,
    disponibility_time,
    job,
    cv,
    city,
    country
  } = props.candidate;
  const lang = languages.map(ln => {
    return " " + ln;
  });
  if(collapse){
    console.log("props.candidate**",props.candidate)
  }
  return (
    <Row
      className="OfferItemDetailHeader-white pb-3 pt-3"
      style={{
        borderBottomColor: "#ccc",
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
        marginBottom: 5,
      }}
    >
      <CVPreview file={BASE_URL + cv} modal={modalCV} toggle={toggleCV} />
      <Col
        xs={12}
        md={2}
        lg={2}
        xl={2}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {avatar && avatar !== "undefined" ? (
          <img
            src={BASE_URL + avatar}
            key={BASE_URL + avatar}
            className="OfferItemDetailHeader-logo"
          />
        ) : (
          <div className={"OfferItemDetailHeader-div"}>
            <div className="OfferItemDetailHeader-letters">
              {`${`${last_name.charAt(0)}${first_name.charAt(
                0,
              )}`}`.toUpperCase()}
            </div>
          </div>
        )}
      </Col>
      <Col
        xs={12}
        md={7}
        lg={7}
        xl={7}
        className="OfferItemDetailHeader-col__middle"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div className="OfferItemDetailHeader-text">
          {first_name + " " + last_name}
        </div>
        <div style={{ textAlign: "center" }}>
          <span className="OfferItemDetailHeader-sub-text">
            {job && job + ", "}
            <span style={{ fontSize: "13px", color: "gray" }}>
              {experience &&
                experienceConst.filter(exp => exp.value === experience)[0]
                  .label + "  d'éxpérience"}
            </span>
          </span>
        </div>
      </Col>
      <Col
        xs={12}
        md={3}
        lg={3}
        xl={3}
        className="OfferItemDetailHeader-col__middle d-flex justify-content-around"
      >
        <div className=" mt-2">
          <Button className="btnVoir mt-2" onClick={toggleCV}>
            Voir Cv
          </Button>
        </div>
        <div className=" mt-2">
          <Button className="btnVoir mt-2" onClick={toggle}>
            {!collapse ? "Voir plus" : "Voir moins"}
          </Button>
        </div>
      </Col>
      <Collapse isOpen={collapse}>
        <Col xs={12} md={12} lg={12} xl={12} className="PadRes">
          <div style={{ marginTop: "0.5rem", paddingLeft:"1rem" }}>
            <Row>
              <Col xs={12} md={12} lg={12} xl={12}>
                <Icon className="OfferItemDetailHeader-icon" name="cv-map" />
                <span className="OfferItemDetailHeader-sub-info">Adresse:</span>
                <span className="OfferItemDetailHeader-sub-infoT">
                {city ? " " + city : " ---"}  {country ? " , " + country : " ---"}
                </span>
              </Col>
              <Col xs={12} md={12} lg={12} xl={12}>
                <div className="d-flex  align-items-center NoFlex">
                  <Icon
                    className="OfferItemDetailHeader-icon"
                    name="disponibility-icon"
                  />

                  <span className="OfferItemDetailHeader-sub-info mr-1">
                    Disponibilité:
                  </span>
                  <span className="OfferItemDetailHeader-sub-infoT d-flex align-items-center ">
                    {disponibility_days &&
                      disponibility_days.map((val, index) => (
                        <React.Fragment key={index}>
                          {val == "Du Lun. au Ven" ? (
                            <span
                              className="availability-Item"
                              style={{ width: "8rem", borderRadius: "10px" }}
                            >
                              Du Lun. au Ven
                            </span>
                          ) : null}
                          {val == "Du Lun. au Ven" ? null : (
                            <span className="availability-Item">
                              {val == "Du Lun. au Ven"
                                ? null
                                : val.charAt(0) == "M"
                                ? val.slice(0, 2)
                                : val.charAt(0)}
                            </span>
                          )}
                        </React.Fragment>
                      ))}
                    <span>
                      {disponibility_time &&
                        disponibility_time.map((val, index) => (
                          <React.Fragment key={index}>
                            {val}
                            {index == disponibility_time.length - 1
                              ? " "
                              : " ,"}
                          </React.Fragment>
                        ))}
                    </span>
                  </span>
                </div>
              </Col>

              <Col xs={12} md={5} lg={5} xl={5}>
                <Icon
                  className="OfferItemDetailHeader-icon"
                  name="cantract-icon"
                />
                <span className="OfferItemDetailHeader-sub-info">
                  Contrat:{" "}
                </span>
                <span className="OfferItemDetailHeader-sub-infoT">
                  {contract &&
                    contract.map((val, index) => (
                      <React.Fragment key={index}>
                        {val}
                        {index == contract.length - 1 ? " " : " ,"}
                      </React.Fragment>
                    ))}
                </span>
              </Col>
              <Col xs={12} md={7} lg={7} xl={7}>
                <Icon
                  className="OfferItemDetailHeader-icon"
                  name="activity-icon"
                />
                <span className="OfferItemDetailHeader-sub-info">
                  Taux d'activité:
                </span>
                <span className="OfferItemDetailHeader-sub-infoT">
                  {activity_rate ? " " + activity_rate : " ---"}
                </span>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={5} lg={5} xl={5}>
                <Icon
                  className="OfferItemDetailHeader-icon"
                  name="candidat-etude"
                />
                <span className="OfferItemDetailHeader-sub-info">
                  Niveau d'études:
                </span>
                <span className="OfferItemDetailHeader-sub-infoT ml-1">
                  {etude
                    ? " " +
                      etudeConst.filter(exp => exp.value === etude)[0].label
                    : "  ---"}
                </span>
              </Col>
              <Col xs={12} md={7} lg={7} xl={7}>
                <Icon
                  className="OfferItemDetailHeader-icon"
                  name="langue-icon"
                />
                <span className="OfferItemDetailHeader-sub-info">Langues:</span>
                <span className="OfferItemDetailHeader-sub-infoT">
                  {lang ? " " + lang : "  ---"}
                </span>
              </Col>
            </Row>
          </div>
        </Col>
      </Collapse>
    </Row>
  );
}

CandidateItemDetailHeader.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  getCandidatProfile: PropTypes.object,
  match: PropTypes.object,
  candidate: PropTypes.object,
  isLoggedIn: PropTypes.bool,
  mutate: PropTypes.func,
};

CandidateItemDetailHeader.defaultProps = {
  banner: null,
  id: null,
  getCandidatProfile: {},
  candidat: [],
};

export default withRouter(
  compose(
    graphql(IS_LOGGED_IN, {
      props: ({ data: { isLoggedIn } }) => ({
        isLoggedIn: isLoggedIn ? isLoggedIn.isLoggedIn : false,
      }),
    }),
    graphql(GET_CANDIDAT, {
      options: () => ({
        fetchPolicy: "network-only",
      }),
      props: ({ data: { loading, error, getCandidatProfile } }) => {
        return {
          loading,
          error,
          getCandidatProfile,
        };
      },
    }),
  )(CandidateItemDetailHeader),
);
