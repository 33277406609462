import PropTypes from "prop-types";
import React from "react";
import { Col, Row } from "reactstrap";
import { BASE_URL } from "../../../handler/utils/constants";
import Icon from "../../../shared/components/Icon";
import Image from "../../../shared/components/Image";
import i18n from "../../../locals/i18n";
import { withTranslation } from "react-i18next";

const image = `${process.env.PUBLIC_URL}/img/images/not-description.png`;

function RecruteurItemDetailHeader(props) {
  const {
    profile_pic_url,
    description,
    name,
    application_email,
    address,
    city,
  } = props.recruteur;
  return (
    <Row className="OfferItemDetailHeader-white pb-3 pt-3">
      <Col xs={12} md={12} lg={12} xl={12}>
        <div className="w-100 border-bottom pb-2">
          <Row>
            <Col
              xs={12}
              md={2}
              lg={2}
              xl={2}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Image
                name={BASE_URL + profile_pic_url}
                className="OfferItemDetailHeader-logo"
              />
            </Col>
            <Col
              xs={12}
              md={6}
              lg={6}
              xl={6}
              className="OfferItemDetailHeader-col__middle"
            >
              <div className="OfferItemDetailHeader-text">{name}</div>
              <div>
                <span className="OfferItemDetailHeader-sub-text">
                  {city && city + ", "}
                </span>
              </div>
            </Col>
            {/*       <Col
              xs={12}
              md={3}
              lg={3}
              xl={3}
              className="OfferItemDetailHeader-col__middle d-flex justify-content-end"
            >
              <Button className="btnVoir mt-2">
                <Link
                  className=" mt-2"
                  to={{
                    pathname: "/offres",
                    state: { recruteur: name },
                  }}
                >
                  {"Voir les offres"}
                </Link>
              </Button>
            </Col> */}
          </Row>
        </div>
      </Col>
      <Col xs={12} md={12} lg={12} xl={12} className="pt-2">
        <span className="OfferItemDetailHeader-text ">{i18n.t("recruiter.Details")}</span>
        <div>
          <Row>
            <Col xs={12} md={12} lg={12} xl={12}>
              <Icon className="OfferItemDetailHeader-icon" name="cv-map" />
              <span className="OfferItemDetailHeader-sub-info">{i18n.t("recruiter.address")}</span>
              <span className="OfferItemDetailHeader-sub-infoT">
                {address ? " " + address : " ---"}
              </span>
            </Col>

            <Col xs={12} md={12} lg={12} xl={12}>
              <Icon
                className="OfferItemDetailHeader-icon"
                name="cantract-icon"
              />
              <span className="OfferItemDetailHeader-sub-info">
              {i18n.t("recruiter.applicationEmail")}
              </span>
              <span className="OfferItemDetailHeader-sub-infoT">
                {application_email ? " " + application_email : " ---"}
              </span>
            </Col>
            <Col xs={12} md={12} lg={12} xl={12}></Col>
          </Row>

          <Row className="mt-3" style={{ minHeight: "20rem" }}>
            <Col xs={12} md={12} lg={12} xl={12}>
              {" "}
              <span className="OfferItemDetailHeader-text border-top d-block w-100 pt-2 ">
              {i18n.t("recruiter.description")}
              </span>
              {description && description.length > 0 ? (
                <Row className="mt-1">
                  <Col
                    xs={12}
                    md={12}
                    lg={12}
                    xl={12}
                    style={{ overflow: "hidden" }}
                  >
                    <span className="OfferItemDetailHeader-sub-infoT">
                      {description}
                    </span>
                  </Col>
                </Row>
              ) : (
                <Row className="mt-2">
                  <Col xs={12} md={12} lg={12} xl={12}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        textAlign: "center",
                        alignItems: "center",
                        marginTop: "2rem",
                        backgroundColor: "#f8f9fa",
                        padding: 30,
                      }}
                    >
                      <img src={image} style={{ minWidth: 75, width: "10%" }} />
                      <span className="OfferItemDetailHeader-sub-info">
                      {i18n.t("recruiter.noDescriptionForTheMoment")}
                      </span>
                    </div>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
}

RecruteurItemDetailHeader.propTypes = {
  recruteur: PropTypes.object,
};

RecruteurItemDetailHeader.defaultProps = {
  recruteur: {},
};

export default withTranslation()(RecruteurItemDetailHeader);
