import gql from "graphql-tag";
import NotificationItem from "../shared/components/NotificationItem";

export const GET_NOTIFICATIONS = gql`
  query getNotificationsCandidates($limit: Int!, $skip: Int!) {
    getNotificationsCandidates(limit: $limit, skip: $skip) {
      id
      ...NotificationItemDetails
    }
  }
  ${NotificationItem.fragments.notificationItemDetails}
`;
export const COUNT_NOTIFICATION = gql`
  query countNotificationsCandidates($viewed: Boolean) {
    countNotificationsCandidates(viewed: $viewed)
  }
`;
export const GET_JOBS = gql`
  query getJobs($skip: Int, $limit: Int) {
    getJobs(skip: $skip, limit: $limit) {
      totalCount
      jobs {
        id
        name
      }
    }
  }
`;
export const GET_OFFERS_TO_DISPLAY = gql`
  query getOffersToDisplay($state: String) {
    getOffersToDisplay(state: $state) {
      offers {
        id
        num
        name
        salary
        address
        contract
        expiredAt
        createdAt
        city
        etude
        experience
        state
        job {
          name
        }
        entreprise {
          id
          name
          profile_pic_url
          application_email
        }
        description_poste
        languages
        disponibility_days
        disponibility_time
        activity_rate
      }
      totalCount
    }
  }
`;

export const GET_OFFERS_BY_STATUS = gql`
  query getOffersByState(
    $state: String
    $search: String
    $skip: Int
    $limit: Int
  ) {
    getOffersByState(
      state: $state
      search: $search
      skip: $skip
      limit: $limit
    ) {
      offers {
        id
        num
        name
        city
        salary_type
        competences {
          id
          name
        }
        application_number
        state
        contract
        expiredAt
        createdAt
      }
      totalCount
    }
  }
`;
export const GET_OFFERS_STATS = gql`
  query getOffersStatByWeekEnterprise {
    getOffersStatByWeekEnterprise
  }
`;

export const GET_OFFERS_ACTIVE_STATS = gql`
  query getOffersActiveStatEnterprise {
    getOffersActiveStatEnterprise
  }
`;

export const GET_APPLICATIONS_STATS = gql`
  query getApplicationsStatByWeekEnterprise {
    getApplicationsStatByWeekEnterprise
  }
`;
export const GET_APPLICATIONS = gql`
  query getApplications($skip: Int, $limit: Int) {
    getApplications(skip: $skip, limit: $limit) {
      totalCount
      applications {
        id
        offer {
          id
          name
          num
          expiredAt
        }
        entreprise {
          id
          name
        }
        state
        createdAt
      }
    }
  }
`;
export const GET_FAVORITES = gql`
  query getFavorites($skip: Int, $limit: Int) {
    getFavorites(skip: $skip, limit: $limit) {
      totalCount
      favorites {
        id
        offer {
          id
          name
          num
          expiredAt
          address
          publishedAt
        }
        entreprise {
          id
          name
        }
      }
    }
  }
`;
export const GET_OFFER_BY_NUM = gql`
  query getOfferByNum($num: Int!) {
    getOfferByNum(num: $num) {
      id
      num
      name
      job {
        id
        name
      }
      city
      competences {
        id
        name
      }
      entreprise {
        id
        name
        profile_pic_url
        name_of_in_charge
        address
      }
      contract
      experience
      publishedAt
      etude
      banner
      description_poste
      address
      extra_file
      salary
      salary_type
      work_time
      state
      offreType
      startEducation
      endEducation
      startApply
      endApply
      startInternship
      endInternship
      typeFormation
      createdAt
      expiredAt
    }
  }
`;
export const GET_CANDIDAT = gql`
  query getCandidatProfile {
    getCandidatProfile {
      id
      num
      avatar
      first_name
      last_name
      genre
      job_id
      city
      country
      address
      cv_data
      cv
      tel
      languages
      contract
      experience
      etude
      activity_rate
      disponibility_days
      disponibility_time
      location {
        lat
        lon
      }
      cv_request {
        cv
        cv_data
        requesthandled
      }
      job {
        id
        name
      }
      profile {
        id
        email
        is_blocked
        is_blocked_by_admin
      }
      createdAt
    }
  }
`;
export const UPDATE_USER_STATUS = gql`
  mutation updateUserStatus($status: Boolean, $id: ID) {
    updateUserStatus(status: $status, id: $id)
  }
`;

export const GET_SETTINGS = gql`
  query getSettings {
    getSettings {
      rgpd
      legal
    }
  }
`;

export const GET_ACTUALITIES = gql`
  query getActualite(
    $entreprise_id: String
    $state: String
    $search: String
    $skip: Int
    $limit: Int
    $field: String
    $allActus: String
    $direction: Int
  ) {
    getActualite(
      entreprise_id: $entreprise_id
      state: $state
      search: $search
      skip: $skip
      allActus: $allActus
      limit: $limit
      field: $field
      direction: $direction
    ) {
      ActualiteResult {
        _id
        num
        title
        description
        lien
        startPublication
        endPublication
        ent_type
        eventDate
        entreprise {
          num
          id
          name
          profile_pic_url
          name_of_in_charge
          address
        }
      }
      totalCount
    }
  }
`;
