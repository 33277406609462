import axios from "axios";
import { BASE_URL } from "../utils/constants";
import { getRequest } from "../utils/functions";
const axis = axios.create({
  baseURL: BASE_URL,
});
export const signin = (params) => {
  const request = getRequest(params);
  return fetch(`${BASE_URL}/auth/login/candidat`, request).then((res) =>
    res.json()
  );
};

export const signiWthLinkedIn = (params) => {
  const request = getRequest(params);
  return fetch(`${BASE_URL}/auth/linkedinlogin/candidat`, request).then((res) =>
    res.json()
  );
};

export const getSettings = () => {
  return fetch(`${BASE_URL}/auth/settings`).then((res) => res.json());
};

export const getStats = () => {
  return fetch(`${BASE_URL}/auth/stats`).then((res) => res.json());
};
export const signup = (params) => {
  const request = getRequest(params);
  return fetch(`${BASE_URL}/auth/register/candidat`, request).then((res) =>
    res.json()
  );
};

export const checkToken = (token) => {
  axis.defaults.headers.authorization = token;
  return axis.post("/auth/token/check").catch(async (error) => {
    if (error.request.status == 401) {
      return false;
    } else {
      return true;
    }
  });
};

export const checkEmail = (params) => {
  const request = getRequest(params);
  return fetch(`${BASE_URL}/auth/check/email`, request).then((res) =>
    res.json()
  );
};
