import PropTypes from "prop-types";
import React from "react";
import { Col, Collapse, Row } from "reactstrap";
import { BASE_URL } from "../../../handler/utils/constants";
import Icon from "../../../shared/components/Icon";
import Image from "../../../shared/components/Image";
import i18n from "../../../locals/i18n";
import { withTranslation } from "react-i18next";

const image = `${process.env.PUBLIC_URL}/img/images/not-description.png`;

function RecruteurCard(props) {
  const { num, recruteur } = props;
  const collapse = false;
  const {
    _id,
    profile_pic_url,
    name,
    application_email,
    city,
    description,
    address,
  } = props.recruteur;
  // const toggle = () => setCollapse(!collapse);

  return (
    <div
      key={_id}
      className={`CandidateListItem " + ${num === recruteur.num &&
        " CandidateListItem-active"}`}
      style={{
        borderBottomColor: "#ccc",
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
        marginBottom: 5,
      }}
    >
      <Row>
        <Col
          md={4}
          lg={4}
          xl={3}
          sx={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Image
            name={BASE_URL + profile_pic_url}
            className="CandidateListItem-avatar"
          />
        </Col>
        <Col
          md={6}
          lg={6}
          xl={7}
          sx={12}
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <span className="CandidateListItem-name__label">{name + " "}</span>
          <span className="CandidateListItem-name__job">{city}</span>
          <div className="CandidateListItem-div">
            <span className="CandidateListItem-div__label WLab">
              {application_email && application_email}
            </span>
          </div>
        </Col>
        {/*      <Col
          xs={12}
          md={3}
          lg={3}
          xl={3}
          className="OfferItemDetailHeader-col__middle d-flex justify-content-around"
        >
          <div className=" mt-2">
            <Button className="btnVoir mt-2">
              <Link
                className=" mt-2"
                to={{
                  pathname: "/offres",
                  state: { recruteur: name },
                }}
              >
                {"Voir les offres"}
              </Link>
            </Button>
          </div>
          <div className=" mt-2">
            <Button className="btnVoir mt-2" onClick={toggle}>
              {!collapse ? "Voir plus" : "Voir moins"}
            </Button>
          </div>
        </Col> */}
        <Collapse isOpen={collapse}>
          <div style={{ marginTop: "1rem" }}>
            <Row>
              <Col xs={12} md={12} lg={12} xl={12}>
                <Icon className="OfferItemDetailHeader-icon" name="cv-map" />
                <span className="OfferItemDetailHeader-sub-info">{i18n.t("recruiter.address")}</span>
                <span className="OfferItemDetailHeader-sub-infoT">
                  {address ? " " + address : " ---"}
                </span>
              </Col>

              <Col xs={12} md={12} lg={12} xl={12}>
                <Icon
                  className="OfferItemDetailHeader-icon"
                  name="cantract-icon"
                />
                <span className="OfferItemDetailHeader-sub-info">
                  {i18n.t("recruiter.applicationEmail")}
                </span>
                <span className="OfferItemDetailHeader-sub-infoT">
                  {application_email ? " " + application_email : " ---"}
                </span>
              </Col>
              <Col xs={12} md={12} lg={12} xl={12}></Col>
            </Row>

            <Row className="mt-3" style={{ minHeight: "10rem" }}>
              <Col xs={12} md={12} lg={12} xl={12}>
                {" "}
                <span className="OfferItemDetailHeader-text d-block w-100 pt-2 ">
                {i18n.t("recruiter.description")}
                </span>
                {description && description.length > 0 ? (
                  <Row className="mt-1">
                    <Col
                      xs={12}
                      md={12}
                      lg={12}
                      xl={12}
                      style={{ overflow: "hidden" }}
                    >
                      <span className="OfferItemDetailHeader-sub-infoT">
                        {description}
                      </span>
                    </Col>
                  </Row>
                ) : (
                  <Row className="mt-2">
                    <Col xs={12} md={12} lg={12} xl={12}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          textAlign: "center",
                          alignItems: "center",
                          marginTop: "2rem",
                          backgroundColor: "#f8f9fa",
                          padding: 30,
                        }}
                      >
                        <img
                          src={image}
                          style={{ minWidth: 75, width: "10%" }}
                        />
                        <span className="OfferItemDetailHeader-sub-info">
                        {i18n.t("recruiter.noDescriptionForTheMoment")}
                        </span>
                      </div>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </div>
        </Collapse>
      </Row>
    </div>
  );
}
RecruteurCard.propTypes = {
  recruteur: PropTypes.object,
  num: PropTypes.number,
  getItem: PropTypes.func,
};

RecruteurCard.defaultProps = {
  recruteur: {},
  num: null,
};

export default withTranslation()(RecruteurCard);
