import React from "react";
import RGPDComponent from "./components/RGPDComponent";
import { Container } from "reactstrap";
import { getSettings } from "../../handler/api/auth";

class RGPD extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rgpd: "<p><p/>"
    };
  }
  componentDidMount() {
    getSettings().then(res => {
      this.setState({ rgpd: res.rgpd });
    });
  }
  render() {
    const { rgpd } = this.state;
    return (
      <div id="app" style={{ marginTop: "8rem" }}>
        <Container className="dashboard">
          <RGPDComponent rgpd={rgpd} />
        </Container>
      </div>
    );
  }
}

export default RGPD;
