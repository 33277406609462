import React from "react";
import { Row, Col , Input} from "reactstrap";
import EmailIcon from "mdi-react/EmailIcon";
import { auth, invalidEmail } from "../../../handler/errorMessages.json";
import Alert from "../../../handler/utils/Alert";
import { EMAIL_REGEX } from "../../../handler/utils/constants";
import PropTypes from "prop-types";
import { signin } from "../../../handler/api/auth";
import Button from "../../../shared/components/Button";

class AddNewCV extends React.PureComponent {
  static propTypes = {
    onSuccess: PropTypes.func.isRequired,
    setForgotPasword: PropTypes.func.isRequired
  };
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      loading: false,
      message: "",
      errors: {
        email: null,
        password: null
      }
    };
  }

  email = null;
  password = null;

  setEmailRef = ref => {
    this.email = ref;
  };

  handleSubmit = e => {
    e.preventDefault();
    const email = this.email.value.trim();
    const password = this.password.value.trim();
    let errors = { email: null, password: null };
    if (!email) errors.email = "Ce champs est obligatoire";
    else if (!EMAIL_REGEX.test(email)) errors.email = invalidEmail;
    if (!password) errors.password = "Ce champs est obligatoire";
    else if (password.length < 6) errors.password = auth.invalidPassword;
    if (errors.email || errors.password)
      this.setState({
        errors
      });
    else {
      this.setState({ loading: true });
      signin({ email, password })
        .then(({ success, token, message, demande }) => {
          if (!success) {
            this.setState({ loading: false }, () => {
              Alert.warning(message);
              this.password.value = "";
              this.setState({
                loading: false,
                errors: { email: null, password: null }
              });
            });
          } else {
            this.props.onSuccess(token, demande);
          }
        })
        .catch(() => {
          this.setState({ loading: false }, () => {});
        });
    }
  };

  render() {
    const { errors } = this.state;

    return (
      <div className="AddNewsletter">
      <Row>
        <Col xs={12} md={12} lg={6} xl={6} className="">
         <h1 style={{color:"#FFF"}}>Newsletter</h1>
          <div className="AddCV-div">
            <span className="AddCV-text">
            Abonnez-vous à la newsletter Q des Pacific pour recevoir les dernières offres d'emploi publiées, les candidats et autres actualités
              </span>
          </div>
        </Col>
        <Col xs={12} md={12} lg={6} xl={6} className="AddCV-col mt-4">
        <div className="form__form-group d-flex ">
                  <div className="form__form-group-field m-0">
                <EmailIcon className="Login-icon" />

                <Input
                  name="email"
                  type="email"
                  className="News-input"
                  style={{ fontSize: 14 }}
                  innerRef={this.setEmailRef}
                />
              </div>
              {errors.email && (
                <span
                  style={{
                    fontSize: 14,
                    width: "100%",
                    marginTop: "0.25rem",
                    color: "#e4c213"
                  }}
                >
                  {errors.email}
                </span>
              )}
              <Button
                className="btn btn-primary News-btn"
                onClick={this.handleSubmit}
                text="S'inscrire"
                color="primary"
                         />
            </div>
        </Col>
        </Row>
      </div>
    );
  }
}

export default AddNewCV;
