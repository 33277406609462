import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router-dom";
import windowSize from "react-window-size";
import {
  pathToTitle,
  setDocumentTitle
} from "../../../handler/utils/functions";
import LeftNavBar from "./LeftNavBar";
import LeftNavBarMin from "./LeftNavBarMin";
import RightNavBar from "./RightNavBar";
import RightNavBarMin from "./RightNavBarMin";

class Topbar extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    windowWidth: PropTypes.number.isRequired,
    windowHeight: PropTypes.number.isRequired,
    getCandidatProfile: PropTypes.object,
    refetch: PropTypes.func
  };

  static defaultProps = {
    getCandidatProfile: {
      first_name: "Candidat",
      avatar: null
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      screen: props.windowWidth < 1250,
      currentRoute: pathToTitle("accueil")
    };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.setCurrentRoute();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentDidUpdate({ location: { pathname } }) {
    if (this.props.location.pathname !== pathname) this.setCurrentRoute();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  setCurrentRoute = () => {
    const currentRoute = pathToTitle(this.props.location.pathname);
    if (currentRoute) {
      this.setState({ currentRoute });
      setDocumentTitle(currentRoute.title);
    }
  };
  updateWindowDimensions() {
    this.setState({ screen: window.innerWidth < 1250 });
  }

  render() {
    const { screen } = this.state;
    const color =
      this.props.location.pathname == "/" ||
      this.props.location.pathname == "/accueil"
        ? ""
        : "blue";
    return (
      <React.Fragment>
        {!screen ? (
          <nav className={`nav-bar ${color}`}>
            <LeftNavBar />
            <div className="d-flex justify-content-between ">
              <RightNavBar
                getCandidatProfile={this.props.getCandidatProfile}
                refetch={this.props.refetch}
              />
            </div>
          </nav>
        ) : (
          <nav className="nav-bar blue">
            <LeftNavBarMin />
            <RightNavBarMin
              getCandidatProfile={this.props.getCandidatProfile}
              refetch={this.props.refetch}
            />
          </nav>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(windowSize(Topbar));
