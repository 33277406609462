import PropTypes from "prop-types";
import React from "react";
import { Container } from "reactstrap";
import CandidateDescription from "./CandidateDescription";
import CandidateItemDetailHeader from "./CandidateItemDetailHeader";
function CandidateItemDetail(props) {
  const { candidate } = props;
  return (
    <Container
      key={candidate._id.toString()}
      className="OfferItemDetail"
      style={{ maxWidth: "100%" }}
    >
      <CandidateItemDetailHeader candidate={candidate} />
      <CandidateDescription candidate={candidate} />
    </Container>
  );
}
CandidateItemDetail.propTypes = {
  candidate: PropTypes.object
};

CandidateItemDetail.defaultProps = {
  candidate: {}
};

export default CandidateItemDetail;
